.reverse-question2-icon {
  background: #294177;
  color: #fff;
  border-radius: 50%;
  font-size: 24px;
}
.question-count {
  font-size: 24px;
  line-height: 0;
  margin-left: 10px;
}
.repeat-action-btns {
  font-size: 24px;
}
.repeat-action-btns > a {
  color: #294177;
  padding: 10px 4px;
  font-size: 50px;
  display: inline-block;
  line-height: 50px;
}
.repeat-action-btns > a:hover {
  color: #268cff !important;
}

.repeat-add-btn {
  font-size: 30px;
  line-height: 2;
}
.repeat-add-btn > a {
  color: #294177;
  padding: 0px 4px;
  font-size: 50px;
  display: inline-block;
  line-height: 50px;
}

.repeat-add-btn > a:hover {
  color: #268cff !important;
}
@media (max-width: 767px) {
  .repeat-add-btn > a {
    font-size: 36px;
  }
}
