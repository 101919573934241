@media (max-width: 767px) {
  .dashboard-wrapper {
    padding: 0 !important;
  }
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.top-search-icon.active {
  background-color: #294177;
  color: #fff;
  border: 2px solid #294177;
}
.top-search-icon.active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #294177;
  top: -25px;
  left: 0;
}
.top-search-icon {
  position: absolute;
  right: -55px;
  top: -31px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  font-size: 20px;
  color: #294177;
  border: 2px solid;
  width: 48px;
  text-align: center;
  height: 48px;
  padding-top: 8px;
}
.top-search-icon:hover {
  color: #ffffff;
  background: #294177;
  border: 2px solid #294177;
}
.memorize-navbar .navbar-nav .nav-link {
  color: #294177;
  position: relative;
  padding: 30px 2px;
  transition: 0.5s;
  font-size: 24px;
}

.memorize-navbar .navbar-nav .nav-link .navLinkItem {
  border: 2px solid #294177;
  padding: 10px;
  border-radius: 50%;
  transition-duration: 0.3s;
}
.restrictIcon {
  color: red !important;
  font-size: 20px;
  position: absolute;
}
.memorize-navbar .navbar-nav .nav-link .restrictIcon {
  bottom: -3px;
  right: -10px;
}
.dropdown-menu .restrictIcon {
  right: 15px;
  top: 15px;
}
.memorize-navbar .navbar-nav .nav-link:hover {
  color: #294177;
  padding: 30px 2px;
}
.memorize-navbar .navbar-nav .nav-link:hover .navLinkItem,
.memorize-navbar .navbar-nav .nav-link.active .navLinkItem {
  border: 2px solid #294177;
  padding: 10px;
  border-radius: 50%;
  background: #294177;
  color: #ffffff;
}
.memorize-navbar .login-and-menu a.circle-link {
  display: inline-block;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  text-align: center;
  font-size: 18px;
  margin-left: 8px;
}
.memorize-navbar .navbar-toggler {
  border: 2px #294177 solid;
  position: absolute;
  top: 13px;
  right: 25px;
  padding: 0px;
  height: 32px;
  width: 32px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  line-height: 0px;
}
.serach-icon.active input {
  display: inline-block;
}
.serach-icon.active .top-close-icon {
  display: block;
}
.form-inline ul li a h6 {
  display: none;
  color: #294177;
  margin-left: 20px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
  height: 100%;
}
/* .serach-icon {
  position: absolute;
  right: 0px;
  top: 0px;
  color: #294177;
} */
.serach-icon input {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border: 1px #dddddd solid;
  padding: 5px 35px;
  display: none;
}
.serach-icon .top-close-icon {
  position: absolute;
  left: 10px;
  top: 7px;
  display: none;
  color: #294177;
}
.login-and-menu {
  /* padding-right: 50px; */
}
.sprite-flags {
  background-image: url("../images/country.png");
  background-repeat: no-repeat;
  width: 16px;
  height: 11px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}
.sprite-flags.usa {
  background-position: 0px 0px;
}
.sprite-flags.Italy {
  background-position: -16px 0px;
}
.language .dropdown {
  font-size: 12px;
}
.language .dropdown .dropdown-toggle {
  color: #294177;
  display: inline-block;
  padding: 20px 0px;
}
.language .dropdown .dropdown-toggle:hover {
  color: #294177;
}
.language img {
  margin-right: 11px;
}
.language .dropdown .dropdown-menu a {
  color: #212529;
  padding: 8px 15px;
  display: block;
  transition: 0.5s;
}
.language .dropdown .dropdown-menu a:hover {
  color: #294177;
  padding: 8px 15px 8px 25px;
}
.mob_disp {
  display: none;
}
@media (max-width: 767px) {
  .edit-profile-img .camera-txt {
    width: 100% !important;
    position: inherit !important;
    right: inherit !important;
    margin: 15px 0;
    bottom: inherit !important;
  }
  .form-inline ul li a h6 {
    display: inline-block;
  }
}
@media (max-width: 768px) {
  .navtop .language {
    padding-right: 14px;
  }
  .top-search-icon {
    position: absolute;
    right: -80px;
    top: -50px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    font-size: 16px;
    color: #294177;
    border: 2px solid;
    width: 32px;
    text-align: center;
    height: 32px;
    padding-top: 4px;
  }
  .memorize-navbar .navbar-nav .nav-link {
    color: #294177;
    position: relative;
    padding: 15px 5px;
    transition: 0.5s;
    font-size: 24px;
  }
  .memorize-navbar .navbar-nav .nav-link:hover {
    color: #294177;
    padding: 15px 5px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .memorize-navbar .navbar-nav .nav-item {
    margin: 0px 0px 0 auto;
  }
}
@media (max-width: 992px) and (min-width: 768px) {
  .memorize-navbar .navbar-toggler {
    border: 2px #294177 solid;
    position: absolute;
    top: 13px;
    right: 95px;
    padding: 0px;
    height: 32px;
    width: 32px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    line-height: 0px;
    z-index: 9999;
  }
  .memorize-navbar .mobile-menu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 90px;
    border-bottom: 1px #dddddd solid;
    background: #ffffff;
    z-index: 1;
  }
  .memorize-navbar .navbar-nav .nav-link {
    padding: 15px 5px;
  }
  .memorize-navbar .navbar-nav .nav-link.active::after,
  .memorize-navbar .navbar-nav .nav-link:hover::after {
    width: 2px;
    height: 100%;
    background-color: #294177;
  }
  .login-and-menu:hover {
    padding-top: 25px;
    padding-bottom: 25px;
    border-left: 2px solid #294177;
  }
  .form-inline ul li a h6 {
    display: inline-block;
  }
  .edit-profile-img .camera-txt {
    right: 0 !important;
  }
}
@media (max-width: 1200px) and (min-width: 993px) {
  .edit-profile-img .camera-txt {
    right: -35px !important;
  }
}
@media (width: 1024px) {
  .login-and-menu {
    padding-right: 10px;
  }
}
@media (max-width: 992px) {
  .mob_disp {
    display: inherit;
  }
}
@media (max-width: 767px) {
  .language {
    position: absolute;
    top: -60px;
    right: 20px;
    text-align: right;
  }
  .kyc-details-wrapper
    .kyc-details-info
    .profile-selction
    .choose-language
    .language {
    top: inherit;
    right: inherit;
  }
  .kyc-details-wrapper
    .kyc-details-info
    .profile-selction
    .choose-language
    .language
    div {
    display: block !important;
  }
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-language
  .language
  div {
  display: inline-block;
}
@media (min-width: 768px) and (max-width: 991px) {
  .language {
    margin: inherit;
  }
}
.language {
  margin-left: auto;
}

.question-id {
  position: relative;
  padding-left: 260px;
}
.questionNumber {
  display: inline-block;
  min-width: 98px;
}
.id-no {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.03) !important;
  width: 255px;
}
.id-no .card-header {
  padding: 6px 10px !important;
  text-align: right;
  font-size: 25px;
  min-height: 50px;
  background: transparent;
}
.question-id .question-title {
  /* margin-left: 110px; */
  min-height: 50px;
}
.question-title .card-header {
  min-height: 50px;
}
.id-no .card-header:after {
  display: none;
}
@media (max-width: 767px) {
  .question-id {
    padding-left: 170px;
  }
  .id-no {
    width: 165px;
  }
  .questionNumber {
    display: inline-block;
    min-width: 63px;
  }
  .id-no .card-header {
    font-size: 16px;
    padding: 12px 6px !important;
  }
}
@media screen and (orientation: landscape) and (max-device-width: 767px) {
  .question-id {
    padding-left: 120px;
  }
  .id-no {
    width: 115px;
  }
}
/* @media screen and (orientation:landscape) and (min-device-width: 768px) and (max-device-width: 991px) {
    .question-id{
      padding-left: 120px;
    }
    .id-no{
     width: 115px;
    }
    } */

@media (max-width: 450px) {
  .question-id {
    padding-left: 110px;
  }
  .id-no {
    width: 105px;
  }
  .question-id .card .card-header span {
    display: block;
  }
}
