.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.BoxShadowHover {
  box-shadow: 0px 0px 12px -3px #294177;
  -webkit-box-shadow: 0px 0px 12px -3px #294177;
  -moz-box-shadow: 0px 0px 12px -3px #294177;
}
.BorderRadius4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.BorderRadius10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.BorderRadius100 {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.Border {
  border: 1px #dddddd solid;
}
.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WhiteBg {
  background: #FFFFFF;
}
.Transition {
  transition: .5s;
}
/* ===== About Us ===== */
.about-us-banner {
  position: relative;
  color: #FFFFFF;
}
.about-us-banner h2 {
  font-weight: bold;
}
.about-us-banner:before {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #294177;
  width: 71%;
  content: "";
}
@media (max-width: 568px) {
  .about-us-banner:before {
    right: 0px;
    width: 100%;
  }
}
.about-us-banner .img-bg {
  background: #FFFFFF;
}
.about-us-banner .get-in-touch-btn {
  background: #294177;
  color: #FFFFFF;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border: 2px #FFFFFF solid;
  padding: 10px 20px;
  font-weight: 900;
  display: inline-block;
  transition: .5s;
}
.about-us-banner .get-in-touch-btn:hover {
  background: #FFFFFF;
  color: #294177;
}
@media (max-width: 768px) {
  .about-us-banner .txt-info {
    padding: 30px 0px;
  }
}
@media (max-width: 568px) {
  .about-us-banner .person-img {
    text-align: center;
  }
}
.about-us-banner .person-img img {
  left: -150px;
  position: relative;
}
@media (max-width: 768px) {
  .about-us-banner .person-img img {
    left: initial;
  }
}
.about-us-banner .down-nav {
  position: absolute;
  text-align: center;
  bottom: 20px;
  left: 0px;
  right: 0px;
  height: 45px;
  width: 30px;
  margin: 0px auto;
}
.about-us-banner .down-nav a {
  width: 30px;
  height: 45px;
  text-align: center;
  border: 2px solid #FFFFFF;
  padding-top: 10px;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  display: block;
  transition-duration: 0.4s;
}
.about-us-banner .down-nav a:hover {
  background: #FFFFFF;
  color: #294177;
}
.about-section {
  padding-top: 50px;
}
.about-section h2 {
  font-weight: bold;
  padding-bottom: 15px;
}
.about-section h2,
.about-section h4 {
  color: #294177;
}
.about-section h4 {
  font-style: italic;
}
.about-section .about-point {
  padding-top: 50px;
}
.about-section .about-point .quality {
  text-align: center;
}
.about-section .about-point .quality .icon {
  color: #294177;
  font-size: 70px;
  line-height: 70px;
}
.about-section .about-point .quality h3 {
  font-weight: 900;
}
.qualification {
  padding: 50px 0px;
}
.qualification .qualification-box {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  padding: 15px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.qualification .qualification-box .qualification-img img {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.qualification .qualification-box h2 {
  color: #294177;
  padding-bottom: 1rem;
  font-weight: bold;
}
.qualification .qualification-box .qualification-text {
  position: relative;
  padding-left: 60px;
  margin-top: 15px;
  min-height: 45px;
}
.qualification .qualification-box .qualification-text .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 40px;
}
.qualification .qualification-box .qualification-text .year {
  font-weight: bold;
}
.career-info h2 {
  color: #294177;
  font-weight: bold;
  padding-bottom: 30px;
}
.career-info .bor-right:before {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 1px;
  height: 100%;
  content: "";
  background: #dee2e6;
}
@media (max-width: 768px) {
  .career-info .bor-right:before {
    display: none;
  }
}
.career-info .career-list {
  position: relative;
  padding-left: 60px;
  min-height: 45px;
  margin-top: 15px;
}
.career-info .career-list .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 40px;
}
.career-info .career-list .graduation {
  font-size: 20px;
  font-weight: bold;
}
.career-info .career-list .year {
  text-align: right;
  font-size: 1.25rem;
}
@media (max-width: 568px) {
  .career-info .career-list .year {
    text-align: left;
  }
}
.get-in-touch {
  padding-top: 50px;
}
@media (max-width: 568px) {
  .get-in-touch {
    padding-bottom: 50px;
  }
}
.get-in-touch h2 {
  color: #294177;
  padding-bottom: 30px;
  font-weight: bold;
}
.get-in-touch .contact-details {
  position: relative;
  padding-left: 60px;
  min-height: 45px;
  margin-top: 10px;
  font-weight: bold;
  line-height: 38px;
  color: #212529;
  display: block;
}
.get-in-touch .contact-details .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 40px;
  color: #212529;
}
.get-in-touch a.contact-details:hover {
  color: #268cff;
}
.get-in-touch a.contact-details:hover .icon {
  color: #268cff;
}
