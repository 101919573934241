.ql-editor{
    min-height: 80px;
}

 ol li.ql-indent-1 {
    counter-increment: list-1;
  }
   ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }
   ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
   ol li.ql-indent-2 {
    counter-increment: list-2;
  }
   ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }
   ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
   ol li.ql-indent-3 {
    counter-increment: list-3;
  }
   ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }
   ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }
   ol li.ql-indent-4 {
    counter-increment: list-4;
  }
   ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }
   ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }
   ol li.ql-indent-5 {
    counter-increment: list-5;
  }
   ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }
   ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }
   ol li.ql-indent-6 {
    counter-increment: list-6;
  }
   ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }
   ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }
   ol li.ql-indent-7 {
    counter-increment: list-7;
  }
   ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }
   ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }
   ol li.ql-indent-8 {
    counter-increment: list-8;
  }
   ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }
   ol li.ql-indent-8 {
    counter-reset: list-9;
  }
   ol li.ql-indent-9 {
    counter-increment: list-9;
  }
   ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }
  
  .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }
  .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }
  .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }
  .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }
  .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }
  .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }
  .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }
  .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }
  .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }
  .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }
  .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }
  .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }
  .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }
  .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }
  .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }
  .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }
  .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }
  .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }
  .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }
  .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }
  .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }
  .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }
  .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }
  .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }
  .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }
  .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }
  .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }
  .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }
  .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }
  .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }
  .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }
  .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }
  .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }
  .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }
  .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }
  .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }


/*Font Size*/
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="8px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: '8px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  font-size: 8px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="9px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  content: '9px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  font-size: 9px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: '10px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  font-size: 10px;
}


.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="11px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
  content: '11px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
  font-size: 11px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: '14px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  font-size: 14px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: '16px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: '18px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: '20px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  font-size: 20px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: '22px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  font-size: 22px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: '24px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  font-size: 24px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: '26px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  font-size: 26px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: '28px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  font-size: 28px;
}


.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: '36px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  font-size: 36px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  content: '48px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  font-size: 48px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="72px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="72px"]::before {
  content: '72px';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="72px"]::before {
  font-size: 72px;
}

.ql-size-8px {
  font-size: 8px;
}
.ql-size-9px {
  font-size: 9px;
}
.ql-size-10px {
  font-size: 10px;
}
.ql-size-11px {
  font-size: 11px;
}
.ql-size-14px {
  font-size: 14px;
}
.ql-size-16px {
  font-size: 16px;
}
.ql-size-18px {
  font-size: 18px;
}
.ql-size-20px{
  font-size: 20px;
}
.ql-size-22px{
  font-size: 22px;
}
.ql-size-24px{
  font-size: 24px;
}
.ql-size-26px{
  font-size: 26px;
}
.ql-size-28px{
  font-size: 28px;
}
.ql-size-36px{
  font-size: 36px;
}
.ql-size-48px{
  font-size: 48px;
}
.ql-size-72px{
  font-size: 72px;
}
  
/* Sets the default font size of the quell container*/
.ql-container {
  font-size: 18px !important;
  
}


  /* Set dropdown font-families */
  #toolbar-container .ql-font span[data-label="arial"]::before {
    font-family: "Arial, sans-serif";
  }

  #toolbar-container .ql-font span[data-label="serif"]::before {
    font-family: "Serif";
  }
  
  #toolbar-container .ql-font span[data-label="sansserif"]::before {
    font-family: "sans-serif";
  }
  
  #toolbar-container .ql-font span[data-label="georgia"]::before {
    font-family: "Georgia, serif";
  }
  
  #toolbar-container .ql-font span[data-label="helvetica"]::before {
    font-family: "Helvetica";
  }
  
  #toolbar-container .ql-font span[data-label="monospace"]::before {
    font-family: "monospace";
  }
  /* Set content font-families */

  .ql-font-arial {
    font-family: "arial";
  }
    
  .ql-font-serif {
    font-family: "serif";
  }
  
  .ql-font-sansserif {
    font-family: "sans-serif";
  }
  
  .ql-font-georgia {
    font-family: "georgia";
  }
  
  .ql-font-helvetica {
    font-family: "helvetica";
  }
  .ql-font-monospace{
    font-family: "monospace";
  }


.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: 'Heading 1';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: 'Heading 2';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: 'Heading 3';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: 'Heading 4';
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  font-size: 36px;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  font-size: 24px;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  font-size: 18px;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  font-size: 16px;
}


