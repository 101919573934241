.campaignList .custList {
  background-color: #FFFFFF;
  padding: 15px 25px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px #aaaaaa;
  margin-bottom: 50px;
}
.campaignList .custList .row {
  margin-bottom: 30px;
}
.campaignList .custList img {
  width: 94%;
  margin: auto;
}
@media (max-width: 992px) {
  .campaignList .custList img {
    width: 100%;
  }
}
.campaignList .custList .description {
  margin-top: 20px;
}
.campaignList .custList .description h3 {
  font-weight: 600;
}
@media (max-width: 992px) {
  .campaignList .custList .description h3 {
    font-size: 18px;
  }
}
.campaignList .custList .description p {
  color: #aaaaaa;
}
@media (max-width: 992px) {
  .campaignList .custList .description p {
    font-size: 15px;
  }
}
@media (max-width: 992px) {
  .campaignList .custList .description h5 {
    font-size: 15px;
  }
}
