.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueGradient90D {
  background: #16457b;
  background: linear-gradient(90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueGradient90DR {
  background: #16457b;
  background: linear-gradient(-90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.BoxShadowHover {
  box-shadow: 0px 0px 12px -3px #294177;
  -webkit-box-shadow: 0px 0px 12px -3px #294177;
  -moz-box-shadow: 0px 0px 12px -3px #294177;
}
.BorderRadius4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.BorderRadius10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.BorderRadius100 {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.Border {
  border: 1px #dddddd solid;
}
.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WhiteBg {
  background: #ffffff;
}
.Transition {
  transition: 0.5s;
}
/* === Begin KYC Details === */
@media (max-width: 567px) {
  .kyc-details-wrapper {
    margin-bottom: 30px;
  }
}
.kyc-details-wrapper .kyc-details-info {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 30px;
  margin-bottom: 50px;
}
@media (max-width: 568px) {
  .kyc-details-wrapper .kyc-details-info {
    padding: 15px 0px 30px 0px;
    margin-top: 10px;
  }
}
.kyc-details-wrapper .kyc-details-info .timeline-wrapper {
  margin-bottom: 30px;
}
.kyc-details-wrapper .kyc-details-info .timeline-wrapper .step-progress {
  list-style: none;
  text-align: center;
  margin: 0px;
  padding: 0px;
}
.kyc-details-wrapper .kyc-details-info .timeline-wrapper .step-progress .item {
  display: inline-block;
  padding-right: 35px;
  position: relative;
}
@media (max-width: 767px) {
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item {
    padding-right: 10px;
  }
  .profile-selction {
    padding: 0 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item {
    padding-right: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item {
    padding-right: 10px;
  }
}
@media (max-width: 360px) {
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item {
    padding-right: 5px;
  }
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item:last-child {
  padding-right: 0px;
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item:last-child:before {
  display: none;
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: #dddddd;
  top: 24px;
  right: -43%;
  z-index: -1;
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item
  .kyc-menu {
  color: #888888;
  font-size: 20px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item
    .kyc-menu {
    font-size: 12px;
  }
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item
    .kyc-menu
    .no {
    width: 40px !important;
    height: 40px !important;
    line-height: 37px !important;
  }
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item:before {
    top: 18px;
  }
  .kyc-details-wrapper .kyc-details-info .timeline-wrapper {
    margin-bottom: 10px;
  }
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item
    .kyc-menu
    .no {
    width: 35px !important;
    height: 35px !important;
    line-height: 31px !important;
  }
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item.step-line
    .kyc-menu
    span {
    width: 60px;
  }
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item.step-change {
    top: -20px;
  }
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item
    .kyc-menu
    span {
    font-size: 14px;
  }
}
@media only screen and (max-device-width: 767px) and (orientation: landscape) {
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item.step-line
    .kyc-menu
    span {
    width: auto;
  }
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item.step-change {
    top: inherit;
  }
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item {
    padding-right: 15px;
  }
}
@media (max-width: 320px) {
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item
    .kyc-menu
    span {
    font-size: 12px;
  }
  .kyc-details-wrapper
    .kyc-details-info
    .timeline-wrapper
    .step-progress
    .item.step-change {
    top: -17px;
  }
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item
  .kyc-menu
  span {
  display: block;
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item
  .kyc-menu
  .no {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  background: #dddddd;
  margin: 0px auto;
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item.active
  .kyc-menu {
  color: #294177;
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item.active
  .no {
  background: #294177;
  color: #ffffff;
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item.done
  .kyc-menu {
  color: #294177;
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item.done
  .no {
  background: #294177;
  color: #ffffff;
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item.done:before {
  background: #294177;
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item:hover
  .kyc-menu {
  color: #294177;
}
.kyc-details-wrapper
  .kyc-details-info
  .timeline-wrapper
  .step-progress
  .item:hover
  .no {
  background: #294177;
  color: #ffffff;
}
.kyc-details-wrapper .kyc-details-info input,
.kyc-details-wrapper .kyc-details-info textarea,
.kyc-details-wrapper .kyc-details-info select {
  width: 100%;
  border: none;
  border-bottom: 1px #dddddd solid;
  padding: 10px 0px;
  margin-bottom: 20px;
}
.kyc-details-wrapper .kyc-details-info .kyc-file.custom-file {
  height: 60px;
}
.kyc-details-wrapper .kyc-details-info .kyc-file .custom-file-label {
  height: 60px;
  line-height: 45px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.kyc-details-wrapper .kyc-details-info .kyc-file .custom-file-label:after {
  display: none;
}
.kyc-details-wrapper .kyc-details-info .light-color {
  color: #888888;
}
.kyc-details-wrapper .kyc-details-info .kyc-bottom-btn {
  margin-top: 30px;
  /* margin-bottom: -52px; */
  text-align: center;
  display: flex;
  justify-content: center;
}
.kyc-details-wrapper .kyc-details-info .kyc-bottom-btn a {
  min-width: 120px;
  text-align: center;
}
.kyc-details-wrapper .kyc-details-info .kyc-bottom-btn a.btn-background {
  padding: 9px 22px 6px 22px;
}
.kyc-details-wrapper .kyc-details-info .kyc-bottom-btn a span {
  font-size: 25px;
  display: inline-block;
}
.kyc-details-wrapper .kyc-details-info .profile-selction p {
  /* margin: 20px 0 50px 0; */
  color: #294177;
}
.kyc-details-wrapper .kyc-details-info .custom-checkbox label {
  color: #888888;
}
.kyc-details-wrapper .kyc-details-info .profile-selction .user-img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.kyc-details-wrapper .kyc-details-info .profile-selction .user-img img {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-language
  .language {
  border: 2px #dddddd solid;
  color: #212529;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  font-weight: 900;
  font-size: 15px;
  padding: 12px 10px;
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 30px;
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-language
  .language.active {
  border: 2px #294177 solid;
  color: #294177;
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-language
  .language:hover {
  border: 2px #294177 solid;
  color: #294177;
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-language
  .language
  .icon {
  /* background-image: url(../images/language.png); */
  background-repeat: no-repeat;
  width: 30px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-language
  .language
  .icon.english {
  background-position: 0px 0px;
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-language
  .language
  .icon.hindi {
  background-position: -30px 0px;
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-language
  .language
  .icon
  .chinese {
  background-position: -60px 0px;
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-language
  .language
  .icon.japanese {
  background-position: -90px 0px;
}
@media (max-width: 568px) {
  .kyc-details-wrapper
    .kyc-details-info
    .profile-selction
    .choose-language
    .language
    .txt {
    display: none;
  }
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-genre
  .preferred-boxs {
  border: 2px #dddddd solid;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  padding: 10px 10px 10px 75px;
  display: block;
  position: relative;
  min-height: 100%;
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-genre
  .preferred-boxs:hover {
  border: 2px #294177 solid;
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-genre
  .preferred-boxs.active {
  border: 2px #294177 solid;
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-genre
  .preferred-boxs
  .icon {
  position: absolute;
  left: 10px;
  top: 10px;
  display: block;
  width: 50px;
  height: 50px;
}
.kyc-details-wrapper
  .kyc-details-info
  .profile-selction
  .choose-genre
  .preferred-boxs
  .icon
  img {
  max-height: 50px;
}
.kyc-details-wrapper .kyc-details-info .profile-selction .choose-genre .in-txt {
  margin-top: 10px;
  color: #212529;
  font-weight: 900;
  word-wrap: break-word;
  display: inline-block;
  min-height: 40px;
}
.kyc-details-wrapper .kyc-details-info .kyc-overflow {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.edit-profile-img {
  position: relative;
}
.edit-profile-img .camera-txt {
  width: 250px;
  display: inline-block;
  text-align: center;
  position: absolute;
  bottom: 10px;
  right: 25px;
}
.edit-profile-img .profile-image {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  padding: 5px;
  width: 135px;
  height: 135px;
  border-radius: 135px;
  -webkit-border-radius: 135px;
  -moz-border-radius: 135px;
  position: relative;
  margin: 0px auto;
}
@media (max-width: 420px) {
  .edit-profile-img .profile-image {
    width: 90px;
    height: 90px;
  }
}
.edit-profile-img .profile-image img {
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  border-radius: 150px;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
}
.edit-profile-img .profile-image .edit-profile {
  position: absolute;
  right: 0px;
  bottom: 5px;
  width: 26px;
  height: 30px;
  overflow: hidden;
}
.edit-profile-img .profile-image .edit-profile input {
  opacity: 0;
  width: 26px;
  height: 30px;
  cursor: pointer;
}
.edit-profile-img .profile-image .edit-profile a {
  color: #212529;
  font-size: 26px;
  cursor: pointer;
}
.edit-profile-img .profile-image .edit-profile a:hover {
  color: #294177;
}
