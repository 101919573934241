/* ===== Memorize Fonts ===== */
@font-face {
  font-family: 'Raleway Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway Regular'), url('../fonts/Raleway/Raleway-Regular.woff') format('woff'), url('../fonts/Raleway/Raleway-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway'), url('../fonts/Raleway/Raleway-Regular.woff') format('woff'), url('../fonts/Raleway/Raleway-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway Italic'), url('../fonts/Raleway/Raleway-Italic.woff') format('woff'), url('../fonts/Raleway/Raleway-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway Thin'), url('../fonts/Raleway/Raleway-Thin.woff') format('woff'), url('../fonts/Raleway/Raleway-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway Thin Italic'), url('../fonts/Raleway/Raleway-ThinItalic.woff') format('woff'), url('../fonts/Raleway/Raleway-ThinItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway ExtraLight'), url('../fonts/Raleway/Raleway-ExtraLight.woff') format('woff'), url('../fonts/Raleway/Raleway-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway ExtraLight Italic'), url('../fonts/Raleway/Raleway-ExtraLightItalic.woff') format('woff'), url('../fonts/Raleway/Raleway-ExtraLightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway Light';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway Light'), url('../fonts/Raleway/Raleway-Light.woff') format('woff'), url('../fonts/Raleway/Raleway-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway Light Italic'), url('../fonts/Raleway/Raleway-LightItalic.woff') format('woff'), url('../fonts/Raleway/Raleway-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway Medium'), url('../fonts/Raleway/Raleway-Medium.woff') format('woff'), url('../fonts/Raleway/Raleway-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway Medium Italic'), url('../fonts/Raleway/Raleway-MediumItalic.woff') format('woff'), url('../fonts/Raleway/Raleway-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway SemiBold'), url('../fonts/Raleway/Raleway-SemiBold.woff') format('woff'), url('../fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway SemiBold Italic'), url('../fonts/Raleway/Raleway-SemiBoldItalic.woff') format('woff'), url('../fonts/Raleway/Raleway-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway Bold'), url('../fonts/Raleway/Raleway-Bold.woff') format('woff'), url('../fonts/Raleway/Raleway-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway Bold Italic'), url('../fonts/Raleway/Raleway-BoldItalic.woff') format('woff'), url('../fonts/Raleway/Raleway-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway ExtraBold'), url('../fonts/Raleway/Raleway-ExtraBold.woff') format('woff'), url('../fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway ExtraBold Italic'), url('../fonts/Raleway/Raleway-ExtraBoldItalic.woff') format('woff'), url('../fonts/Raleway/Raleway-ExtraBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway Black';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway Black'), url('../fonts/Raleway/Raleway-Black.woff') format('woff'), url('../fonts/Raleway/Raleway-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Raleway Black Italic'), url('../fonts/Raleway/Raleway-BlackItalic.woff') format('woff'), url('../fonts/Raleway/Raleway-BlackItalic.ttf') format('truetype');
}
/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Raleway/raleway-v14-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Raleway'), local('Raleway-Regular'), url('../fonts/Raleway/raleway-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/Raleway/raleway-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/Raleway/raleway-v14-latin-regular.woff') format('woff'), /* Modern Browsers */ url('../fonts/Raleway/raleway-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/Raleway/raleway-v14-latin-regular.svg#Raleway') format('svg');
  /* Legacy iOS */
}
@font-face {
  font-family: 'Century-gothic-bold';
  font-style: normal;
  font-weight: normal;
  src: local('Century-gothic-bold'), url('../fonts/Raleway/gothicb-webfont.woff') format('woff'), url('../fonts/Raleway/Century-Gothic.ttf') format('truetype');
}
@font-face {
  font-family: 'Century-gothic-regular';
  font-style: normal;
  font-weight: normal;
  src: local('Century-gothic-regular'), url('../fonts/Raleway/gothic-regular.woff') format('woff'), url('../fonts/Raleway/gothic-regular.ttf') format('truetype');
}




