.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.BoxShadowHover {
  box-shadow: 0px 0px 12px -3px #104178;
  -webkit-box-shadow: 0px 0px 12px -3px #104178;
  -moz-box-shadow: 0px 0px 12px -3px #104178;
}
.BorderRadius4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.BorderRadius10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.BorderRadius100 {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.Border {
  border: 1px #dddddd solid;
}
.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WhiteBg {
  background: #FFFFFF;
}
.Transition {
  transition: .5s;
}
/* ===== Contact Us ===== */
.contact-us-wrapper{
  margin-bottom: 50px;
}
.contact-us-wrapper .address-mail-info h3{
  color: #294177;
}
.contact-us-wrapper .address-mail-info .website {
  margin-bottom: 30px;
}
.contact-us-wrapper .address-mail-info .website a {
  padding-left: 25px;
  position: relative;
  margin-top: 10px;
  display: block;
}
.contact-us-wrapper .address-mail-info .website a .icon {
  position: absolute;
  left: 0px;
  top: 4px;
}
.contact-us-wrapper .address-mail-info .emails {
  margin-top: 30px;
}
.contact-us-wrapper .contact-form {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 30px;
}
.contact-us-wrapper .contact-form input,
.contact-us-wrapper .contact-form textarea,
.contact-us-wrapper .contact-form select {
  width: 100%;
  border: none;
  border-bottom: 1px #dddddd solid;
  padding: 10px;
  margin-bottom: 20px;
}
.contact-us-wrapper .contact-form textarea {
  min-height: 150px;
}
.contact-us-wrapper .contact-form .submit-btn {
  text-align: center;
}
.contact-us-wrapper .contact-form .submit-btn a {
  min-width: 180px;
}
.contact-us-wrapper .success-message {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 30px;
  text-align: center;
  background: #fafafa;
  margin-bottom: 30px;
}
.contact-us-wrapper .success-message img {
  max-width: 100%;
}
.contact-us-wrapper .success-message h1 {
  font-weight: bold;
}
@media (max-width: 568px) {
  .contact-us-wrapper .success-message h1 {
    font-size: 2rem;
  }
}
.contact-us-wrapper .success-message .home-btn {
  text-align: center;
  margin-top: 30px;
}
.contact-us-wrapper .success-message .home-btn a {
  min-width: 180px;
  color: #FFFFFF;
}
