.quiz-button-wrapper {
  /* position: relative;
  display: inline-block; */
}

.hover-content {
  display: none;
  /* position: absolute; */
  top: 100%; /* Position it below the main button */
  left: 0;
  margin-top: 1px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  color: #294177;
}

.hover-content p {
  margin: 0 0 10px 0;
  font-size: 16px;
}

.quiz-button-wrapper:hover .hover-content {
  display: block;
}
