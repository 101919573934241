.genre_pref_list .item {
    width: 100%;
    text-align: center;
    box-shadow: 1px 1px 5px #999;
    margin: 0px 1px 0px 0px;
    border: 2px solid #fff;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    min-height: 130px;
}
.genre_pref_list .item.cur_list {
    border: 2px solid #294177;
}
.cust_center {
    margin: auto auto 50px auto;
    box-shadow: 1px 1px 10px #ccc;
    padding: 20px 20px;
    border-radius: 30px;
}
.cust_center p strong,
.cust_center h1 span{
    color: #294177;
}
.no-pad {
    padding: 5px;
}


.genre_pref_list{
    padding: 0 15px;
}
.circle{
   height: 100px;
    width: 100px;
    border-radius: 50%;
    box-shadow: 1px 1px 10px #999;
    text-align: center;
    padding-top: 28px;
    margin: auto;
    border: 2px solid #fff;
}
.lang_sel p{
    margin-top: 20px;
}
.circle.active_circ{
    border: 2px solid #294177;
}
.circle1{
   height: 100px;
    width: 100px;
    border-radius: 50%;
    box-shadow: 1px 1px 10px #999;
    text-align: center;
    padding-top: 28px;
    margin: auto;
    border: 2px solid #fff;
}
.lang_sel p{
    margin-top: 20px;
    font-size: 15px;
    font-weight: 900;
}
.circle1.active_circ{
    border: 2px solid #294177;
}
.cat_disp_list .cust_genre img, .genre_disp_list .cust_genre img {
    width: 55%;
}
.cat_disp_list .cust_genre h4, .genre_disp_list .cust_genre h4 {
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}
.genre_pref_list .cust_genre img {
    width: 55%;
}
.genre_pref_list .cust_genre h4 {
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 0;
}
.cust_center h4 {
    margin: 20px 0;
}
@media (max-width: 575px){
    .cat_disp_list .col-xs-3, .genre_disp_list .col-xs-3{
    width: 33%;
}
.genre_pref_list .col-xs-6{
    width: 50%;
} 
}
@media (max-width: 768px){ 
.col-md-3.lang_sel{
    width: 50%;
}
}
@media (max-width: 992px) and (min-width: 768px){
    .circle {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    box-shadow: 1px 1px 10px #999;
    text-align: center;
    padding-top: 15px;
    margin: auto;
    border: 2px solid #fff;
}
.circle1 {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    box-shadow: 1px 1px 10px #999;
    text-align: center;
    padding-top: 15px;
    margin: auto;
    border: 2px solid #fff;
}


}
.bootstrap-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    display: inline-block;
    padding: 4px 6px;
    color: #555;
    vertical-align: middle;
    border-radius: 4px;
    max-width: 100%;
    line-height: 22px;
    cursor: text;
    width: 100%;
    border-radius: 0px;
    border: none !important;
    border-bottom: 1px solid #ccc !important;
    box-shadow: none;
    margin-bottom: 20px;
    border: none !important;
    border-bottom: 1px solid #ccc !important;
    box-shadow: none !important;
    margin-bottom: 20px;
}
.bootstrap-tagsinput .tag {
    margin-right: 2px;
    color: #fff !important;
    background: #294177;
    padding: 5px;
    border-radius: 4px;
}
.plus_img{
    position: absolute;
    right: 0;
    top: -18px;
    width: 45px;
}
.cust_cat_btn {
    padding: 13px 37px;
    font-size: 20px;
    font-weight: 600;
    display: block;
    width: 200px;
    margin: 30px auto;
    text-align: center;
}

.circle img {
  width: 45px;
}

.circle1 img {
  width: 35px;
}



