@media (max-width: 768px) {
  .pricingDetails .col-md-4 {
    margin-bottom: 35px;
  }
}
.pricingDetails{
  padding: 50px 0;
}
.priceTable {
  text-align: center;
  /* border: 2px solid #294177; */
  border-radius: 20px;
  transition-duration: 0.3s;
  height: 100%;
  margin-right: -15px;
  box-shadow: 0px 0px 5px #ccc;
}
.priceTable .type {
  color: #294177;
  font-weight: 600;
  padding: 15px 0;
  margin-bottom: 0;
}
.priceTable .rateCard {
  color: #FFFFFF;
/*background: #294177;
  padding: 40px 0; */
  border-radius: 15px;
  transition-duration: 0.3s;
}
.priceTable .rateCard h2,
.priceTable .rateCard p {
  margin-bottom: 0;
  color:#294177
}
/* .priceTable.active .rateCard h2,
.priceTable.active .rateCard p,
.priceTable:hover .rateCard h2,
.priceTable:hover .rateCard p{
  color: #fff;
} 
.priceTable.active span.icon,
.priceTable:hover span.icon{
  color: #fff;
}
.priceTable:hover {
  transform: scale(1);
  background: #294177;
  border: 2px solid #294177;
  box-shadow: 0px 0px 10px #555;
}
.priceTable:hover .rateCard {
  background: #FFFFFF;
  color: #294177;
} 
.priceTable:hover .type {
  color: #FFFFFF;
}
.priceTable:hover .description {
  color: #FFFFFF;
}
.priceTable:hover .description p {
  margin-bottom: 10px;
  font-size: 12px;
}
.priceTable:hover .description p span {
  font-size: 14px;
  font-weight: 600;
}
.priceTable:hover .btnBuy {
  border: 2px solid #FFFFFF;
  background: #FFFFFF;
  color: #294177;
}
.priceTable.active {
  transform: scale(1.03);
  background: #294177;
  border: 2px solid #294177;
  box-shadow: 0px 0px 10px #555;
}
.priceTable.active .type {
  color: #FFFFFF;
}
.priceTable.active .rateCard {
  background: #FFFFFF;
  color: #294177;
}
.priceTable.active .description {
  color: #FFFFFF;
}
.priceTable.active .btnBuy {
  border: 2px solid #FFFFFF;
  background: #FFFFFF;
  color: #294177;
}
*/
.priceTable .description {
  padding: 15px;
  color: #294177;
  padding-bottom: 100px;
  transition-duration: 0.3s;
  text-align: left;
}
.priceTable span.icon{
  font-size: 50px;
  position: absolute;
  bottom: 35px;
  left: 0;
  right: 0;
  color: #294177;
}

.priceTable .description p {
  margin-bottom: 10px;
  font-size: 12px;
}
.priceTable .description p span {
  font-size: 14px;
  font-weight: 600;
}
.priceTable .btnBuy {
  position: absolute;
  text-align: center;
  width: max-content;
  transform: translate(-50%, -50%);
  bottom: 0;
  left: 50%;
  background: #294177;
  border: 2px solid #294177;
  border-radius: 8px;
  color: #FFFFFF;
  padding: 8px 15px;
  font-size: 15px;
  transition-duration: 0.3s;
}

.manage-subscription-section p{
  margin-bottom: 0;
  color: #294177;
}
.manage-subscription-section .blue-bg-btn{
  padding: 10px 0;
}
.manage-subscription-section h3{
  font-weight: bold;
  color: #294177;
}
@media(max-width:767px){
  .manage-subscription-section .blue-bg-btn{
    margin-top: 10px;
  }
  .priceTable{
    margin-right: 0;
  }
  .manage-subscription-section p{
    display: inline-block;
    margin-right: 5px;
  }
}
@media(max-width:991px){
  .sub-table{
    margin-bottom: 30px;
  }
  .manage-subscription-section p,
  .manage-subscription-section .blue-bg-btn{
    margin-top: 10px;
  }
  .dashboard-wrapper .dashboard-content .dashboard-information .manage-subscription-section .subscription-item .subscription-box{
    padding: 15px;
  }
}
