.counter{
    position: absolute;
    top: 10px;
    right: 0;
    height: 5%;
    width: 5%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidebar-counter{
    position: absolute;
    top: 16px;
    right: 20px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}