.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.BoxShadowHover {
  box-shadow: 0px 0px 12px -3px #294177;
  -webkit-box-shadow: 0px 0px 12px -3px #294177;
  -moz-box-shadow: 0px 0px 12px -3px #294177;
}
.BorderRadius4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.BorderRadius10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.BorderRadius100 {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.Border {
  border: 1px #dddddd solid;
}
.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WhiteBg {
  background: #FFFFFF;
}
.Transition {
  transition: .5s;
}
/* ===== Inner Pages ===== */
/* .innerpage-wrapper {
  padding: 30px 0px;
}
.innerpage-wrapper .nav-tabs {
  border-bottom: 2px #dddddd solid;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.innerpage-wrapper .nav-tabs .nav-item {
  margin-bottom: 0px;
  padding: 0px 10px 0px 0px;
}
.innerpage-wrapper .nav-tabs .nav-item:last-child {
  padding: 0px;
}
.innerpage-wrapper .nav-tabs .nav-item .nav-link {
  border: 2px #dddddd solid;
  color: #212529;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  font-weight: 900;
  font-size: 15px;
  padding: 12px 10px;
  position: relative;
}
.innerpage-wrapper .nav-tabs .nav-item .nav-link.active {
  border: 2px #294177 solid;
  color: #294177;
}
.innerpage-wrapper .nav-tabs .nav-item .nav-link.active:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -14px;
  height: 2px;
  width: 100%;
  background: #294177;
}
.innerpage-wrapper .nav-tabs .nav-item .nav-link:hover {
  border: 2px #294177 solid;
  color: #294177;
}
.innerpage-wrapper .nav-tabs .nav-item .nav-link:hover:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -14px;
  height: 2px;
  width: 100%;
  background: #294177;
}
.innerpage-wrapper .nav-tabs .nav-item .nav-link .icon {
  background-image: url(../images/language.png);
  background-repeat: no-repeat;
  width: 30px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.innerpage-wrapper .nav-tabs .nav-item .nav-link .icon.english {
  background-position: 0px 0px;
}
.innerpage-wrapper .nav-tabs .nav-item .nav-link .icon.hindi {
  background-position: -30px 0px;
}
.innerpage-wrapper .nav-tabs .nav-item .nav-link .icon .chinese {
  background-position: -60px 0px;
}
.innerpage-wrapper .nav-tabs .nav-item .nav-link .icon.japanese {
  background-position: -90px 0px;
}
@media (max-width: 568px) {
  .innerpage-wrapper .nav-tabs .nav-item .nav-link .txt {
    display: none;
  }
}
.innerpage-wrapper .sales-report-info {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  padding: 30px;
}
.innerpage-wrapper .sales-report-info h2 {
  color: #294177;
  font-weight: bold;
}
.innerpage-wrapper .sales-report-info .sr-overall {
  text-align: center;
}
.innerpage-wrapper .sales-report-info .sr-overall .amount {
  color: #294177;
  font-size: 30px;
  font-weight: bold;
}
.innerpage-wrapper .sales-report-info .sr-overall .txt {
  color: #294177;
  font-weight: bold;
}
@media(max-width:767px){
  .st-filter .btn-block {
    margin-top: 20px;
  }
  .innerpage-wrapper .sales-report-info .st-filter .to{
    display: block;
    width: 100%;
    padding: 15px 0 !important;
  }
}
.innerpage-wrapper .sales-report-info .st-filter .to{
  padding: 0 15px;
}
.innerpage-wrapper .sales-report-info .st-filter {
  margin-top: 30px;
}
.innerpage-wrapper .sales-report-info .st-filter p {
  color: #888888;
}
.innerpage-wrapper .sales-report-info .st-filter .input-control {
  position: relative;
  margin-bottom: 10px;
}
.innerpage-wrapper .sales-report-info .st-filter .input-control input {
  border: none;
  border-bottom: 1px #dddddd solid;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px 30px 10px 0px;
}
.innerpage-wrapper .sales-report-info .st-filter .input-control .icon {
  position: absolute;
  right: 10px;
  top: 10px;
  display: inline-block;
  color: #212529;
}
.innerpage-wrapper .sales-report-info .report-table {
  margin-top: 30px;
}
.innerpage-wrapper .sales-report-info .report-table h3 {
  text-align: center;
  color: #294177;
  margin-bottom: 20px;
}
.innerpage-wrapper .sales-report-info .report-table .table-btn {
  padding: 8px 16px;
  font-size: 15px;
  font-weight: 900;
  display: inline-block;
}
.innerpage-wrapper .sales-report-info .report-table .reports {
  overflow-y: auto;
}
.innerpage-wrapper .sales-report-info .report-table .reports table {
  border-collapse: collapse;
  width: 100%;
}
.innerpage-wrapper .sales-report-info .report-table .reports table .price {
  color: #294177;
}
.innerpage-wrapper .sales-report-info .report-table .reports table thead th {
  background: #f9f9f9;
  padding: 15px 10px;
  font-size: 20px;
}
.innerpage-wrapper .sales-report-info .report-table .reports table td,
.innerpage-wrapper .sales-report-info .report-table .reports table th {
  border-right: 1px #DDDDDD solid;
}
.innerpage-wrapper .sales-report-info .report-table .reports table td:last-child,
.innerpage-wrapper .sales-report-info .report-table .reports table th:last-child {
  border-right: none;
}
.innerpage-wrapper .sales-report-info .report-table .reports table td {
  padding: 10px;
  border-bottom: 1px #DDDDDD solid;
}
.innerpage-wrapper .sales-report-info .report-table .reports table tfoot tr td {
  background: #f9f9f9;
  font-size: 20px;
  border-right: none;
}
.innerpage-wrapper .sales-report-info .report-table .reports table tfoot tr td .price {
  font-weight: bold;
} */



/* sales Table */
.mw-48px {
  min-width: 48px;
}

input[type="date"] {
  font-size: 14px;
}

.dateSelectionBox {
  border: 1px solid #294177;
  padding: 0 15px;
}

.w-100 {
  width: 100%;
}

.blueBgBtn {
  background-color: #294177;
  color: #ffffff;
  border: 2px solid #294177;
  transition: .5s;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 4px 12px;
}

.redBgBtn {
  background-color: #c00000;
  color: #ffffff;
  border: 2px solid #c00000;
  transition: .5s;
  -webkit-border-radius: 30px;
  -moz-border-radius: 25px;
  border-radius: 30px;
}
.salesInfo h3{
  font-weight: 600 !important;
}
.blue-bg-btn:hover {
  background-color: #ffffff;
  color: #294177 !important;
}
.salesInfo .react-date-picker__wrapper{
  min-width: 161px !important;
}
a {
  text-decoration: none !important;
}

.saleCreditTable tr th, .saleCreditTable tr td {
  color: #294177;
  padding: .2rem;
}

.saleCreditTable tr td {
  border-bottom: 1px solid !important;
  border-top: 0 !important;
}

.saleCreditTable tbody, .saleCreditTable td, .saleCreditTable th, .saleCreditTable thead, .saleCreditTable tr {
  border-color: #294177 !important;
}

.saleCreditTable thead {
  border-top: 2px solid #294177 !important;
}

.purchaseBox {
  display: flex;
}

.contentBox {
  padding-right: .5rem !important;
}

.creditTableBox {
  padding: 15px !important;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: none!important;
}

.payoutRedBgButton {
  border: 1px #dddddd solid;
  color: #ffffff;
  border-radius: 100px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  font-weight: 600;
  font-size: 15px;
  padding: 9px 10px;
  min-width: 124px;
  position: relative;
  background-color: #c00000;
}

.red-bg-btn{
  background-color: #c00000;
  color: #ffffff;
  border: 2px solid #c00000;
  transition: .5s;
  -webkit-border-radius: 30px;
  -moz-border-radius: 25px;
  border-radius: 30px;
}

.payoutBtns {
  width: 100%;
  text-align: center;
}



button:focus {
  outline: none !important;
}

.balanceNum, .payoutBtn, .balanceColumn {
  text-align: right;
}

@keyframes fadeInRight {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0)
  }
  to {
      opacity: 1;
      -webkit-transform: none;
      transform: none
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight
}

.creatorDetails {
  display: flex;
}

.payoutBtns {
  display: flex;
  justify-content: center;
}

.payoutBtns button {
  padding: 4px 12px;
  margin-bottom: 10px;
}

.paymentRequestInfo {
  font-size: 14px;
}

.creatorInfo {
  padding-left: 3rem;
}

.salesDateSelection {
  display: flex;
  justify-content: space-between;
}

table {
  width: 100%;
}

.mainTable tr td, .mainTable tr th, .mainHeaderTable tr th {
  color: #294177;
  padding: .2rem;
}

.purchaseInnerTable thead, .mainHeaderTable thead {
  border-top: 1px solid #294177;
  border-bottom: 1px solid #294177;
}

.font-weight-bold {
  font-weight: bold;
}

.contentTotalTable tr td {
  font-weight: 600;
}

@media only screen and (min-width:769px) {
  .salesDateTable thead th:first-child {
      width: 10%;
  }
  .salesDateTable thead th:nth-child(2) {
      width: 20%;
  }
  .salesDateTable thead th:nth-child(3) {
      width: 40%;
  }
  .salesDateTable thead th:nth-child(4) {
      width: 10%;
  }
  .salesDateTable thead th:nth-child(5) {
      width: 10%;
  }
  .salesDateTable thead th:last-child {
      width: 10%;
  }
  .salesDateTable tbody tr:nth-child(1) {
      width: 10%;
  }
  #dynamicCell {
      width: 100%;
  }
  .dynamicTable  tr td:nth-child(1) {
      width: 20% !important;
  }
  .dynamicTable  tr td:nth-child(2) {
      width: 40% !important;
  }
  .dynamicTable  tr td:nth-child(3) {
      width: 10% !important;
  }
  .dynamicTable  tr td:nth-child(4) {
      width: 10% !important;
  }
  .dynamicTable  tr td:last-child {
      width: 10% !important;
  }
  .dynamicTable tr:last-child td {
      border: none !important;
  }
  .mainHeaderTable tr th:first-child, .contentTotalTable tr td:first-child {
      width: 40%;
  }
  .mainHeaderTable tr th:nth-child(2), .mainHeaderTable tr th:nth-child(3), .mainHeaderTable tr th:last-child, .contentTotalTable tr td:nth-child(2), .contentTotalTable tr td:nth-child(3), .contentTotalTable tr td:last-child {
      width: 20%;
  }
  .purchaseInnerTable thead tr th:first-child, .purchaseInnerTable tbody tr td:first-child, .purchaseInnerTable thead tr th:nth-child(3), .purchaseInnerTable tbody tr td:nth-child(3) .purchaseOuterTable tr td:first-child {
      width: 10%;
  }
  .purchaseInnerTable thead tr th:nth-child(4), .purchaseInnerTable tbody tr td:nth-child(4), .purchaseInnerTable thead tr th:nth-child(5), .purchaseInnerTable tbody tr td:nth-child(5), .purchaseInnerTable thead tr th:last-child, .purchaseInnerTable tbody tr td:last-child {
      width: 20%;
  }
  .purchaseOuterTable tr td:last-child {
      width: 90%;
  }
  .purchaseInnerTable thead tr th:nth-child(2), .purchaseInnerTable tbody tr td:nth-child(2) {
      width: 30%;
  }
  .mainTable {
      border-bottom: 1px solid #aaa;
  }
  .purchaseInnerTable th {
      font-weight: normal;
  }
  .purchaseOuterTable {
      margin-top: .5rem !important;
  }
}
@media only screen and (max-width:767px){
  .salesCard{
      height: auto !important;
  }
}
@media only screen and (max-width:768px) {
  .saleCreditTable thead {
      display: none;
  }
  .saleCreditTable, .saleCreditTable tbody, .saleCreditTable tr, .saleCreditTable td {
      display: block;
      width: 100%;
  }
  .saleCreditTable tr {
      margin-bottom: 15px;
      border: 1px solid #294177;
     border-radius: 4px;
  }
  .saleCreditTable tbody tr td,
  .contentTotalTable tbody tr td, .mainTable tr .contentIdCell, .purchaseInnerTable tr td,.contentTotalRow td {
      text-align: left !important;
      padding-left: 50% !important;
      position: relative;
      border-bottom: none !important;
  }
  .saleCreditTable td::before, .contentTotalTable td::before, .mainTable tr .contentIdCell::before, .purchaseInnerTable tr td::before,.contentTotalRow td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      /* font-size: 14px; */
      text-align: left;
  }
  .purchaseBox {
      flex-direction: column;
  }
  .contentBox {
      padding-right: 0 !important;
  }
  .salesCard {
      text-align: center;
  }
  .salesInfo {
      padding: 15px !important;
  }
  .balanceNum {
      text-align: left;
  }
  .dateTable [data-label^="Date"] {
      background: #294177;
  }
  .tableBody [rowspan]:first-of-type {
      color: #ffffff !important;
      margin-bottom: 0 !important;
  }
  .creatorDetails {
      flex-direction: column;
      text-align: center;
  }
  .creatorInfo {
      padding-left: 0 !important;
  }
  .dynamicTable tbody tr td {
      width: initial !important;
  }
  .dynamicTable tr {
      border: none;
      margin: 8px 0;
  }
  .dynamicTable tr:last-child td {
     margin-bottom: 8px !important;
  }
  .dateCell {
      background: #294177 !important;
      color: #ffffff !important;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      
  }
  .dateCell::before {
      color: #ffffff !important;
  }
  #dynamicCell {
      padding-left: 0 !important;
      padding: 0 !important;
  }
  .dynamicTable tr {
      border-bottom: 1px solid !important;
      border-radius: 0 !important;
  }
  .dynamicTable tr:last-child {
      border-bottom: none !important;
  }
  .purchaseInnerTable thead, .mainHeaderTable thead {
      display: none;
  }
  .contentTotalTable, .contentTotalTable tbody, .contentTotalTable tr, .contentTotalTable td, .purchaseInnerTable tr td {
      display: block;
      width: 100%;
  }
  .contentTotalTable {
      margin-bottom: 15px;
  }
  .purchaseOuterTable {
      border-collapse: separate;
      border: 1px solid;
     border-radius: 4px !important;
     margin-bottom: 8px;
  }
  .d-noneCell {
      display: none;
  }
  .purchaseInnerTable tbody tr {
      border-bottom: 1px solid #294177;
  }
  .purchaseInnerTable tbody tr:last-child {
      border-bottom: 0 !important;
  }
  .pa-sm-0 {
      padding: 0 !important;
  }
  .pa-sm-6px{
      padding: 0 6px !important;
  }
  .mainTable {
      margin-bottom: 20px;
      border: 1px solid;
      border-radius: 4px !important;
      border-collapse: separate;  
  }
}

@media only screen and (max-width:992px) {
  .creatorIconArea {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }
  .creatorInfo {
      padding-left: 1.5rem;
  }
}

@media only screen and (max-width:450px) {
  .payoutBtns {
      width: 100%;
      flex-direction: column;
  }
  .payoutBtns .red-bg-btn {
      margin-left: 0 !important;
  }
  .salesDateSelection {
      flex-direction: column;
      align-items: center;
  }
  .salesHeading {
      margin-bottom: 1rem;
  }
  .salesInfo h3 {
      font-size: 20px;
  }
  .salesTab .nav-tabs .nav-item .nav-link {
      padding: 4px 3px !important;
      font-size: 14px !important;
  }
  table td{
      font-size: 14px;
  }
}

@media only screen and (min-width:450px) {
  #navbarSupportedContent {
      display: flex !important;
  }
}

/* sales begins */

.salesTab .nav-tabs .nav-item .nav-link.active:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -14px;
  height: 2px;
  width: 100%;
  background: #294177;
}

.salesTab .nav-tabs .nav-item .nav-link {
  border: 2px #dddddd solid;
  color: #212529;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  font-weight: 600;
  font-size: 15px;
  padding: 12px 10px;
  position: relative;
  width: 100%;
}

.salesTab .nav-tabs .nav-item .nav-link.active {
  border: 2px #294177 solid;
  color: #294177;
}

.salesTab .nav-tabs {
  border-bottom: 2px #dddddd solid;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.salesTab .nav-tabs .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  padding: 0px 13px 0px 0px;
}

.salesInfo {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  padding: 30px;
  color: #294177;
}

.salesCard {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 12px -3px #aaaaaa;
  margin-bottom: 10px !important;
  padding: 15px;
  border: 1px solid #294177;
  font-size: 14px;
  height: 100%;
}

.creatorIcon {
  width: 80px;
  height: 80px;
  background-color: #294177;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: #ffffff;
  border-radius: 50%;
}

.reportId {
  text-align: center;
  border: 1px solid #294177;
  margin: 10px 0;
  min-width: 80px;
}

/* sales ends */

/* sale content begins*/

.mainTitleRow {
  border-top: 2px solid;
  border-bottom: 2px solid;
  padding: 7px 0;
}

/* sale content ends */
/* sales Table */
