.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.BorderRadius4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.deleteSubscriptionBtn {
  padding: 15px !important;
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.BorderRadius50 {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}
.Border {
  border: 1px #dddddd solid;
}
.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WhiteBg {
  background: #ffffff;
}
.Transition {
  transition: 0.5s;
}
body {
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-family: "Arial";
  overflow-x: hidden;
}
a:hover {
  text-decoration: none;
}
img {
  max-width: 100%;
}
.login-and-menu {
  padding: 23px 10px 0px 0px;
  transition: 0.5s;
}
.p-relative {
  position: relative;
}
.notifyNumber {
  color: #fff !important;
  font-size: 16px;
  background: red;
  position: absolute;
  bottom: 28px;
  right: 2px;
  border: none !important;
  padding: none !important;
  padding: 11px !important;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
}
.inboxLink:hover .notifyNumber {
  background: red !important;
}
.inboxLink.active .notifyNumber {
  background: red !important;
}
/* ===== Begin Arrow-Top ====== */
.arrow-top:after {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(16, 65, 120, 0);
  border-width: 7px;
  margin-left: -7px;
}
.arrow-top.blue:after {
  border-bottom-color: #294177;
}
.arrow-top.right:after {
  right: 15px;
}
.arrow-top.left:after {
  left: 15px;
}
.arrow-top.center:after {
  right: 50%;
}
.pl-20px {
  padding-left: 20px;
}
/* ===== End Arrow-Top ====== */
/* ===== Begin Dropdown ====== */
.dropdown .dropdown-menu {
  border: none;
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.dropdown-menu-left {
  left: 16px !important;
}
/* ===== End Dropdown ====== */
/* ===== Begin Input-Field ====== */
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}
.input-field {
  position: relative;
  margin-bottom: 10px;
}
.input-field.left-icon {
  padding-left: 35px;
}
.input-field .icons {
  position: absolute;
  top: 0px;
  width: 25px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  font-size: 20px;
  color: #888888;
  text-align: center;
}
.input-field .icons.left {
  left: 0px;
}
.input-field .icons.right {
  right: 0px;
}
.input-field input,
.input-field select,
.input-field textarea {
  border: none;
  border-bottom: 1px #dddddd solid;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px;
  font-family: "Arial";
  font-weight: 900;
  height: 40px;
}
.input-field input.right-icon,
.input-field select.right-icon,
.input-field textarea.right-icon {
  padding: 10px 35px 10px 10px;
}
.input-field input:focus,
.input-field select:focus,
.input-field textarea:focus {
  outline: none;
  border-bottom: 1px #294177 solid;
}
.input-field .show-password:hover {
  color: #294177;
}
.blue-bg-btn {
  background-color: #fff;
  color: #294177;
  border: 2px solid #294177;
  transition: 0.5s;
  -webkit-border-radius: 30px;
  -moz-border-radius: 25px;
  border-radius: 30px;
}
.blue-bg-btn:hover {
  background-color: #294177;
  color: #fff !important;
}
.white-bg-btn {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 25px;
  color: #fff;
  transition: 0.5s;
  border: 2px solid #fff;
}
.white-bg-btn:hover {
  color: #294177;
  background-color: #fff;
}
/* ===== End Input-Field ====== */
/* ===== Begin Navbar ====== */
.navtop {
  box-shadow: 0px 1px 11px -5px #aaa;
  -webkit-box-shadow: 0px 1px 11px -5px #aaa;
  -moz-box-shadow: 0px 1px 11px -5px #aaa;
  padding: 9px 0px 8px;
  position: relative;
}
.navtop .social-icons {
  padding: 4px 0px;
}
.navtop .social-icons a {
  color: #294177;
  font-size: 17px;
  padding: 0px 6px;
  display: inline-block;
  height: 30px;
  line-height: 32px;
  transition: 0.5s;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
@media (max-width: 567px) {
  .navtop .social-icons a {
    font-size: 14px;
    padding: 0px 4px;
  }
}
.navtop .social-icons a:hover {
  background: #dddddd;
}
.navtop .social-icons a.facebook:hover {
  color: #3d5b99;
}
.navtop .social-icons a.twitter:hover {
  color: #00aced;
}
.navtop .social-icons a.linkedin:hover {
  color: #0073a4;
}
.navtop .social-icons a.instagram:hover {
  color: #de6666;
}
.navtop .language {
  text-align: right;
  padding-right: 60px;
  position: relative;
}
@media (max-width: 567px) {
  .navtop .language {
    padding-right: 40px;
  }
}
.navtop .dropdown {
  font-size: 12px;
}
.navtop .dropdown .dropdown-toggle {
  color: #294177;
  display: inline-block;
  padding: 10px 0px;
}
.navtop .dropdown .dropdown-toggle:hover {
  color: #294177;
}
.navtop .dropdown .dropdown-menu {
  font-size: 12px;
  margin-top: 0px;
  border: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  min-width: 130px;
}
.navtop .dropdown .dropdown-menu a {
  color: #212529;
  padding: 8px 15px;
  display: block;
  transition: 0.5s;
}
.navtop .dropdown .dropdown-menu a:hover {
  color: #294177;
  padding: 8px 15px 8px 25px;
}
.navtop .serach-icon {
  position: absolute;
  right: 10px;
  top: 0px;
  color: #294177;
}
@media (max-width: 567px) {
  .navtop .serach-icon {
    right: 5px;
  }
}
.navtop .serach-icon.active input {
  display: inline-block;
}
.navtop .serach-icon.active .top-close-icon {
  display: block;
}
.navtop .serach-icon input {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border: 1px #dddddd solid;
  padding: 5px 35px;
  display: none;
}
.navtop .serach-icon .top-search-icon {
  position: absolute;
  right: 10px;
  top: 7px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  font-size: 18px;
  color: #294177;
}
.navtop .serach-icon .top-search-icon:hover {
  color: #268cff;
}
@media (max-width: 567px) {
  .navtop .serach-icon .top-search-icon {
    font-size: 16px;
  }
}
.navtop .serach-icon .top-close-icon {
  position: absolute;
  left: 10px;
  top: 7px;
  display: none;
  color: #294177;
}
.navtop .serach-icon .top-close-icon:hover {
  color: #268cff;
}
@media (max-width: 567px) {
  .navtop .right-drop {
    padding: 0px 5px;
  }
}
.navtop .sprite-flags {
  background-image: url("../images/country.png");
  background-repeat: no-repeat;
  width: 16px;
  height: 11px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}
.navtop .sprite-flags.usa {
  background-position: 0px 0px;
}
.navtop .sprite-flags.Italy {
  background-position: -16px 0px;
}
.memorize-navbar {
  padding: 0px;
}
@media (max-width: 768px) {
  .memorize-navbar .container {
    padding: 0px 15px;
    position: relative;
  }
  .notifyNumber {
    bottom: 15px;
  }
}
.memorize-navbar .navbar-brand {
  color: #294177;
  font-weight: 900;
}
@media (max-width: 768px) {
  .memorize-navbar .navbar-brand {
    font-size: 14px;
  }
}
.memorize-navbar .navbar-brand:hover {
  color: #268cff;
}
.memorize-navbar .navbar-brand:focus {
  color: #268cff;
}
.memorize-navbar .navbar-brand img {
  display: inline-block;
}
@media (max-width: 768px) {
  .memorize-navbar .navbar-brand img {
    max-height: 50px;
  }
}
.memorize-navbar .navbar-brand p {
  display: inline-block;
  padding-left: 10px;
  font-family: "century-gothic-bold";
  color: #294177;
}
.memorize-navbar .navbar-brand p span {
  color: #a5cc31;
}
@media (max-width: 768px) {
  .memorize-navbar .navbar-brand p {
    padding-left: 5px;
  }
}
.memorize-navbar .navbar-nav .nav-item {
  margin: 0px 7px;
}
.memorize-navbar .navbar-nav .nav-link {
  color: #294177;
  position: relative;
  padding: 30px 15px;
  transition: 0.5s;
  font-size: 24px;
}
.memorize-navbar .navbar-nav .nav-link:after {
  content: "";
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  width: 0px;
  border-top: 2px #294177 solid;
  transition: 0.25s ease;
}
@media (max-width: 768px) {
  .memorize-navbar .navbar-nav .nav-link:after {
    display: none;
  }
}
.memorize-navbar .navbar-nav .nav-link:hover:after {
  width: 100%;
}
@media (max-width: 768px) {
  .memorize-navbar .navbar-nav .nav-link {
    padding: 10px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .memorize-navbar .navbar-nav .nav-link {
    padding: 30px 10px;
  }
}
.memorize-navbar .navbar-nav .nav-link.active {
  color: #294177;
}
@media (max-width: 768px) {
  .memorize-navbar .navbar-nav .nav-link.active {
    color: #268cff;
  }
}
.memorize-navbar .navbar-nav .nav-link.active::after {
  width: 100%;
}
.memorize-navbar .navbar-nav .nav-link:hover {
  color: #294177;
  padding: 20px 15px 40px;
}
@media (max-width: 768px) {
  .memorize-navbar .navbar-nav .nav-link:hover {
    color: #268cff;
  }
}
.memorize-navbar .navbar-nav .nav-link:focus {
  color: #294177;
}
@media (max-width: 768px) {
  .memorize-navbar .login-and-menu {
    width: 100%;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  .login-and-menu {
    width: 100%;
    padding: 0px 10px 8px 5px;
    margin-left: 10px;
  }
  .memorize-navbar .login-and-menu a.circle-link {
    margin-left: -1px !important;
  }
  .headerContentIcon,
  .createContentIcon {
    position: relative;
    left: 8px;
  }
  .arrow-top.left:after {
    left: 29px;
    top: 41px;
  }
  .memorize-navbar .navbar-toggler {
    top: 13px !important;
    right: 35px !important;
    height: 46px !important;
    width: 46px !important;
  }
  .notifyNumber {
    bottom: 15px;
    right: initial !important;
    left: 39px;
  }
}
.memorize-navbar .login-and-menu ul {
  margin: 0px;
  padding: 0px;
}
.memorize-navbar .login-and-menu ul li {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: inline-block;
}
.memorize-navbar .login-and-menu a.circle-link {
  display: inline-block;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  text-align: center;
  font-size: 18px;
  margin-left: 25px;
  position: relative;
}
@media (max-width: 768px) {
  .memorize-navbar .login-and-menu a.circle-link {
    margin-left: 0px;
    height: 30px;
    font-size: 14px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .memorize-navbar .login-and-menu a.circle-link {
    margin-left: 10px;
  }
}
.memorize-navbar .login-and-menu a.circle-link.login-btn {
  color: #294177;
  border: 2px #294177 solid;
  font-weight: 900;
  height: 50px;
  line-height: 46px;
  width: 100px;
  transition: 0.5s;
}
.memorize-navbar .login-and-menu a.circle-link.login-btn:hover {
  background: #294177;
  color: #ffffff;
}
@media (max-width: 768px) {
  .memorize-navbar .login-and-menu a.circle-link.login-btn {
    width: 32px;
    height: 32px;
    line-height: 30px;
  }
}
.memorize-navbar .login-and-menu a.circle-link.login-btn .icon {
  display: none;
}
@media (max-width: 768px) {
  .memorize-navbar .login-and-menu a.circle-link.login-btn .icon {
    display: inline-block;
  }
}
@media (max-width: 768px) {
  .memorize-navbar .login-and-menu a.circle-link.login-btn .login-txt {
    display: none;
  }
}
.memorize-navbar .login-and-menu a.circle-link.hamburger-menu {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
  color: #ffffff;
  line-height: 52px;
  width: 50px;
  height: 50px;
  transition: 0.5s;
}
.memorize-navbar .login-and-menu a.circle-link.hamburger-menu:hover {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
@media (max-width: 768px) {
  .memorize-navbar .login-and-menu a.circle-link.hamburger-menu {
    width: 32px;
    height: 32px;
    line-height: 30px;
  }
}
/* .memorize-navbar .login-and-menu li.dropdown.active a {
  background-color: #294177;
  color: #fff !important;
}
.memorize-navbar .login-and-menu li.dropdown:after {
  content: "";
  position: absolute;
  display: block;
  left: 3px;
  top: -25px;
  width: 0;
  border-top: 2px #294177 solid;
  transition: .25s ease;
}
.memorize-navbar .login-and-menu li.dropdown.active:after{
  width: 100%;
} */
/* .headerContentIcon,.createContentIcon{
  display: inline-block;
  color: #294177;
  border: 2px #294177 solid;
  font-weight: 400;
  border-radius: 50%;
  line-height: 44px;
  width: 48px;
  height: 49px;
} */
.headerContentIcon {
  font-size: 26px;
  vertical-align: middle;
}
.createContentIcon {
  font-size: 23px;
  vertical-align: -2px;
}
.memorize-navbar .login-and-menu a.circle-link.logged-in-user-btn {
  color: #294177;
  border: 2px #294177 solid;
  font-weight: 400;
  height: 46px;
  line-height: 44px;
  width: 46px;
  transition: 0.5s;
}
.memorize-navbar .login-and-menu a.circle-link.logged-in-user-btn:hover {
  background: #294177;
  color: #ffffff;
}
@media (max-width: 768px) {
  .headerContentIcon,
  .createContentIcon {
    position: relative;
    left: 8px;
  }
  .arrow-top.left:after {
    top: 41px;
    left: 28px;
  }
}
.memorize-navbar .login-and-menu a.circle-link.logged-in-user-btn:after {
  display: none;
}
.memorize-navbar .login-and-menu .dropdown-menu {
  padding: 0px;
  min-width: 220px;
  max-width: 220px;
  margin-top: 10px;
}
.memorize-navbar .login-and-menu .dropdown-menu .user-name {
  background: #294177;
  color: #ffffff;
  padding: 20px 15px 20px 60px;
  position: relative;
  border-radius: 4px 4px 0px 0px;
  -webkit-border-radius: 4px 4px 0px 0px;
  -moz-border-radius: 4px 4px 0px 0px;
  min-height: 40px;
}
.memorize-navbar .login-and-menu .dropdown-menu .user-name .user-icon {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 16px;
  line-height: 34px;
  height: 34px;
  width: 34px;
  text-align: center;
  background: #ffffff;
  color: #294177;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.memorize-navbar .login-and-menu .dropdown-menu ul li {
  display: block;
  border-bottom: 1px #dddddd solid;
  position: relative;
}
.memorize-navbar .login-and-menu .dropdown-menu ul li:last-child {
  border-bottom: none;
}
.memorize-navbar .login-and-menu .dropdown-menu ul li a {
  display: block;
  padding: 10px 15px;
  color: #294177;
  transition: 0.5s;
  position: relative;
}
.memorize-navbar .login-and-menu .dropdown-menu ul li a:hover {
  color: #268cff;
  padding: 10px 15px 10px 25px;
}
.memorize-navbar .login-and-menu .dropdown-menu ul li a .icon {
  padding-right: 12px;
}
.memorize-navbar .navbar-toggler {
  border: 2px #294177 solid;
  position: absolute;
  top: 13px;
  right: 100px;
  padding: 0px;
  height: 32px;
  width: 32px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  line-height: 0px;
}
.memorize-navbar .navbar-toggler .navbar-toggler-icon {
  height: 15px;
}
.memorize-navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
@media (max-width: 768px) {
  .memorize-navbar .mobile-menu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 65px;
    border-bottom: 1px #dddddd solid;
    background: #ffffff;
    z-index: 1;
  }
  .listItemContainer {
    margin-bottom: 12px;
  }
}
.logo_slider .item {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.logo_slider .owl-carousel .owl-item img {
  max-width: 100%;
  min-height: 100%;
}
@media (max-width: 767px) {
  .logo_slider .item {
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 360px) {
  .logo_slider .item {
    width: 80px;
    height: 80px;
  }
}
/* ===== End Navbar ====== */
/* ===== Begin Footer ====== */
.memorize-footer {
  font-weight: 900;
  background-color: #294177;
  padding: 50px 0;
}
.login-memorize-footer {
  margin-top: 100px;
}
/* @media (min-width: 769px) {
  .memorize-footer {
    background-image: url(../images/footer-bg.png);
    width: 100%;
    min-height: 712px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media (min-width: 769px) {
  .memorize-footer .footer-img {
    background-image: url(../images/logo-footer.png), url(../images/logo-footer-right.png);
    background-repeat: no-repeat;
    background-position: right center, left center;
    padding-top: 250px;
  }
}  */
.memorize-footer h3 {
  color: #ffffff;
  font-size: 22px;
  font-weight: 900;
}
.memorize-footer .memorize-txt {
  text-align: right;
  align-self: flex-end;
  position: relative;
}
.memorize-footer .memorize-txt p {
  margin-bottom: 0;
  color: #fff;
  font-size: 40px;
  line-height: 44px;
  font-family: "Century-gothic-bold";
}
.memorize-footer .memorize-txt p span {
  color: #a5cc31;
}
.memorize-footer .memorize-logo {
  position: absolute;
  right: -135px;
  bottom: 0;
  background-color: #fff;
  border-radius: 100%;
  width: 160px;
  height: 160px;
  display: flex;
}
.memorize-footer .memorize-logo img {
  margin: auto;
}
@media (max-width: 767px) {
  .memorize-footer .copy-right a {
    display: block;
  }
  .memorize-footer {
    padding-bottom: 100px !important;
  }
  .memorize-footer-mobile {
    padding: 25px !important;
  }
}
@media (max-width: 500px) {
  .memorize-footer .ios-android-download-link a {
    margin-right: 10px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .memorize-footer .memorize-logo {
    width: 120px;
    height: 120px;
    right: -40px;
  }
  .memorize-footer .memorize-txt p {
    margin-right: 65px;
    font-size: 30px;
  }
  .memorize-footer .memorize-logo img {
    width: 100px;
  }
}
@media (max-width: 1199px) {
  .memorize-footer .ios-android-download-link {
    text-align: center !important;
  }
  .memorize-footer .memorize-txt {
    margin-top: 30px;
    text-align: center;
  }
  .memorize-footer-mobile .memorize-txt {
    margin-top: 0 !important;
  }
  .memorize-footer .memorize-logo {
    position: inherit;
    margin: 0px auto 10px auto;
    right: inherit;
  }
  .memorize-footer .position-inherit {
    position: inherit;
  }
  .memorize-footer {
    position: relative;
    padding-bottom: 70px;
  }
  .memorize-footer .copy-right {
    position: absolute;
    bottom: 35px;
    z-index: 99;
    margin-top: 0;
    width: 100%;
    text-align: center !important;
    right: 0;
  }
}
/* @media(min-width:768px) and (max-width:991px){
  .memorize-footer .memorize-txt{
    text-align: left;
  }
  .memorize-footer .memorize-logo{
    right: -12px;
    width: 120px;
    height: 120px;
  }
  .memorize-footer .memorize-logo img{
    width: 55px;
  }
  .memorize-footer .memorize-txt p{
    font-size: 37px;
  }
} */
.memorize-footer ul {
  margin: 0px;
  padding: 0px;
  color: #ffffff;
}
@media (max-width: 768px) {
  .memorize-footer ul {
    margin: 0px 0px 20px 0px;
  }
}
.memorize-footer ul li {
  padding: 8px 0px;
  margin: 0px;
  list-style-type: none;
}
.memorize-footer ul li a {
  color: #ffffff;
  transition: 0.5s;
}
.memorize-footer ul li a:hover {
  color: #268cff;
  padding-left: 10px;
}
.memorize-footer .ios-android-download-link {
  text-align: left;
}
.memorize-footer .ios-android-download-link a {
  border: 1px #ffffff solid;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  padding: 5px 5px 5px 45px;
  display: inline-block;
  position: relative;
  min-height: 42px;
  color: #ffffff;
  transition: 0.5s;
  margin-right: 20px;
  margin-top: 10px;
}
.memorize-footer .ios-android-download-link a:last-child {
  margin-right: 0;
}
.memorize-footer .ios-android-download-link a:hover {
  background: #268cff;
  border: 1px #268cff solid;
}
.memorize-footer .ios-android-download-link a .icon {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  display: block;
}
.memorize-footer .ios-android-download-link a .icon .icon-apple {
  font-size: 35px;
}
.memorize-footer .ios-android-download-link a .txt {
  font-size: 10px;
  display: block;
}
.memorize-footer .ios-android-download-link a .store {
  display: block;
}
.memorize-footer .subscribe {
  background: #ffffff;
  height: 60px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  padding: 4px;
  margin-top: 15px;
}
.memorize-footer .subscribe .subscribe-in {
  display: block;
  position: relative;
  padding-right: 185px;
}
.memorize-footer .subscribe .subscribe-in input {
  border: none;
  padding: 15px;
  height: 50px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}
.memorize-footer .subscribe .subscribe-in .subscribe-now-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  width: 170px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #294177;
  color: #ffffff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  transition: 0.5s;
}
.memorize-footer .subscribe .subscribe-in .subscribe-now-btn:hover {
  background: #268cff;
}
.memorize-footer .copy-right {
  text-align: left;
  color: #ffffff;
  font-size: 12px;
  margin-top: 48px;
}
.memorize-footer .back-to-top-btn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  display: none;
}
.animate-top-to-bottom {
  animation-name: move-top-to-bottom;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-iteration-count: infinite;
  animation-direction: alternative;
}
@keyframes move-top-to-bottom {
  0% {
    transform: translateY(5%);
  }
  50% {
    transform: translateY(15%);
  }
  100% {
    transform: translateY(5%);
  }
}
/* ===== End Footer ====== */
/* ===== Begin Button ====== */
a.btn-background {
  padding: 12px 22px;
  font-weight: 900;
  display: inline-block;
  transition: 0.5s;
}
a.btn-background.btn-block {
  display: block;
  text-align: center;
}
a.btn-border {
  padding: 10px 20px;
  font-weight: 900;
  display: inline-block;
}
a.btn-border.btn-block {
  display: block;
  text-align: center;
}
/* ===== End Button ====== */
/* ===== Begin Breadcrumb  ==== */
.breadcrumb-item {
  margin-top: 10px;
  text-align: right;
}
.breadcrumb-item ul {
  margin: 0px;
  padding: 0px;
}
.breadcrumb-item ul li {
  padding: 0px 5px;
  margin: 0px;
  display: inline-block;
  list-style-type: none;
  font-size: 10px;
}
.breadcrumb-item ul li:last-child {
  padding-right: 0px;
}
.breadcrumb-item ul li a {
  color: #212529;
  font-size: 14px;
}
.breadcrumb-item ul li a.active {
  color: #294177;
}
.breadcrumb-item ul li a:hover {
  color: #294177;
}
@media (max-width: 567px) {
  .breadcrumb-item {
    text-align: left;
  }
}
/* ===== End Breadcrumb  ==== */
/* ===== Begin InnerPage  ==== */
.inner-header {
  padding: 30px 0px;
}
.inner-header h2 {
  font-weight: 900;
  color: #294177;
}
/* ===== End InnerPage  ==== */
@media screen and (min-width: 993px) and (max-width: 1199px) {
  .language .dropdown .dropdown-toggle {
    text-align: center;
    margin-left: 53px;
  }
}
.h-100 {
  height: 100%;
}

.CookieConsent {
  border-top: 1px solid #fff;
  background: #294177 !important;
}
.CookieConsent #rcc-confirm-button {
  border-radius: 30px !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  color: #fff !important;
  transition: 0.5s !important;
  border: 2px solid #fff !important;
  background: transparent !important;
  padding: 10px 40px !important;
}
.CookieConsent #rcc-confirm-button:hover {
  background: #fff !important;
  color: #294177 !important;
}
@media (max-width: 767px) {
  .CookieConsent {
    justify-content: center !important;
    text-align: center;
  }
}
