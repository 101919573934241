.campaignList .custList {
  background-color: #FFFFFF;
  padding: 15px 25px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px #aaaaaa;
  margin-bottom: 50px;
}
@media (max-width: 992px) {
  .campaignList .custList .col-sm-11 {
    width: 85%;
  }
}
@media (max-width: 992px) {
  .campaignList .custList .col-sm-1 {
    width: 15%;
  }
}
.campaignList .custList .listItems {
  margin-top: 40px;
}
.campaignList .custList .listItems h2 {
  padding: 20px 8px;
  font-size: 20px;
  color: #294177;
  font-weight: 600;
}
@media (max-width: 992px) {
  .campaignList .custList .listItems h2 {
    display: none;
  }
}
@media (min-width: 992px) {
  .campaignList .custList .listItems .icns {
    display: none;
  }
}
@media (max-width: 992px) {
  .campaignList .custList .listItems .icns {
    display: block !important;
  }
}
.campaignList .custList .row {
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .campaignList .custList .custImgGrid .row6 {
    width: 50%;
  }
}
.campaignList .custList .custImgGrid img {
  width: 100%;
  margin-top: 20px;
}
.campaignList .custList .custImgGrid .imgHead {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 10px;
}
.campaignList .custList .custImgGrid .imgDesc {
  font-size: 16px;
  text-align: center;
  margin: 0;
}
.campaignList .custList img {
  width: 100%;
}
.campaignList .custList h3 {
  font-weight: 600;
  color: #294177;
  font-size: 18px;
}
.campaignList .custList p {
  position: relative;
  font-size: 16px;
}
.campaignList .custList .description {
  margin-top: 20px;
  margin-bottom: 30px;
}
.campaignList .custList .description p {
  color: #aaaaaa;
  margin-bottom: 0;
}
.campaignList .custList .description ol li::marker {
  color: #aaaaaa;
}
@media (max-width: 992px) {
  .campaignList .custList .description h5 {
    font-size: 15px;
  }
}
#owl-carousel5 .item {
  padding: 10px;
}
#owl-carousel5 .item .custExample {
  text-align: center;
  padding: 20px 10px;
  box-shadow: 0px 0px 10px #aaaaaa;
  border-radius: 20px;
}
#owl-carousel5 .item .custExample .duoImg {
  margin: 5px auto;
}
#owl-carousel5 .item .custExample .duoImg img {
  width: 50px;
  margin: 0;
  margin: 5px;
}
#owl-carousel5 .item .custExample .pricing {
  font-weight: 600;
  font-size: 18px;
  margin: 15px 0;
}
#owl-carousel5 .item .custExample .brd {
  width: 80%;
  margin: 5px auto;
  height: 1px;
  background-color: #aaaaaa;
}
#owl-carousel5 .item .custExample img {
  margin: 10px auto;
  height: 31px;
  width: auto;
}
