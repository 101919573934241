.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueGradient90D {
  background: #16457b;
  background: linear-gradient(90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueGradient90DR {
  background: #16457b;
  background: linear-gradient(-90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.BoxShadowHover {
  box-shadow: 0px 0px 12px -3px #294177;
  -webkit-box-shadow: 0px 0px 12px -3px #294177;
  -moz-box-shadow: 0px 0px 12px -3px #294177;
}
.BorderRadius4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.BorderRadius10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.BorderRadius100 {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.Border {
  border: 1px #dddddd solid;
}
.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WhiteBg {
  background: #FFFFFF;
}
.Transition {
  transition: .5s;
}
/* === Begin Invite Group === */
.invite-group-box {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 30px;
}
@media (max-width: 567px) {
  .invite-group-box {
    padding: 15px;
  }
}
.invite-group-box h2 {
  color: #294177;
  margin-bottom: 30px;
}
.invite-info .search-control {
  position: relative;
  margin-bottom: 30px;
}
.invite-info .search-control .search-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  display: inline-block;
  color: #294177;
}
.invite-info .search-control .search-icon:hover {
  color: #268cff;
}
.invite-info .search-control input {
  width: 100%;
  border: 1px #dddddd solid;
  padding: 10px 40px 10px 15px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.invite-info .invite-listing {
  margin-top: 30px;
  color:#294177;
}
.invite-info .invite-listing .invite-list-box {
  position: relative;
  border-bottom: 1px #dddddd solid;
  cursor: pointer;
  padding: 10px 70px 10px 100px;
  min-height: 90px;
}
@media (max-width: 567px) {
  .invite-info .invite-listing .invite-list-box {
    padding: 15px 0px;
  }
}
.invite-info .invite-listing .invite-list-box.active {
  background: #f4f4f4;
}
.invite-info .invite-listing .invite-list-box:hover {
  background: #f4f4f4;
}
.invite-info .invite-listing .invite-list-box .user-img {
  position: absolute;
  left: 10px;
  top: 10px;
  display: inline-block;
  height: 70px;
  width: 70px;
  padding: 5px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
@media (max-width: 567px) {
  .invite-info .invite-listing .invite-list-box .user-img {
    position: relative;
    margin: 0px auto;
    left: initial;
    top: initial;
    display: block;
  }
}
.invite-info .invite-listing .invite-list-box .user-img img {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.invite-info .invite-listing .invite-list-box .user-name {
  padding-top: 10px;
}
@media (max-width: 567px) {
  .invite-info .invite-listing .invite-list-box .user-name {
    text-align: center;
    padding-bottom: 10px;
  }
}
.invite-info .invite-listing .invite-list-box .links {
  position: absolute;
  right: 15px;
  top: 30px;
  display: inline-block;
  text-align: center;
}
.invite-info .invite-listing .invite-list-box .links.remove {
  color: #ff0000;
}
.invite-info .invite-listing .invite-list-box .links.remove:hover {
  color: #ca0202;
}
.invite-info .invite-listing .invite-list-box .links.add {
  color: #294177;
}
.invite-info .invite-listing .invite-list-box .links.add:hover {
  color: #268cff;
}
@media (max-width: 567px) {
  .invite-info .invite-listing .invite-list-box .links {
    position: relative;
    margin: 0px auto;
    top: initial;
    right: initial;
    display: block;
  }
}
.invite-info .invite-listing .invite-list-box .links .txt:hover {
  font-weight: bold;
}
.invite-info .invite-listing .invite-list-box .links span {
  display: block;
}
.invite-info .invite-listing .invite-btn {
  margin-top: 30px;
}
.group-listing {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 30px;
  margin: 50px 0;
}
@media (max-width: 567px) {
  .group-listing {
    padding: 15px;
  }
}
.group-listing .group-name {
  color: #294177;
  font-size: 24px;
}
.group-listing .group-list-box {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  padding: 20px;
  border: 2px #dddddd solid;
  cursor: pointer;
  margin-bottom: 10px;
  display: block;
}
.group-listing .group-list-box:hover {
  border: 2px #294177 solid;
}
.group-listing .group-list-box .group-description {
  color: #212529;
}
.group-profile {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 30px;
  margin: 50px 0;
  color:#294177;
}
@media (max-width: 568px) {
  .group-profile {
    padding: 15px;
  }
}
.group-profile .invite-info {
  margin-top: 30px;
  color: #294177;
}
.group-profile .group-name {
  color: #294177;
  font-size: 24px;
}
@media (max-width: 568px) {
  .group-profile .group-name {
    font-size: 20px;
  }
}
.group-profile .group-description {
  color: #212529;
}
@media (max-width: 568px) {
  .group-profile .invite-the-group-btn {
    padding-top: 10px;
  }
}
.group-member-list {
  margin-top: 20px;
}
.group-member-list strong {
  margin-bottom: 20px;
  display: block;
}
.group-member-list .group-member {
  position: relative;
  padding: 10px 10px 10px 85px;
  min-height: 90px;
  border-bottom: 1px #dddddd solid;
}
.group-member-list .group-member:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}
.group-member-list .group-member .member-img {
  position: absolute;
  left: 0px;
  top: 10px;
  height: 70px;
  width: 70px;
  padding: 5px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.group-member-list .group-member .member-img img {
  height: 60px;
  width: 60px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.group-member-list .group-member .member-name {
  padding-top: 18px;
  font-size: 20px;
}
.create-group{
  margin: 50px 0;
}
.create-group .create-group-in {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 30px;
}
@media (max-width: 568px) {
  .create-group .create-group-in {
    padding: 15px;
  }
}
.create-group .create-group-in input,
.create-group .create-group-in textarea {
  width: 100%;
  border: none;
  border-bottom: 1px #dddddd solid;
  padding: 10px 0px;
  margin-bottom: 20px;
}
.notification-info {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 30px;
  margin-bottom: 30px;
}
@media (max-width: 568px) {
  .notification-info {
    padding: 15px;
  }
}
.notification-info .notification-list {
  position: relative;
  padding: 15px 15px 15px 85px;
  min-height: 100px;
  border-bottom: 1px #dddddd solid;
}
.notification-info .notification-list:last-child {
  border-bottom: none;
}
@media (max-width: 568px) {
  .notification-info .notification-list {
    padding: 15px 15px 15px 65px;
  }
}
.notification-info .notification-list .icon {
  position: absolute;
  left: 0px;
  top: 15px;
  width: 70px;
  height: 70px;
}
@media (max-width: 568px) {
  .notification-info .notification-list .icon {
    width: 50px;
    height: 50px;
  }
}
.notification-info .notification-list .icon img {
  max-width: 100%;
}
.notification-info .notification-list .msg-head {
  font-size: 24px;
  color: #212529;
}
.notification-info .notification-list .msg-txt {
  color: #888888;
}
.notification-info .notification-list .action-btn {
  text-align: right;
  padding-top: 10px;
  padding-right: 0px;
}
@media (max-width: 568px) {
  .notification-info .notification-list .action-btn {
    text-align: left;
  }
}
.add-rate-review-info .modal-content {
  border: none;
}
.add-rate-review-info .stars {
  color: #cfcfcf;
  font-size: 30px;
}
.add-rate-review-info .stars span {
  margin-right: 5px;
  cursor: pointer;
}
.add-rate-review-info .stars span:hover {
  color: #294177;
}
.add-rate-review-info .stars .active {
  color: #294177;
}
.add-rate-review-info input,
.add-rate-review-info textarea {
  border: 1px #dddddd solid;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  padding: 15px;
  width: 100%;
  margin: 10px 0px 15px;
}
.add-rate-review-info .save-cancel-btn {
  text-align: center;
}
.add-rate-review-info .save-cancel-btn a {
  min-width: 140px;
  margin: 0px 5px;
}
@media (max-width: 420px) {
  .add-rate-review-info .save-cancel-btn a {
    min-width: initial;
  }
}
.reviews-listing {
  max-height: 100px;
  overflow-y: auto;
}
.reviews-listing .review-list {
  padding: 10px 10px 10px 85px;
  min-height: 90px;
  position: relative;
  border-bottom: 1px #dddddd solid;
}
.reviews-listing .review-list:last-child {
  border-bottom: none;
}
.reviews-listing .review-list .user-img {
  position: absolute;
  left: 0px;
  top: 10px;
  height: 70px;
  width: 70px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.reviews-listing .review-list .user-img img {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  min-height: 70px;
  max-height: 70px;
  max-width: 100%;
  min-width: 70px;
}
.reviews-listing .review-list .stars {
  color: #cfcfcf;
  font-size: 18px;
}
.reviews-listing .review-list .stars span {
  margin-right: 5px;
  cursor: pointer;
}
.reviews-listing .review-list .stars span:hover {
  color: #294177;
}
.reviews-listing .review-list .stars .active {
  color: #294177;
}
.reviews-listing .review-list .comment {
  color: #888888;
}
