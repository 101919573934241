.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueGradient90D {
  background: #16457b;
  background: linear-gradient(90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueGradient90DR {
  background: #16457b;
  background: linear-gradient(-90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.BoxShadowHover {
  box-shadow: 0px 0px 12px -3px #294177;
  -webkit-box-shadow: 0px 0px 12px -3px #294177;
  -moz-box-shadow: 0px 0px 12px -3px #294177;
}
.BorderRadius4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.BorderRadius10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.BorderRadius100 {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.Border {
  border: 1px #dddddd solid;
}
.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WhiteBg {
  background: #FFFFFF;
}
.Transition {
  transition: .5s;
}
/* ===== Create Content ===== */
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs {
  border-bottom: 2px #dddddd solid;
  padding-bottom: 10px;
}
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item {
  margin-bottom: 0px;
  padding: 0px 10px 0px 0px;
}
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item:last-child {
  padding: 0px;
}
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item .nav-link {
  border: 2px #dddddd solid;
  color: #212529;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  font-weight: 900;
  font-size: 15px;
  padding: 12px 10px;
  position: relative;
}
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item .nav-link.active {
  border: 2px #294177 solid;
  color: #294177;
}
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item .nav-link.active:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -14px;
  height: 2px;
  width: 100%;
  background: #294177;
}
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item .nav-link:hover {
  border: 2px #294177 solid;
  color: #294177;
}
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item .nav-link:hover:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -14px;
  height: 2px;
  width: 100%;
  background: #294177;
}
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item .nav-link .icon {
  background-image: url(../images/language.png);
  background-repeat: no-repeat;
  width: 30px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item .nav-link .icon.english {
  background-position: 0px 0px;
}
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item .nav-link .icon.hindi {
  background-position: -30px 0px;
}
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item .nav-link .icon .chinese {
  background-position: -60px 0px;
}
.quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item .nav-link .icon.japanese {
  background-position: -90px 0px;
}
@media (max-width: 767px) {
  .quiz-creation-wrapper .quiz-creation-tabs .nav-tabs .nav-item .nav-link .txt {
    display: none;
  }
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  padding: 30px 15px;
  margin-top: 40px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
@media (max-width: 568px) {
  .quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content {
    padding: 15px 0px;
  }
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content h4 {
  color: #294177;
  text-align: center;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content h6 {
  background: #e8ecf2;
  padding: 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  color: #294177;
  font-size: 14px;
  font-weight: bold;
  margin-top: 30px;
}
@media (max-width: 568px) {
  .quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content h6 {
    margin-top: 15px;
  }
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .create-question {
  border: 1px #dddddd solid;
  padding: 30px;
}
@media (max-width: 568px) {
  .quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .create-question {
    padding: 15px;
    margin-bottom: 30px;
  }
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .create-question .question-info {
  margin-bottom: 30px;
}
@media (max-width: 568px) {
  .quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .create-question .question-info {
    margin-bottom: 15px;
  }
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .create-question .question-info .bold {
  font-weight: 900;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .create-question .question-info .count {
  text-align: right;
  color: #888888;
  font-size: 14px;
  padding-left: 0px;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .create-question .question-info .question-textarea {
  margin-top: 20px;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .inner-boxs {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  text-align: center;
  padding: 15px 10px 10px;
  border: 2px #FFFFFF solid;
  display: block;
  margin-bottom: 10px;
  min-height: 100%;
  color: #294177;
  font-weight: 900;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .inner-boxs .icon {
  display: block;
  font-size: 30px;
  margin-bottom: 10px;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .inner-boxs span {
  display: block;
  padding-top: 10px;
  font-size: 14px;
  line-height: 14px;
}
@media (max-width: 568px) {
  .quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .inner-boxs {
    display: block;
    min-height: initial;
  }
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .inner-boxs:hover {
  border: 2px #294177 solid;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .inner-boxs.active {
  border: 2px #294177 solid;
  color: #294177;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .inner-boxs img {
  max-width: 100%;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .upload-files-info {
  margin-top: 30px;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .upload-files-info .upload-files {
  border: 1px #dddddd solid;
  position: relative;
  padding: 5px;
  max-height: 100px;
  max-width: 100px;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .upload-files-info .upload-files img,
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .upload-files-info .upload-files vedio {
  max-width: 100%;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .upload-files-info .upload-files .close-btn {
  position: absolute;
  right: -9px;
  top: -9px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  color: #294177;
  cursor: pointer;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .upload-files-info .upload-files .close-btn:hover {
  color: #268cff;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .add-option-btn {
  text-align: right;
  margin: 30px 0px;
}
@media (max-width: 568px) {
  .quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .add-option-btn {
    text-align: center;
  }
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .add-option-btn a {
  color: #212529;
  font-size: 20px;
  font-weight: 900;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .add-option-btn a .icon {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  font-weight: normal;
  vertical-align: middle;
  margin-right: 10px;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .add-option-btn a:hover {
  color: #294177;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .option-list {
  margin-bottom: 15px;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .option-list .custom-control-label {
  color: #212529;
  font-weight: 900;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .option-list .inner-option {
  padding-left: 25px;
  padding-bottom: 15px;
  border-bottom: 1px #dddddd solid;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .option-list .inner-option .select-txt {
  color: #888888;
  font-size: 14px;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .option-list .inner-option .option-input {
  margin-top: 24px;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .option-list .inner-option .option-input input {
  border: none;
  border-bottom: 1px #dddddd solid;
  width: 100%;
}
.quiz-creation-wrapper .quiz-creation-tabs .quiz-creation-content .option-list .inner-option .option-input input:focus {
  border-bottom: 1px #294177 solid;
}
.quiz-creation-wrapper .hint-explanation {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  padding: 30px;
  margin: 30px 0px;
}
.quiz-creation-wrapper .hint-explanation .hint-explanation-in {
  position: relative;
  min-height: 40px;
}
@media (max-width: 768px) {
  .quiz-creation-wrapper .hint-explanation .hint-explanation-in:first-child {
    margin-bottom: 30px;
  }
}
.quiz-creation-wrapper .hint-explanation .hint-explanation-in .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
}
.quiz-creation-wrapper .hint-explanation .hint-explanation-in h4 {
  margin-bottom: 20px;
  padding-left: 55px;
}
.quiz-creation-wrapper .hint-explanation .hint-explanation-in textarea {
  min-height: 125px;
}
.quiz-creation-wrapper .hint-explanation .qc-border {
  position: relative;
}
@media (max-width: 767px) {
  .quiz-creation-wrapper .hint-explanation .qc-border {
    display: none;
  }
}
.quiz-creation-wrapper .hint-explanation .qc-border:after {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 50%;
  width: 2px;
  background: #dddddd;
}
.quiz-creation-wrapper textarea {
  width: 100%;
  border: none;
  border-bottom: 1px #dddddd solid;
}
.quiz-creation-wrapper textarea:focus {
  border-bottom: 1px #294177 solid;
}
.quiz-creation-wrapper .qc-bottom-btns {
  text-align: center;
  padding-bottom: 25px;
}
@media (max-width: 568px) {
  .quiz-creation-wrapper .qc-bottom-btns a {
    margin-bottom: 10px;
  }
  .optionListing{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.custom-control-input:checked ~ .custom-control-label {
  color: #268cff !important;
}
.typeOfAns{
  padding-left: .25rem;
  padding-right: .25rem;
}
@media screen and (max-width:576px){
    .typeOfAns,.typeOfAnsRow{
      padding-left: 15px !important; 
      padding-right: 15px !important;
    }
}
@media screen and (max-width:569px){
  .createQues,.yourAns,.selectAns{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}