.align-item-center {
  display: flex;
  align-items: center;
}
.wid-100 {
  width: 100%;
}
@media (max-width: 1080px) {
  .wid-100 {
    margin: 20px 0;
  }
}
.banner_section {
  background: #294177;
}
.banner_section .carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border-top: none;
  border-bottom: none;
  opacity: 1;
  transition: opacity 0.6s ease;
}
.banner_section .carousel-indicators li.active {
  width: 30px;
  height: 10px;
  border-radius: 20px;
  background-color: #00e7ff;
  opacity: 1;
  transition: opacity 0.6s ease;
}
.banner_section .carousel-inner {
  padding: 50px 0;
}
.dnld_btn img {
  width: 100px;
}
.logo_carousel .owl-nav{
  display: none;
}
.feature_list {
  /* list-style-image: url("../images/home/list-img.png"); */
  color: #FFFFFF;
  padding-left: 40px;
  margin: 20px 0 0 0;
  list-style: none;
}
.feature_list li {
  padding-bottom: 15px;
  position: relative;
}
.feature_list li span {
  position: absolute;
  left: -39px;
  top: -1px;
  font-size: 28px;
}
.setup-account-bg{
  text-align: center;
  margin-top: 30px;
}
.setup-account-bg a{
  padding: 10px 20px;
  display: inline-table;
  margin-left: -25px;
}
.setup-account{
  text-align: center;
  margin-top: 30px;
}
.setup-account a{
  padding: 10px 20px;
}
.banner_heading {
  font-size: 34px;
  color: #FFFFFF;
  font-weight: 600;
}
.home_page_banner_heading {
  color: #FFFFFF;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 40px;
  font-family: 'Century-gothic-bold';
}
.home_page_banner_heading span,
.benifits-txt p span,
.main_head span,
.why_mm_content span{
  color: #a5cc31;
}
.benifits-txt p small{
  font-size: 14px;
  font-family: 'Century-gothic-bold';
}
@media(max-width:767px){
  .banner_section .carousel-item .slide_img{
    margin-top: 0 !important;
  }
  .wid-100{
    margin:20px 0 0 0;
  }
  .benifits-txt{
    padding-bottom: 30px;
  }
  .benifits-txt:last-child{
    padding-left: 15px !important;
    padding-top: 30px;
    padding-bottom: 0;
  }
  .benifits-txt:after{
    width: 94% !important;
    height: 1px !important;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    top: inherit !important;
    right: 0 !important;
  }
  .feature_list li:last-child{
    padding-bottom: 0;
  }
  .setup-account-bg a{
    margin-left: 0;
  }
}
@media(min-width:768px) and (max-width:991px){
  .banner_section .carousel-item .slide_img{
    width: 70% !important;
    margin-top: 0 !important;
    margin: 0 auto;
  }
  .benifits-txt:last-child{
    padding-left: 80px !important;
  }
  .navtop .login{
    top: 25px !important;
  }
}
@media(min-width:992px) and (max-width:1200px){
  .benifits-txt:last-child{
    padding-left: 0 !important;
  }
  .benifits-txt:after{
    right: 17px !important;
  }
  .feature_list li{
    font-size: 14px;
  }

}
.navtop .login{
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 30px;
  width: 120px;
  padding: 10px;
  z-index: 999;
}
.benifits-txt{
  position: relative;
}
.benifits-txt:after {
  content: '';
  position: absolute;
  right: 20px;
  width: 1px;
  height: 95%;
  background-color: #fff;
  top: 5px;
}
.benifits-txt:last-child:after{
  display: none;
}
.benifits-txt:last-child{
  padding-left: 25px;
  padding-right: 0;
}
.benifits-txt p{
  color:#fff;
  margin-bottom: 0;
}
.cust_tabs_designing {
  background: linear-gradient(#083669, #3c83d2);
  border-radius: 15px;
  padding: 15px;
  display: inline-block;
  width: 100%;
  line-height: 2;
}
.cust_tabs_designing .nav-item {
  color: #FFFFFF;
}
@media (max-width: 768px) {
  .cust_tabs_designing .nav-item {
    display: inline-block;
  }
}
.cust_tabs_designing .nav-item .nav-link {
  color: #FFFFFF;
  background: transparent;
  border: none;
  transition-duration: 0.3s;
}
.cust_tabs_designing .nav-item .nav-link i {
  padding-right: 10px;
}
@media (max-width: 767px) {
  .cust_tabs_designing .nav-item .nav-link i {
    padding-right: 2px;
  }
}
@media (max-width: 1080px) and (min-width: 768px) {
  .cust_tabs_designing .nav-item .nav-link {
    padding: 10px 0px;
  }
}
@media (max-width: 768px) {
  .cust_tabs_designing .nav-item .nav-link {
    padding: 7px 0;
  }
}
.cust_tabs_designing .nav-item .nav-link:hover {
  color: #00e7ff;
}
.cust_tabs_designing .nav-item .nav-link:hover i {
  color: #ffc850;
}
@media (max-width: 767px) {
  .cust_tabs_designing .nav-item .nav-link {
    margin-right: 10px;
  }
}
.cust_tabs_designing .nav-item .nav-link.active {
  color: #00e7ff;
  background: transparent;
}
.cust_tabs_designing .nav-item .nav-link.active i {
  color: #ffc850;
}
@media (max-width: 767px) {
  .cust_tabs_designing {
    background: linear-gradient(#083669, #3c83d2);
    border-radius: 15px;
    padding: 15px;
    display: inline;
    width: 100%;
    line-height: 2;
    float: left;
    white-space: nowrap;
    overflow-x: auto;
  }
  .cust_tabs_designing::-webkit-scrollbar-thumb {
    background: #fff !important;
  }
}
.tabs_section .how-it-works{
  width: 70%;
  margin-bottom: 20px;
}
.tabs_section .how-it-works .setup-section{
  position: relative;
  border: 2px solid #294177;
  display: inline-table;
  width: 100%;
}
.tabs_section .how-it-works .setup-section .number-txt{
  position: absolute;
  left: -45px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  bottom: 20px;
}
.tabs_section .how-it-works .setup-section .work-txt{
  display: flex;
  flex-wrap: wrap;
}
.tabs_section .how-it-works .setup-section .work-txt b{
  width: calc(100% - 200px);
  display: inline-flex;
  align-items: center;
  padding: 10px 0;
}
.tabs_section .how-it-works .setup-section .number-txt span{
  border: 2px solid #294177;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  font-weight: bold;
  font-size: 22px;
  color: #294177;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabs_section .how-it-works .setup-section p{
  margin-bottom: 0;
  color: #000000;
  font-weight: bold;
  align-items: center;
}
.tabs_section .how-it-works .setup-section .work-txt span{
  background-color: #294177;
  color: #fff;
  width: 150px;
  display: flex;
  justify-content: center;
  margin-right: 30px;
  align-items: center;
  padding: 10px 0;
}
.tabs_section {
  /* background: #fcfcfc; */
  padding: 50px 0;
  /* background-image: url(../images/home/bg_tab.png); */
  background-position-x: right;
  background-position-y: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
}
.tabs_section .main_head {
  color: #294177;
  font-weight: bold;
  padding-bottom: 50px;
  margin-bottom: 0;
}
.tabs_section .tab-content {
  background: #FFFFFF;
  padding: 20px;
  position: relative;
  box-shadow: 3px 0px 5px #bbb;
  width: 100%;
}
@media(max-width:767px){
  .tabs_section .how-it-works .setup-section .testing{
    width: 100%;
    margin-right: 0;
  }
  .tabs_section .how-it-works .setup-section b{
    width: 100% !important;
    padding: 10px !important;
  }
  .tabs_section .how-it-works{
    width: 80%;
    padding-left: 30px;
  }
  .tabs_section .how-it-works .setup-section .number-txt{
    left: -50px;
    top: 0;
    display: inherit;
  }
  .feature_section .icon-verified{
    margin-top: 30px;
    display: block;
  }
  .tabs_section .how-it-works .setup-section .work-txt{
    flex-direction: column;
  }
  .tabs_section .how-it-works .setup-section .work-txt span{
    width: 100%;
    margin-right: 0;
    justify-content: left;
    padding: 10px;
  }
  .list-icons .icon, 
  .bg-list-icons .icon{
    top: 0 !important;
  }
  .navtop .login{
    position: inherit;
    width: 85%;
    margin-bottom: 15px;
  }
}
@media(min-width:768px) and (max-width:1200px){
  .tabs_section .how-it-works{
    width: 80%;
  }
}
.bg_grid_img {
  min-height: 230px;
  /* background-image: url(../images/home/tab_grid_img.png); */
  padding: 30px;
  background-position-x: right;
  background-position-y: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
.bg_grid_img p {
  padding-right: 200px;
}
@media (max-width: 1080px) and (min-width: 768px) {
  .bg_grid_img p {
    padding-right: 80px;
  }
}
@media (max-width: 768px) {
  .bg_grid_img p {
    padding-right: 0px;
  }
}
.bg_grid_img h2 {
  color: #104178;
  font-weight: bold;
  padding-bottom: 10px;
}
@media (max-width: 768px) {
  .bg_grid_img {
    background-image: none;
    padding: 0 ;
  }
}
.memorize-btn{
  background: linear-gradient(#083669, #3c83d2);
  padding: 50px 0px;
  margin-top: 20px;
  margin-bottom: 50px;
}
.memorizeitall-info{
  margin-top: 20px;
}
.play_btn {
  display: block;
  width: 220px;
  margin: auto;
  background: #FFFFFF;
  padding: 10px 11px;
  text-align: center;
  border-radius: 30px;
  border: 2px solid transparent;
  color: #104178;
  transition-duration: 0.4s;
}
.play_btn:hover {
  display: block;
  width: 220px;
  margin: auto;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
  padding: 10px 11px;
  text-align: center;
  border-radius: 30px;
  background-color: #104178;
}
.why_mm_tall {
  padding: 50px 0;
  background-color: #294177;
}
.why_mm_tall .carousel-inner .carousel-item {
  text-align: center;
}
.why_mm_tall .carousel-inner .carousel-item img {
  width: 65%;
}
.list-icons li,
.bg-list-icons li{
  position: relative;
  padding-bottom: 17px;
  font-weight: normal;
}
.list-icons .icon,
.bg-list-icons .icon{
  color: #fff;
  font-size: 28px;
  position: absolute;
  left: -40px;
  top: -2px;
}
.bg-list-icons .icon{
  color: #294177;
}
.feature_section .icon-verified{
  color: #569417;
  font-size: 70px;
  margin-bottom: 15px;
}
@media (max-width: 1080px) and (min-width: 768px) {
  .why_mm_tall .carousel-inner .carousel-item img {
    width: 85%;
  }
}
@media (max-width: 768px) {
  .why_mm_tall .carousel-inner .carousel-item img {
    width: 100%;
  }
}
.why_mm_tall .main_head {
  color: #fff;
  font-weight: bold;
  padding-bottom: 50px;
  margin-bottom: 0;
}
.why_mm_tall .main_head b,
.why_mm_content ul li b,
.feature_section .main_head b,
.main_head b{
  font-family: 'Century-gothic-bold';
}
.why_mm_tall .carousel-indicators {
  bottom: -35px;
}
.why_mm_tall .carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000000;
  background-clip: padding-box;
  border-top: none;
  border-bottom: none;
  opacity: 0.4;
  transition: opacity 0.6s ease;
}
.why_mm_tall .carousel-indicators li.active {
  width: 30px;
  height: 10px;
  border-radius: 20px;
  background-color: #00e7ff;
  opacity: 1;
  transition: opacity 0.6s ease;
}
.why_mm_content h3 {
  color: #000000;
  font-weight: 600;
  margin-top: 25px;
}
.why_mm_content ul {
  color: #fff;
  padding-left: 40px;
  margin-bottom: 0;
  list-style: none;
}
/* .why_mm_content p:after {
  content: '';
  position: absolute;
  left: -15px;
  top: 9px;
  height: 7px;
  width: 7px;
  background-color: #104178;
  border-radius: 100%;
} */
.icon_img {
  text-align: center;
  color: #555555;
}
.icon_img img {
  width: 100px;
  margin: auto;
  display: block;
}
.feature_card {
  text-align: center;
  padding: 40px;
  transition-duration: 0.4s;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
@media (max-width: 992px) {
  .feature_card {
    padding: 15px;
  }
}
.feature_card:hover {
  box-shadow: 0px 0px 5px #aaa;
  background: #FFFFFF;
}
.feature_card h4 {
  font-weight: 600;
  margin: 15px 0;
  color: #104178;
}
.feature_card p {
  font-weight: 600;
  color: #555;
}
.feature_card.active {
  box-shadow: 0px 0px 5px #aaa;
  background: #FFFFFF;
}
.feature_section {
  background: #fff;
  padding: 50px 0;
  color: #294177;
}
.feature_section .main_head {
  color: #294177;
  font-weight: bold;
  padding-bottom: 50px;
  margin-bottom: 0;
  padding: 0 15px 50px 15px;
}
.feature_section ul{
  padding-left: 40px;
  margin-bottom: 0;
  list-style: none;
  font-weight: bold;
}
.feature_section p{
  margin-bottom: 0;
}
.additional-features{
  background-color: #294177;
}
.additional-features .main_head{
  color: #fff;
}
.all-creators .bg-list-icons li{
  color:#294177;
}
.practical-use{
  border-top: 2px solid #294177;
  width: 80%;
  margin: 0 auto;
}
.member_plan_section {
  padding: 50px 0;
  position: relative;
}
.mm_heading {
  padding: 50px 0;
}
.mm_heading .main_head {
  color: #104178;
  font-weight: bold;
}
.icons_section {
  padding: 15px 0;
  transition-duration: 0.3s;
}
.icons_section .icon {
  font-size: 40px;
  color: #FFFFFF;
  margin-bottom: 15px;
  display: block;
  transition-duration: 0.3s;
}
.icons_section:hover .icon {
  color: #00e7ff;
}
.icons_section:hover p {
  color: #00e7ff;
}
.icons_section p {
  color: #FFFFFF;
  transition-duration: 0.3s;
  font-weight: 600;
}
/* .org_seller {
  padding-bottom: 50px;
} */
.org_seller .grid-header{
  margin-bottom: 50px;
}
@media(max-width:767px){
  .org_seller_grid{
    height: auto !important;
  }
}
.org_seller .main_head {
  color: #104178;
  font-weight: bold;
  margin-bottom: 20px;
  position: relative;
  min-height: 65px;
}
.org_seller_grid {
  width: 100%;
  text-align: center;
  margin: auto;
  height: 86%;
  padding: 20px;
  box-shadow: 0px 0px 5px #aaa;
  border-radius: 20px;
  padding-bottom: 40px;
  position: relative;
}
.org_seller_grid .icon{
  color: #104178;
  font-size: 52px;
}
.org_seller_grid .bord_bot {
  border-bottom: 1px solid #bbb;
  margin: 10px auto;
  width: 70%;
}
.org_seller_grid .add_mbtn {
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  left: 0;
  bottom: 20px;
  transition-duration: 0.2s;
}
.org_seller_grid .add_mbtn:hover {
  color: #083669;
}
.org_seller_grid .list_item {
  margin: 21px auto;
  font-weight: 600;
}
.logo_carousel {
  background: #294177;
  padding: 50px 0px;
}
.banner_carousel img{
  height:300px
}
.logo_carousel .main_head {
  color: #FFFFFF;
  font-weight: bold;
  padding-bottom: 50px;
  margin-bottom: 0;
}
.logo_carousel .play_btn{
  margin-top: 50px;
}
@media(max-width:767px){
  .client-info{
    text-align: center !important;
  }
  .client-img{
    text-align: center !important;
  }
}
.client_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
}
.client_feedback .setup-account{
  margin-top: 60px;
}
.client_talk {
  margin: auto auto 15px auto;
}
.client-img{
  text-align: right;
}
.client-info{
  text-align: left;
}
.client_talk .user-img{
  width: 75px;
  height: 75px;
  display: inline-block;
}
.user-img img, 
.company-logo img {
  max-width: 100%;
}
.client_talk .company-logo {
  width: 65px;
  height: 65px;
  margin-right: 10px;
  display: inline-block;
}
.client_talk h6 {
  color: #00e7ff;
}
.client_talk h5 {
  font-size: 18px;
  font-weight: 900;
  margin: 6px 0;
}
.client_feedback {
  padding: 50px 0;
}
.client_feedback .main_head {
  color: #104178;
  font-weight: bold;
  padding-bottom: 50px;
}
.client_feedback .carousel-indicators {
  bottom: -60px;
  opacity: 1;
}
.client_feedback .carousel-indicators li {
  position: relative;
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 25px;
  height: 25px;
  margin-right: 0px;
  margin-left: 0px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.7;
  transition: opacity 0.6s ease;
}
.client_feedback .carousel-indicators li img {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 0;
  top: 0;
}
.client_feedback .carousel-indicators li.active {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 28px;
  height: 28px;
  margin-right: 0px;
  margin-left: 0px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 1;
  transition: opacity 0.6s ease;
}
.client_feedback .carousel-indicators li.active img {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 0;
  top: 0;
}
@media (min-width: 768px) {
  .banner_section .carousel-item .slide_img {
    width: 80%;
    margin:0 auto;
  }
  .client_talk {
    width: 70%;
  }
  .bg_gradient {
    z-index: -1;
    background: linear-gradient(#083669, #3c83d2);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 75%;
  }
  .bg_grid_img {
    padding-right: 0px;
  }
  .tabs_section .tab-content {
    margin-left: -30px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
}
@media (min-width: 1080px) {
  .bg_grid_img {
    padding-right: 0px;
  }
}
@media (max-width: 768px) {
  .client_talk {
    width: 100%;
  }
  .bg_gradient {
    z-index: -1;
    background: linear-gradient(#083669, #3c83d2);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  .banner_section .carousel-item .slide_img {
    margin-top: 40px;
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 30px;
  }
  .tabs_section .tab-content {
    left: 0px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
    margin-top: 20px;
  }
  .icons_section {
    padding: 15px 0;
    text-align: center;
  }
}
