
.Btnlinkedin {
  color:#FFFFFF;
  background-color: #FFFFFF;
  margin: 0; padding: 0;
  border: #FFFFFF;
}

.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.BorderRadius4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.BorderRadius100 {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.Border {
  border: 1px #dddddd solid;
}
.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WhiteBg {
  background: #FFFFFF;
}
.Transition {
  transition: .5s;
}
/* ===== Login ===== */
.login-form-control {
  padding: 100px 0px 0px;
  margin-bottom: 50px;
}
@media (min-width: 769px) {
  .login-form-control .login-bg-img {
    background-image: url(../images/brain-left.png), url(../images/brain-right.png);
    background-repeat: no-repeat;
    background-position: left top, right bottom;
    padding: 50px 0px;
  }
}
@media (max-width: 768px) {
  .login-form-control {
    padding: 70px 0px 30px;
  }
}
.login-form-control .brain-left {
  background-image: url('../images/brain-left.png');
  background-repeat: no-repeat;
  background-position: left top;
}
.login-form-control .brain-right {
  background-image: url('../images/brain-right.png');
  background-repeat: no-repeat;
  background-position: right bottom;
}
.login-form-control .same-height {
  min-height: 100%;
}
.login-form-control .same-height p{
  margin-bottom: 0;
}
#language-form h4{
  font-weight: bold;
  color: #294177;
  font-family: 'Century-gothic-regular';
}
#language-form h4 span{
  color:#a5cc31;
}
.login-form-control .login-box {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 65px 20px 20px;
}
.login-form-control .login-box .login-box-logo {
  position: absolute;
  left: 0px;
  right: 0px;
  top: -50px;
  height: 100px;
  width: 100px;
  line-height: 100px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  background: #FFFFFF;
  text-align: center;
  border: 1px #dddddd solid;
  margin: 0px auto;
}
.login-form-control .login-box h4 {
  text-align: center;
  font-weight: 900;
  margin-bottom: 20px;
}
.login-form-control .login-box .input-field {
  margin-bottom: 20px;
}
.login-form-control .login-box .forgot-password-link {
  text-align: right;
  margin-bottom: 20px;
}
.login-form-control .login-box .forgot-password-link a {
  color: #888888;
  text-decoration: underline;
}
.login-form-control .login-box .forgot-password-link a:hover {
  color: #294177;
}
.login-form-control .login-box .login-btn a {
  /* background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1); */
  text-align: center;
  display: block;
  padding: 10px;
  font-weight: 900;
}
.login-form-control .or-login-via {
  text-align: center;
  position: relative;
}
@media (max-width: 768px) {
  .login-form-control .or-login-via {
    margin-top: 30px;
  }
}
.login-form-control .or-login-via:before {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 1px;
  height: 100%;
  background: #dddddd;
  z-index: -1;
}
@media (max-width: 768px) {
  .login-form-control .or-login-via:before {
    height: 1px;
    width: 100%;
    left: 0px;
    right: 0px;
    top: 50%;
    bottom: auto;
  }
}
.login-form-control .or-login-via .same-height {
  padding-top: 140px;
}
@media (max-width: 768px) {
  .login-form-control .or-login-via .same-height {
    padding-top: 0px;
  }
}
.login-form-control .or-login-via .same-height .txt {
  background: #FFFFFF;
  padding: 20px 0px;
  font-weight: 900;
  color: #888888;
}
@media (max-width: 768px) {
  .login-form-control .or-login-via .same-height .txt {
    padding: 0px 20px;
    display: inline-block;
  }
}
.login-form-control .social-link-option {
  margin-top: 60px;
}
@media (max-width: 768px) {
  .login-form-control .social-link-option {
    margin-top: 30px;
  }
}
.login-form-control .social-link-option .social-links {
  text-align: center;
}
.login-form-control .social-link-option .social-links ul {
  margin: 0px;
  padding: 0px;
}
.login-form-control .social-link-option .social-links ul li {
  margin: 0px;
  padding: 0px 5px;
  display: inline-block;
  list-style-type: none;
}
.login-form-control .social-link-option .social-links ul li a {
  background: #dddddd;
  height: 35px;
  width: 35px;
  line-height: 38px;
  text-align: center;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  display: inline-block;
  transition: .5s;
}
.login-form-control .social-link-option .social-links ul li a.facebook {
  color: #3a559f;
}
.login-form-control .social-link-option .social-links ul li a.facebook:hover {
  color: #FFFFFF;
  background: #3a559f;
}
.login-form-control .social-link-option .social-links ul li a.google-plus {
  color: #d0472d;
}
.login-form-control .social-link-option .social-links ul li a.google-plus:hover {
  color: #FFFFFF;
  background: #d0472d;
}
.login-form-control .social-link-option .social-links ul li a.twitter {
  color: #41b0ec;
}
.login-form-control .social-link-option .social-links ul li a.twitter:hover {
  color: #FFFFFF;
  background: #41b0ec;
}
.login-form-control .social-link-option .social-links ul li a.line {
  color: #2fbf16;
}
.login-form-control .social-link-option .social-links ul li a.line:hover {
  color: #FFFFFF;
  background: #2fbf16;
}
.login-form-control .social-link-option .social-links ul li a.linkedin {
  color: #007aaa;
}
.login-form-control .social-link-option .social-links ul li a.linkedin:hover {
  color: #FFFFFF;
  background: #007aaa;
}
.login-form-control .social-link-option .right-btns {
  text-align: center;
  margin-top: 60px;
}
@media (max-width: 768px) {
  .login-form-control .social-link-option .right-btns {
    margin-top: 30px;
  }
}
.login-form-control .social-link-option .right-btns a {
  display: inline-block;
}
.login-form-control .social-link-option .right-btns .link {
  margin-top: 20px;
}
.login-form-control .social-link-option .right-btns .link a {
  color: #888888;
  text-decoration: underline;
  font-weight: 900;
}
.login-form-control .social-link-option .right-btns .link a:hover {
  color: #294177;
}
.login-form-control .social-link-option .right-btns .sign-up-btn {
  margin-top: 20px;
}
.login-form-control .social-link-option .right-btns .sign-up-btn a {
  border: 2px #294177 solid;
  color: #294177;
  transition: .5s;
  padding: 10px 30px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  font-weight: 900;
}
.login-form-control .social-link-option .right-btns .sign-up-btn a:hover {
  color: #FFFFFF;
  background: #294177;
}
.login-form-control .phone-number {
  position: relative;
}
.login-form-control #countriesListID{
  padding-left: 5px;
}
.login-form-control .phone-number .dial-code {
  width: 100%;
}
.login-form-control .conditions {
  margin-bottom: 10px;
}
.login-form-control .conditions .custom-control-label {
  font-size: 13px;
}
.login-form-control #signup-form {
  display: none;
}
.terms-conditions{
  max-height: 400px;
  overflow-y: auto;
}
  