.quiz-cr {
    font-size: 14px;
}
.creator-profile.creator-pro-001 h5 {
    font-size: 12px;
    font-weight: 600;
    margin-top: 15px;
}
/* @media(min-width:768px) and (max-width:991px){
  .seller-edit{
    right: -5px;
    top: -5px;
  }
} */
.seller-edit {
    width: 35px;
    height: 35px;
    background: #fff;
    color: #000;
    text-align: center;
    border-radius: 50%;
    padding-top: 5px;
    position: absolute;
    top: 0px;
    right: 0px;
}
.quiz-cr .fa {
    font-size: 18px;
    color: yellow;
}
.about-seller {
    width: 100%;
    border-radius: 10px;
    box-shadow: 3px 3px 10px #ccc;
    padding: 15px 20px;
}
.about-seller h5{
  font-size: 18px;
  font-weight: 600;
}
.selling-reviews p {
    font-size: 15px;
    box-shadow: 2px 2px 5px #ccc;
    padding: 7px 15px;
    border-radius: 50px;
    color: #294177;
    font-weight: 600;
}
.or-folder {
  margin-bottom: 50px;
}
.seller-hind {
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0px 0px 8px #aaa;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seller-prefer p {
    font-size: 14px;
}
.seller-text p {
    font-size: 15px;
}
.seller-collection{
  width: 70px;
    height: 70px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0px 0px 8px #aaa;
    padding: 17px;
    margin: auto;
}
.seller-cate p {
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    line-height: 16px;
}
@media(max-width:767px){
  .seller-tab li.nav-item .nav-link h5 {
    font-size: 16px;
  }
  .seller-tab li.nav-item .nav-link{
    padding: 0;
  }
  .quiz_details .head_pro .profile_pick{
    top: -13px !important;
  }
  .creator-profile.creator-pro-001 h5{
    text-align: center;
  }
}
@media(max-width:992px){
  .seller-genres-detail{
    padding: 20px 15px !important;
  }
}
.seller-genres-detail .seller-tab {
  margin-bottom: 20px;
}
.seller-genres-detail {
    padding: 20px 45px;
    box-shadow: 0px 0px 12px -3px #aaa;
    border-radius: 15px;
    background: white;
}
.seller-tab {
    border-bottom: 2px solid silver;
}
.seller-tab li.nav-item .nav-link {
    color: #ccc;
}
.seller-tab .active {
  color: #294177 !important;
  font-weight: 600 !important;
    border-bottom: 2px solid #294177 !important;
}

@media (max-width: 768px){
  .seller-prefer .col-md-3,.seller-cate{
    width: 50%;
  }

}

.reviews-listing {
    max-height: 200px !important;
    overflow-y: auto;
  }
.reviews-listing .review-list { 
    padding: 10px 10px 10px 85px;
    min-height: 90px;
    position: relative;
    border-bottom: 1px #dddddd solid;
  }
  .reviews-listing .review-list:last-child {
    border-bottom: none;
  }
  .reviews-listing .review-list .user-img {
    position: absolute;
    left: 0px;
    top: 10px;
    height: 70px;
    width: 70px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
  }
  .reviews-listing .review-list .user-img img {
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    min-height: 70px;
    max-height: 70px;
    max-width: 100%;
    min-width: 70px;
  }
  .reviews-listing .review-list .stars {
    color: #cfcfcf;
    font-size: 18px;
  }
  .reviews-listing .review-list .stars span {
    margin-right: 5px;
    cursor: pointer;
  }
  .reviews-listing .review-list .stars span:hover {
    color: #294177;
  }
  .reviews-listing .review-list .stars .active {
    color: #294177;
  }
  .reviews-listing .review-list .comment {
    color: #888888;
  }
  