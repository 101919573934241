.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueGradient90D {
  background: #16457b;
  background: linear-gradient(90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueGradient90DR {
  background: #16457b;
  background: linear-gradient(-90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.BoxShadowHover {
  box-shadow: 0px 0px 12px -3px #294177;
  -webkit-box-shadow: 0px 0px 12px -3px #294177;
  -moz-box-shadow: 0px 0px 12px -3px #294177;
}
.BorderRadius4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.BorderRadius10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.BorderRadius100 {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.Border {
  border: 1px #dddddd solid;
}
.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WhiteBg {
  background: #ffffff;
}
.Transition {
  transition: 0.5s;
}
/* ===== Create Content ===== */
.create-content-wrapper .create-content-tabs .nav-tabs {
  border-bottom: 2px #dddddd solid;
  padding-bottom: 10px;
}
.create-content-wrapper .create-content-tabs .nav-tabs .nav-item {
  margin-bottom: 0px;
  padding: 0px 10px 0px 0px;
}
.create-content-wrapper .create-content-tabs .nav-tabs .nav-item:last-child {
  padding: 0px;
}
.create-content-wrapper .create-content-tabs .nav-tabs .nav-item .nav-link {
  border: 2px #dddddd solid;
  color: #212529;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  font-weight: 900;
  font-size: 15px;
  padding: 12px 10px;
  position: relative;
}
.create-content-wrapper
  .create-content-tabs
  .nav-tabs
  .nav-item
  .nav-link.active {
  border: 2px #294177 solid;
  color: #294177;
}
.create-content-wrapper
  .create-content-tabs
  .nav-tabs
  .nav-item
  .nav-link.active:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -14px;
  height: 2px;
  width: 100%;
  background: #294177;
}
.create-content-wrapper
  .create-content-tabs
  .nav-tabs
  .nav-item
  .nav-link:hover {
  border: 2px #294177 solid;
  color: #294177;
}
.create-content-wrapper
  .create-content-tabs
  .nav-tabs
  .nav-item
  .nav-link:hover:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -14px;
  height: 2px;
  width: 100%;
  background: #294177;
}
.create-content-wrapper
  .create-content-tabs
  .nav-tabs
  .nav-item
  .nav-link
  .icon {
  background-image: url(../images/language.png);
  background-repeat: no-repeat;
  width: 30px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.create-content-wrapper
  .create-content-tabs
  .nav-tabs
  .nav-item
  .nav-link
  .icon.english {
  background-position: 0px 0px;
}
.create-content-wrapper
  .create-content-tabs
  .nav-tabs
  .nav-item
  .nav-link
  .icon.hindi {
  background-position: -30px 0px;
}
.create-content-wrapper
  .create-content-tabs
  .nav-tabs
  .nav-item
  .nav-link
  .icon
  .chinese {
  background-position: -60px 0px;
}
.create-content-wrapper
  .create-content-tabs
  .nav-tabs
  .nav-item
  .nav-link
  .icon.japanese {
  background-position: -90px 0px;
}
@media (max-width: 568px) {
  .create-content-wrapper
    .create-content-tabs
    .nav-tabs
    .nav-item
    .nav-link
    .txt {
    display: none;
  }
}
.create-content-wrapper .add-quiz-btn {
  text-align: center;
  margin: 30px 0px;
}
.create-content-wrapper .add-quiz-btn a {
  color: #294177;
  font-size: 20px;
}
.create-content-wrapper .add-quiz-btn a .icon {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.create-content-wrapper .add-quiz-btn a:hover {
  color: #294177;
}
.create-content-wrapper .create-content-accordion .card {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.create-content-wrapper .create-content-accordion .card .card-header {
  margin-bottom: 0px;
  border-bottom: none;
  position: relative;
  padding: 12px 20px 12px 50px;
  font-weight: bold;
  cursor: pointer;
}
.create-content-wrapper .create-content-accordion .card .card-header:after {
  font-family: "icomoon";
  content: "\e930";
  position: absolute;
  top: 12px;
  left: 20px;
  color: #888888;
  font-weight: normal;
  font-size: 14px;
}
.create-content-wrapper
  .create-content-accordion
  .card
  .card-header.collapsed:after {
  content: "\e93d";
}
.create-content-wrapper .create-content-accordion .card .card-header .question {
  color: #888888;
  font-size: 14px;
  text-align: right;
  font-weight: normal;
  padding-left: 0px;
}
@media (max-width: 568px) {
  .create-content-wrapper
    .create-content-accordion
    .card
    .card-header
    .question {
    text-align: left;
    padding-left: 15px;
  }
}
.create-content-wrapper
  .create-content-accordion
  .card
  .card-header
  .question
  .icon-next {
  font-size: 11px;
  padding-left: 10px;
}
.create-content-wrapper .create-content-accordion .card .card-body {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.create-topic-box {
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 40px;
}
.create-topic-box .header {
  background: rgba(0, 0, 0, 0.03);
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 900;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.add-qa {
  margin-bottom: 50px;
}
a.btn-background {
  padding: 10px 22px;
}
.create-topic-box .inner-box {
  padding: 20px;
}

.create-topic-box .inner-box .inner-box-input input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  margin-bottom: 30px;
}
.create-topic-box .inner-box .form-control {
  border: none;
  border-bottom: 1px #dddddd solid;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
}
.create-topic-box .inner-box .form-control:focus {
  outline: none;
  border-bottom: 1px #294177 solid;
}
.create-topic-box .inner-box textarea {
  min-height: 150px;
}
.Toastify__toast-container--top-right {
  width: 600px !important;
}

.form-check-input {
  cursor: pointer;
}

.form-check-label {
  cursor: pointer;
}

.form-check-input:checked + .form-check-label {
  font-weight: bold; /* Bold the label when checked */
}
.form-check-input {
  width: 20px;
  height: 20px;
  background-color: #f8f9fa;
  border-radius: 50%;
}

.form-check-input:checked {
  background-color: #007bff;
  border-color: #007bff;
}
.form-check input[type="radio"]:hover {
  box-shadow: none; /* Remove shadow on hover */
}

.form-check input[type="radio"]:focus {
  outline: none; /* Remove outline on focus */
  box-shadow: none; /* Remove shadow when focused */
}

.form-check {
  position: relative; /* Allows positioning of the radio buttons */
  display: flex; /* Ensures the label and radio button are aligned */
  align-items: center; /* Center vertically */
}

.form-check input[type="radio"] {
  margin-right: 5px; /* Space between the radio and label */
}

.form-check input[type="radio"]:hover {
  box-shadow: none; /* Remove shadow on hover */
}

.form-check input[type="radio"]:focus {
  outline: none; /* Remove outline on focus */
  box-shadow: none; /* Remove shadow on focus */
}

.form-check-label:hover {
  outline: none; /* Remove any outline on hover for label */
  box-shadow: none; /* Remove shadow on hover for label */
}

.radioContainer {
  input:hover,
  input:focus {
    outline: auto !important;
    -webkit-appearance: auto;
  }
}
