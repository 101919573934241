@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?whncrv");
  src: url("../fonts/icomoon.eot?whncrv#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?whncrv") format("truetype"),
    url("../fonts/icomoon.woff?whncrv") format("woff"),
    url("../fonts/icomoon.svg?whncrv#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-circle-minus:before {
  content: "\e9f7";
}
.icon-circle-minus1:before {
  content: "\e9f8";
}
.icon-apple:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-calendar1:before {
  content: "\e904";
}
.icon-call:before {
  content: "\e905";
}
.icon-camera:before {
  content: "\e906";
}
.icon-camera1:before {
  content: "\e907";
}
.icon-circle-arrow-down:before {
  content: "\e908";
}
.icon-circle-arrow-left:before {
  content: "\e909";
}
.icon-circle-arrow-right:before {
  content: "\e90a";
}
.icon-circle-arrow-top:before {
  content: "\e90b";
}
.icon-close:before {
  content: "\e90c";
}
.icon-content:before {
  content: "\e90d";
}
.icon-dashboard:before {
  content: "\e90e";
}
.icon-diamond:before {
  content: "\e90f";
}
.icon-down:before {
  content: "\e910";
}
.icon-download:before {
  content: "\e911";
}
.icon-edit:before {
  content: "\e912";
}
.icon-email:before {
  content: "\e913";
}
.icon-eye:before {
  content: "\e914";
}
.icon-facebook:before {
  content: "\e915";
}
.icon-favorite:before {
  content: "\e916";
}
.icon-filter:before {
  content: "\e917";
}
.icon-globe:before {
  content: "\e918";
}
.icon-google-plus:before {
  content: "\e919";
}
.icon-graduation:before {
  content: "\e91a";
}
.icon-heart:before {
  content: "\e91b";
}
.icon-home:before {
  content: "\e91c";
}
.icon-home1:before {
  content: "\e91d";
}
.icon-info:before {
  content: "\e91e";
}
.icon-info1:before {
  content: "\e91f";
}
.icon-instagram:before {
  content: "\e920";
}
.icon-language:before {
  content: "\e921";
}
.icon-like:before {
  content: "\e922";
}
.icon-line:before {
  content: "\e923";
}
.icon-linkedin:before {
  content: "\e924";
}
.icon-login:before {
  content: "\e925";
}
.icon-logout:before {
  content: "\e926";
}
.icon-mail:before {
  content: "\e927";
}
.icon-m-content:before {
  content: "\e928";
}
.icon-m-create:before {
  content: "\e929";
}
.icon-menu:before {
  content: "\e92a";
}
.icon-menu1:before {
  content: "\e92b";
}
.icon-m-genre:before {
  content: "\e92c";
}
.icon-m-group:before {
  content: "\e92d";
}
.icon-m-hamburger:before {
  content: "\e92e";
}
.icon-m-home:before {
  content: "\e92f";
}
.icon-minus:before {
  content: "\e930";
}
.icon-m-language:before {
  content: "\e931";
}
.icon-m-mail:before {
  content: "\e932";
}
.icon-m-memorizeitall1:before {
  content: "\e933";
}
.icon-m-memorizeitall2:before {
  content: "\e934";
}
.icon-m-notifications:before {
  content: "\e935";
}
.icon-m-profile:before {
  content: "\e936";
}
.icon-m-search:before {
  content: "\e937";
}
.icon-m-settings:before {
  content: "\e938";
}
.icon-my-credits:before {
  content: "\e939";
}
.icon-next:before {
  content: "\e93a";
}
.icon-password:before {
  content: "\e93b";
}
.icon-phone:before {
  content: "\e93c";
}
.icon-plus:before {
  content: "\e93d";
}
.icon-previous:before {
  content: "\e93e";
}
.icon-question:before {
  content: "\e93f";
}
.icon-question1:before {
  content: "\e940";
}
.icon-reward:before {
  content: "\e941";
}
.icon-search:before {
  content: "\e942";
}
.icon-share:before {
  content: "\e943";
}
.icon-share-app:before {
  content: "\e944";
}
.icon-sign-out:before {
  content: "\e945";
}
.icon-smile:before {
  content: "\e946";
}
.icon-star:before {
  content: "\e947";
}
.icon-star1:before {
  content: "\e948";
}
.icon-suitcase:before {
  content: "\e949";
}
.icon-target:before {
  content: "\e94a";
}
.icon-tick:before {
  content: "\e94b";
}
.icon-time-left:before {
  content: "\e94c";
}
.icon-trash-bin:before {
  content: "\e94d";
}
.icon-twitter:before {
  content: "\e94e";
}
.icon-user:before {
  content: "\e94f";
}
.icon-user1:before {
  content: "\e950";
}
.icon-user-email:before {
  content: "\e951";
}
.icon-sales-report:before {
  content: "\e952";
}
.icon-sales-report1:before {
  content: "\e953";
}
.icon-phone1:before {
  content: "\e954";
}
.icon-referral:before {
  content: "\e955";
}
.icon-email1:before {
  content: "\e956";
}
.icon-help:before {
  content: "\e957";
}
.icon-refer:before {
  content: "\e958";
}
.icon-review:before {
  content: "\e959";
}
.icon-accuracy:before {
  content: "\e95a";
}
.icon-audio:before {
  content: "\e95b";
}
.icon-calendar2:before {
  content: "\e95c";
}
.icon-content1:before {
  content: "\e95d";
}
.icon-creators:before {
  content: "\e95e";
}
.icon-delete:before {
  content: "\e95f";
}
.icon-edit1:before {
  content: "\e960";
}
.icon-folder:before {
  content: "\e961";
}
.icon-free:before {
  content: "\e962";
}
.icon-free1:before {
  content: "\e963";
}
.icon-group:before {
  content: "\e964";
}
.icon-image:before {
  content: "\e965";
}
.icon-invite:before {
  content: "\e966";
}
.icon-lock:before {
  content: "\e967";
}
.icon-paid:before {
  content: "\e968";
}
.icon-paid1:before {
  content: "\e969";
}
.icon-text:before {
  content: "\e96a";
}
.icon-video:before {
  content: "\e96b";
}
.icon-world:before {
  content: "\e96c";
}
.icon-bulb:before {
  content: "\e96d";
}
.icon-chart:before {
  content: "\e96e";
}
.icon-cup:before {
  content: "\e96f";
}
.icon-explanation:before {
  content: "\e970";
}
.icon-target1:before {
  content: "\e971";
}
.icon-verified:before {
  content: "\e972";
}
.icon-eduction:before {
  content: "\e973";
}
.icon-leadership:before {
  content: "\e974";
}
.icon-industry:before {
  content: "\e975";
}
.icon-contents:before {
  content: "\e976";
}
.icon-create-new:before {
  content: "\e977";
}
.icon-edit-content:before {
  content: "\e978";
}
.icon-folder1:before {
  content: "\e979";
}
.icon-images:before {
  content: "\e97a";
}
.icon-information:before {
  content: "\e97b";
}
.icon-points:before {
  content: "\e97c";
}
.icon-report:before {
  content: "\e97d";
}
.icon-subscription:before {
  content: "\e97e";
}
.icon-add-groups:before {
  content: "\e97f";
}
.icon-add-modules:before {
  content: "\e980";
}
.icon-boat:before {
  content: "\e981";
}
.icon-brain:before {
  content: "\e982";
}
.icon-collaboration:before {
  content: "\e983";
}
.icon-diagram:before {
  content: "\e984";
}
.icon-download1:before {
  content: "\e985";
}
.icon-download2:before {
  content: "\e986";
}
.icon-folder2:before {
  content: "\e987";
}
.icon-for-sellers:before {
  content: "\e988";
}
.icon-global-network:before {
  content: "\e989";
}
.icon-language1:before {
  content: "\e98a";
}
.icon-notification:before {
  content: "\e98b";
}
.icon-packaging:before {
  content: "\e98c";
}
.icon-preference:before {
  content: "\e98d";
}
.icon-referal:before {
  content: "\e98e";
}
.icon-refer-earn:before {
  content: "\e98f";
}
.icon-retake:before {
  content: "\e990";
}
.icon-upload:before {
  content: "\e991";
}
.icon-private-content:before {
  content: "\e992";
}
.icon-audio1:before {
  content: "\e993";
}
.icon-basic-plan:before {
  content: "\e994";
}
.icon-beginner:before {
  content: "\e995";
}
.icon-custom-plan:before {
  content: "\e996";
}
.icon-download3:before {
  content: "\e997";
}
.icon-exclamation:before {
  content: "\e998";
}
.icon-expert:before {
  content: "\e999";
}
.icon-images1:before {
  content: "\e99a";
}
.icon-intermediate:before {
  content: "\e99b";
}
.icon-money:before {
  content: "\e99c";
}
.icon-number0:before {
  content: "\e99d";
}
.icon-number1:before {
  content: "\e99e";
}
.icon-number2:before {
  content: "\e99f";
}
.icon-number3:before {
  content: "\e9a0";
}
.icon-number4:before {
  content: "\e9a1";
}
.icon-number5:before {
  content: "\e9a2";
}
.icon-number6:before {
  content: "\e9a3";
}
.icon-number7:before {
  content: "\e9a4";
}
.icon-number8:before {
  content: "\e9a5";
}
.icon-number9:before {
  content: "\e9a6";
}
.icon-premium-plan:before {
  content: "\e9a7";
}
.icon-question2:before {
  content: "\e9a8";
}
.icon-star-border:before {
  content: "\e9a9";
}
.icon-text1:before {
  content: "\e9aa";
}
.icon-youtube:before {
  content: "\e9ab";
}
.icon-anywhere:before {
  content: "\e9ac";
}
.icon-categories:before {
  content: "\e9ad";
}
.icon-communicate:before {
  content: "\e9ae";
}
.icon-contents1:before {
  content: "\e9af";
}
.icon-create:before {
  content: "\e9b0";
}
.icon-download4:before {
  content: "\e9b1";
}
.icon-efficient:before {
  content: "\e9b2";
}
.icon-free2:before {
  content: "\e9b3";
}
.icon-group1:before {
  content: "\e9b4";
}
.icon-language2:before {
  content: "\e9b5";
}
.icon-organise:before {
  content: "\e9b6";
}
.icon-push:before {
  content: "\e9b7";
}
.icon-sales-report2:before {
  content: "\e9b8";
}
.icon-sell:before {
  content: "\e9b9";
}
.icon-share1:before {
  content: "\e9ba";
}
.icon-anything:before {
  content: "\e9bb";
}
.icon-anytime:before {
  content: "\e9bc";
}
.icon-change-language:before {
  content: "\e9bd";
}
.icon-create-group:before {
  content: "\e9be";
}
.icon-create-share:before {
  content: "\e9bf";
}
.icon-creating-content:before {
  content: "\e9c0";
}
.icon-everybody:before {
  content: "\e9c1";
}
.icon-faster:before {
  content: "\e9c2";
}
.icon-group2:before {
  content: "\e9c3";
}
.icon-group-of-people:before {
  content: "\e9c4";
}
.icon-increase:before {
  content: "\e9c5";
}
.icon-language3:before {
  content: "\e9c6";
}
.icon-organistaion:before {
  content: "\e9c7";
}
.icon-pace:before {
  content: "\e9c8";
}
.icon-platform:before {
  content: "\e9c9";
}
.icon-repeating:before {
  content: "\e9ca";
}
.icon-selling-content:before {
  content: "\e9cb";
}
.icon-student:before {
  content: "\e9cc";
}
.icon-unlimited:before {
  content: "\e9cd";
}
.icon-download5:before {
  content: "\e9ce";
}
.icon-anywhere1:before {
  content: "\e9cf";
}
.icon-create-share1:before {
  content: "\e9d0";
}
.icon-organistaion1:before {
  content: "\e9d1";
}
.icon-repeating1:before {
  content: "\e9d2";
}
.icon-search1:before {
  content: "\e9d3";
}
.icon-smile1:before {
  content: "\e9d4";
}
.icon-idea:before {
  content: "\e9d5";
}
.icon-subscription1:before {
  content: "\e9d6";
}
.icon-inbox:before {
  content: "\e9d7";
}
.icon-arrow:before {
  content: "\e9d8";
}
.icon-hand:before {
  content: "\e9d9";
}
.icon-dollar:before {
  content: "\e9da";
}
.icon-mail1:before {
  content: "\e9db";
}
.icon-contents2:before {
  content: "\e9dc";
}
.icon-dollar1:before {
  content: "\e9dd";
}
.icon-edit2:before {
  content: "\e9de";
}
.icon-inbox1:before {
  content: "\e9df";
}
.icon-logout1:before {
  content: "\e9e0";
}
.icon-m-bulb:before {
  content: "\e9e1";
}
.icon-m-create1:before {
  content: "\e9e2";
}
.icon-new-login:before {
  content: "\e9e3";
}
.icon-notification1:before {
  content: "\e9e4";
}
.icon-report1:before {
  content: "\e9e5";
}
.icon-subscription2:before {
  content: "\e9e6";
}
.icon-user2:before {
  content: "\e9e7";
}
.icon-m-edit-content:before {
  content: "\e9e8";
}
.icon-refresh:before {
  content: "\e9e9";
}
.icon-refresh-add:before {
  content: "\e9ea";
}
.icon-refresh-minus:before {
  content: "\e9eb";
}
.icon-refresh-multiple:before {
  content: "\e9ec";
}
.icon-free3:before {
  content: "\e9ed";
}
.icon-paid3:before {
  content: "\e9ee";
}
.icon-add-user:before {
  content: "\e9ef";
}
.icon-camera2:before {
  content: "\e9f0";
}
.icon-close1:before {
  content: "\e9f1";
}
.icon-notification2:before {
  content: "\e9f2";
}
.icon-unlock:before {
  content: "\e9f3";
}
.icon-news:before {
  content: "\e9f4";
}
.icon-news1:before {
  content: "\e9f5";
}
.icon-tick1:before {
  content: "\e9f6";
}
