.mr-auto {
  margin: auto;
}
.bar_container {
  width: 90%;
  background-color: #f5f5f5;
  border-radius: 12px;
}
.prize {
  width: initial;
  position: absolute;
  right: 0;
  top: -8px;
  color: #294177;
  font-size: 40px;
}

.skills {
  text-align: right;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  border-radius: 12px;
  text-align: center;
}

.html {
  background: #294177;
}
.blueBg {
  background-color: #294177;
}
.mb-2px {
  margin-bottom: 2px;
}
.clock {
  display: flex;
  width: 147px;
  margin: auto;
  margin-top: 20px;
}
.clock .num {
  width: 60px;
  height: 60px;
  display: block;
  border-radius: 50%;
  border: 2px solid #9fc2e1;
  text-align: center;
  color: #9fc2e1;
  font-size: 30px;
  text-align: center;
  padding-top: 3px;
  border-top: 2px solid #ccc;
  border-right: 2px solid #ccc;
}
.colan {
  font-size: 35px;
  margin: 0 10px;
  color: #9fc2e1;
}
.min_sec {
  text-align: center;
  color: #9fc2e1;
}
.play-quiz-section {
  width: 100%;
  box-shadow: 2px 2px 10px #ddd;
  border-radius: 5px;
  padding: 18px;
  margin-top: 80px;
}
.answer-section {
  padding: 20px;
  width: 100%;
  box-shadow: 2px 2px 10px #ddd;
  border-radius: 20px;
  margin-bottom: 30px;
  border: 2px solid #999;
  color: #999;
  font-weight: 600;
  font-family: "Arial" !important;
  position: relative;
  font-size: 36px;
  font-family: arial;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.repeatplayaudio {
  width: 100%;
}
.repeatplayaudio div {
  height: 50px !important;
}
.repeatplayaudio1 div {
  height: auto !important;
}
.ans_opt .repeatplayaudio1 div {
  height: 360px !important;
}
.ans_opt img {
  height: 360px !important;
}
.your_ans {
  margin: 15px 0px;
}
.answer-section.right {
  color: green;
  border: 2px solid green;
}
.answer-section.wrong {
  color: red;
  border: 2px solid red;
}
.answer-section.unanswered {
  color: #7f807b !important;
  border: 2px solid #7f807b;
}
.answer-section.active {
  color: #294177;
  border: 2px solid #294177;
  background-color: #d3d3d370;
}
.answer-section p {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.fa.fa-arrow-left.cust_left {
  position: absolute;
  left: 20%;
  top: 26%;
  color: #294177;
  padding: 13px 15px;
  border-radius: 50%;
  box-shadow: 2px 2px 10px #ccc;
  border: 1px solid;
  font-size: 20px;
}
.fa.fa-arrow-right.cust_right {
  position: absolute;
  right: 20%;
  top: 26%;
  color: #294177;
  padding: 13px 15px;
  border-radius: 50%;
  box-shadow: 2px 2px 10px #ccc;
  border: 1px solid;
  font-size: 20px;
}
.cust_icns .col-md-4 {
  text-align: center;
}
.cust_icns .support_link {
  font-size: 16px;
  font-weight: bold;
  color: #294177;
}
.row.cust_icns .img {
  background: #fff;
  width: 85px;
  padding-top: 14px;
  box-shadow: 2px 2px 10px #ccc;
  border-radius: 50%;
  height: 85px;
  text-align: center;
  margin: auto;
  margin-bottom: 8px;
  cursor: pointer;
  z-index: 999;
  line-height: 80px;
}
.row.cust_icns .img .icon {
  font-size: 40px;
  color: #294177;
}
.row.cust_icns {
  margin-top: -60px;
  margin-bottom: 20px;
}

.opt_tetx {
  font-weight: 300;
  padding-left: 10px;
}
.answerStatusText {
  width: 100%;
  padding-left: 10px;
  display: inline-block;
}
.answerStatusText textarea {
  font-size: 36px;
  line-height: 40px;
  height: 60px;
}
.memorizeTitle {
  background: #104178;
  color: #fff;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
}
.details-play-quiz {
  max-height: 54px;
  position: relative;
  min-width: 120px !important;
}
.details-play-quiz-kyc {
  max-height: 54px;
  position: relative;
  min-width: 120px;
  max-width: 122px;
  /* align-items: center; */
  /* left: 200px; */
  top: -10px;
}
.rightArrow {
  display: inline-block;
  margin-top: -47px;
  font-size: 69px;
  color: white;
}
.rightBannerColumn {
  padding-right: 0.25rem;
}
.leftBannerColumn {
  padding-left: 0.25rem;
}
.mb-30px {
  margin-bottom: 30px;
}
.quiz_head {
  margin-bottom: 20px;
  color: #132b5d;
}
.quiz_desc {
  margin-bottom: 20px;
  font-size: 36px;
  color: #132b5d;
}
.cust_modal .modal-title {
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #294177;
}
.cust_modal .modal-body p {
  text-align: center;
  font-size: 15px;
}
.cust_modal .modal-header {
  border: none;
  padding-bottom: 0;
}
.add-rate-review-info .modal-content {
  border: none;
}
.add-rate-review-info .stars {
  color: #cfcfcf;
  font-size: 30px;
}
.add-rate-review-info .stars span {
  margin-right: 5px;
  cursor: pointer;
}
.add-rate-review-info .stars span:hover {
  color: #294177;
}
.add-rate-review-info .stars .active {
  color: #294177;
}
.add-rate-review-info input,
.add-rate-review-info textarea {
  border: 1px #dddddd solid;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  padding: 15px;
  width: 100%;
  margin: 10px 0px 15px;
}
.add-rate-review-info .save-cancel-btn {
  text-align: center;
}
.add-rate-review-info .save-cancel-btn a {
  min-width: 140px;
  margin: 0px 5px;
}
@media (max-width: 420px) {
  .add-rate-review-info .save-cancel-btn a {
    min-width: initial;
  }
  .memorizeTitle {
    font-size: 16px !important;
  }
}
@media (max-width: 450px) {
  .row.cust_icns .img {
    width: 60px;
    height: 60px;
    line-height: initial;
  }
  .row.cust_icns .img .icon {
    font-size: 30px;
  }
  .quizContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 992px) {
  .fa.fa-arrow-left.cust_left {
    position: absolute;
    left: 0%;
    top: 26%;
    color: #294177;
    padding: 13px 15px;
    border-radius: 50%;
    box-shadow: 2px 2px 10px #ccc;
    border: 1px solid;
    font-size: 20px;
  }
  .fa.fa-arrow-right.cust_right {
    position: absolute;
    right: 0%;
    top: 26%;
    color: #294177;
    padding: 13px 15px;
    border-radius: 50%;
    box-shadow: 2px 2px 10px #ccc;
    border: 1px solid;
    font-size: 20px;
  }
}
@media screen and (max-width: 576px) {
  .rightBannerColumn {
    padding-right: 15px !important;
    margin-bottom: 0.5rem;
  }
  .leftBannerColumn {
    padding-left: 15px !important;
  }
  .memorizeTitle {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .ans_opt img,
  .ans_opt .repeatplayaudio1 div {
    height: inherit !important;
  }
  .answer-section {
    padding: 5px;
    font-size: 20px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .inner-header {
    padding: 0;
  }
  .inner-header .mb-30px {
    margin-bottom: 10px;
  }
  .leftBannerColumn {
    display: none;
  }
  .gener_gap {
    margin-top: 20px;
  }
  .play-quiz-section {
    margin-top: 65px;
    padding: 10px;
  }
  .quiz_head {
    margin-bottom: 10px;
    color: #132b5d;
  }
  .quiz_desc {
    margin-bottom: 20px;
    font-size: 20px;
    color: #132b5d;
  }
  .cust_icns {
    margin-bottom: 0 !important;
  }
  .cust_icns .support_link {
    text-align: left;
  }
  .row.cust_icns .img {
    margin: 0 0 10px 0;
  }
  .your_ans {
    margin: 0 0 5px 0px;
  }
  .answerStatusText {
    padding-right: 10px;
  }
  .answerStatusText textarea {
    font-size: 20px;
    line-height: 24px;
    margin-top: 8px;
    height: 45px;
  }
}
@media only screen and (max-device-width: 767px) and (orientation: landscape) {
  .leftBannerColumn {
    display: block;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 991px) and (orientation: landscape) {
  .inner-header {
    padding: 0;
  }
  .inner-header .mb-30px {
    margin-bottom: 10px;
  }
  .gener_gap {
    margin-top: 20px;
  }
  .play-quiz-section {
    margin-top: 65px;
    padding: 10px;
  }
  .quiz_head {
    margin-bottom: 10px;
    color: #132b5d;
  }
  .quiz_desc {
    margin-bottom: 20px;
    font-size: 20px;
    color: #132b5d;
  }
  .cust_icns {
    margin-bottom: 0 !important;
  }
  .your_ans {
    margin: 0 0 5px 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .answerStatusText {
    margin-top: 5px;
    padding-right: 10px;
  }
}
@media (max-width: 767px) {
  .options {
    padding: 5px;
  }
}
