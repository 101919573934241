.no_mar {
  margin-top: 0px !important;
}
.quiz_details .box_quize {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 12px -3px #aaaaaa;
  margin-bottom: 10px !important;
}
.nav-direction {
  width: 100%;
  text-align: center;
}
.nav-direction img {
  width: 40px;
}
#owl-carousel4 .owl-item img {
  display: block;
  height: 45px;
  margin: auto;
  width: auto;
}
#owl-carousel4 .cust_genre {
  width: 100%;
  text-align: center;
}
.cust_folder {
  width: 100%;
  text-align: center;
  border-right: 1px solid #aaa;
}
.bord_sh {
  border: 1px solid #aaa;
  box-shadow: 3px 3px 10px #aaa;
  padding: 15px 10px;
  border-radius: 10px;
}
.customize_link a {
  font-size: 30px;
  color: #294177;
}
.customize_link a:hover {
  color: #268cff;
}
#owl-carousel4 .cust_genre {
  margin-top: 10px;
}
.folder-icons .cust_genre .icon {
  font-size: 40px;
  color: #294177;
}
.btn_cst {
  width: 130px;
}
/*Lokesh styles*/
.box_quize .review-section {
  margin-top: 5px;
  margin-bottom: 15px;
  max-height: 75px;
  min-height: 75px;
}
.box_quize .review-section i.fa {
  font-size: 18px;
  color: #dcdc00;
  margin-right: 10px;
  margin-top: 6px;
}
.box_quize .review-section .content-right {
  justify-content: flex-end;
}
.box_quize .review-section .language-img,
.box_quize .review-section .review-user,
.box_quize .review-section .questions-section {
  margin-top: 10px;
}
.box_quize .review-section .user-content {
  margin-top: 7px;
  text-align: right;
}
.box_quize .review-section .language-img img {
  margin: 0px 10px 10px 0;
  width: 30px;
}
.box_quize .review-section .questions-section span {
  font-size: 20px;
  color: #294177;
}
.box_quize .review-section .questions-section b {
  margin-right: 2px;
}
.box_quize .review-section .review-user {
  display: flex;
}
.box_quize .review-section .review-user span {
  font-size: 18px;
  color: #294177;
  margin-right: 10px;
  margin-top: 4px;
}
.box_quize .review-section .review-user small a {
  font-weight: bold;
  border-bottom: 2px solid;
  font-size: 17px;
  color: #294177;
  display: inline-block;
  margin-top: -3px;
}
.box_quize .review-section .questions-section b {
  color: #294177;
  margin-top: -2px;
  margin-left: 5px;
}
.box_quize .review-section .questions-section .count {
  margin-right: 15px;
}
.box_quize .review-section .questions-section {
  margin-top: 10px;
  margin-left: auto;
}
.box_quize .heading-text {
  min-height: 55px;
}
.box_quize .create_by {
  min-height: 60px;
}
.box_quize .user-content-info {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 28vh;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box_quize .user-content span {
  /* box-shadow: 0px 0px 12px -3px #aaa;
    -webkit-box-shadow: 0px 0px 12px -3px #aaa;
    -moz-box-shadow: 0px 0px 12px -3px #aaa; */
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  text-align: center;
  /* padding: 6px; */
  border: 2px #ffffff solid;
  margin-bottom: 15px;
  color: #294177;
  font-size: 24px;
}
/*Lokesh styles ends*/
@media (min-width: 1200px) {
  .profile_pick {
    top: -15px !important;
  }
}
/*Lokesh styles*/
@media (min-width: 992px) and (max-width: 1100px) {
  .box_quize .user-content span {
    font-size: 22px;
  }
  .box_quize .review-section .questions-section span {
    font-size: 17px;
  }
  .box_quize .review-section .questions-section b {
    font-size: 13px;
  }
  .review-section i.fa {
    margin: 10px 7px 0 0;
  }
  .box_quize .review-section .review-user span {
    font-size: 18px;
    margin-right: 7px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
}
/*Lokesh styles ends*/
@media (max-width: 576px) {
  #owl-carousel .col-xs-3 {
    width: 33%;
  }
}
@media (max-width: 767px) {
  .box_quize .review-section .content-right {
    justify-content: inherit;
  }
  .box_quize .review-section .user-content {
    text-align: left;
  }
}
