.cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 20px;
  box-shadow: 2px 2px 5px #999;
  border-radius: 14px;
}
.cards .icon{
color: #104178;
font-size: 40px;
}
.statusRow .cards{
padding: 31px 20px;
}
.car_img {
      /* position: absolute;
  width: 52px; */
}
.text_card {
  /* text-align: right;
  padding-left: 75px;
  width: 100%; */
}
.text_card p {
  margin: 0;
}
.pad_60{
padding-top: 60px;
}
.text_card span {
font-size: 37px;
  font-weight: 600;
  margin-top: -10px;
  display: block;
}
.ques img{
    width: 27px;
  position: relative;
  top: -2px;
  margin-right: 10px;
  }
  .ques{
    margin-top: 25px;
    font-weight: 600;
    color:#13457e;
  }
  .your_ans h6{
    margin-top: 25px;
    margin-bottom: 15px;
  }
  .ans_sec i{
        background: #13457e;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  padding-top: 2px;
  }
  .options{
    padding: 20px;
  position: relative;
  border: 1px solid;
  border-radius: 11px;
  display:flex;
  align-items: center;
  }
.icon_check .icon{
  color: #104178;
  font-size: 36px;
}
.ans_sec .icon_check .icon{
  font-size: 30px;
}
.ans_opt{
  padding-left: 10px;
  width: 100%;
}
.ans_opt p{
  margin-bottom: 0;
  font-size: 36px;
  font-family: arial;
}
.wrong{
border-color: red;
}
.unanswered{
    color:#7f807b !important;
     border:2px solid #7f807b;
}
.unanswered .icon_check .icon-close{
  color:#7f807b !important;
}
/* .wrong .fa{
background: red;
} */
.right_ans{
border-color: green;
}
.right_ans .fa{
background: green;
}
.bord_bot{
height: 1px;
background: #999;
width: 100%;
margin: 40px 0px;
}

/*graph*/
.side_cards {
  padding: 15px 8px;
  box-shadow: 2px 2px 10px;
  overflow: hidden;
  border-radius: 9px;
      /* margin-bottom: 30px; */
}
.btn-linkedin{
border-color: #337ab7;
font-size: 20px;
}
.btn-facebook{
background-color: #4267B2;
border-color: #4267B2;
font-size: 20px;
margin-left: 5px;
}
.btn-twitter{
background-color: #007bff;
border-color: #007bff;
font-size: 20px;
margin-left: 5px;
}
.linkedin, .facebook, .twitter{
color : #fff;
}

/* result changes code */
.mb-20px{
margin-bottom: 20px;
}
.mb-20px{
margin-bottom: 20px;
}
/* .socialBtns{
margin-left: 14px;
} */
.socialBtns div button{
margin-bottom: 5px;
border: none;
border-radius: 4px;
}
.linkedinBtn button{
margin-left: 5px;
}
.snsText{
  writing-mode: vertical-lr;
  /*transform: rotate(-180deg); */
  font-size: 10px;
  display: flex;
  justify-content: center;
}
.icon-tick{
color: green !important;
}
.icon-close{
color: #c00002 !important;
}
.icon-info1{
color: #7f807b !important;
}
.chartArea{
display: flex;
align-items: center;
}
.canvasCard,.side_cards{
height: 100% !important;
position: relative;
}
.memorizeImg{
position: absolute;
bottom: 11px;
right: 12px;
}
.socialCanvasArea{
display: flex;
}
.icon-info1:before{
content: "\e90c";
}
.descriptionArea{
background: #104178;
color: #fff;
font-weight: 500;
padding: 15px 25px;
font-size: 23px;
letter-spacing: 0.01px;
border-radius: 14px;
}
.descriptionWidth{
display: inline-block;
min-width: 156px;
margin-right: 10px;
position: absolute;
left: 0;
top: 0;
}
.memorizeLogo{
width: 171px;
object-fit: cover;
}
.content{
position: relative;
padding-left: 166px;
}
.cursor-pointer{
cursor: pointer;
}
@media screen and (max-width:991px){
.thirdCol,.fourthCol{
  margin-bottom: 20px !important;
}
.descriptionArea{
  font-size: 19px;
}
.content{
  padding-left: 146px;
}
.descriptionWidth{
  min-width: 136px;
}
.canvasCard{
  height: initial;
}
.socialCanvasArea{
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}
.snsText{
  writing-mode: initial !important;
  transform: initial;
}
.socialBtns{
  display: flex;
  justify-content: center;
}
}
@media screen and (max-width:768px){
.statusRow .cards{
  padding: 20px 15px;
}
.descriptionWidth{
  min-width: 92px;
}
.content{
  padding-left: 102px;
}
.side_cards{
  overflow: initial !important;
  margin-bottom: 17px;
}
.canvasCard{
  flex-direction: column;
}
.socialBtns{
    flex-direction: row;
  display:flex;
  margin-left: initial;
}
.socialCanvasArea{
  flex-direction: column;
}
.snsText{
  writing-mode:initial !important;
  transform: initial !important;
}
.descriptionArea{
  font-size: 13px;
}
.memorizeImg{
  bottom: 4px;
}
  .car_img .icon-tick,
  .car_img .icon-close,
  .car_img .icon-info1,
  .car_img .icon-question{
    font-size: 34px !important;
  }
}
@media screen and (max-width:468px){
.content{
  margin-bottom: 7px;
}
.text_card span{
  font-size: 25px;
}
.memorizeLogo{
  width: 161px;
}
.car_img .icon-tick,
.car_img .icon-close,
.car_img .icon-info1,
.car_img .icon-question{
  font-size: 26px !important;
}
}
@media(max-width:991px){
  .options{
    padding: 10px;
  }
  .ques,.your_ans h6{
    margin-top: 10px;
  }
  .ans_sec .icon_check .icon{
    top: 7px;
    left: 12px;
    font-size: 20px;
  }
  .bord_bot{
    margin: 15px 0;
  }
  .ans_opt p{
    font-size: 20px;
  }
  .ques img{
    margin-right: 0;
  }
}
#result-canvas,#sns-chart{
  display: none !important;
}
/* result changes code */
