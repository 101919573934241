.main-content .owl-theme .custom-nav {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
}
.main-content .owl-theme .custom-nav1 {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
}
.create_by p {
  margin: 0;
  font-size: 11px;
  color: #999;
}
.main-content {
  position: relative;
}
.main-content .owl-theme .custom-nav {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
}
.main-content .owl-theme .custom-nav .owl-prev,
.main-content .owl-theme .custom-nav .owl-next {
  position: absolute;
  height: 100px;
  color: inherit;
  background: none;
  border: none;
  z-index: 100;
}
.main-content .owl-theme .custom-nav .owl-prev i,
.main-content .owl-theme .custom-nav .owl-next i {
  font-size: 2.5rem;
  color: black;
}
.main-content .owl-theme .custom-nav .owl-prev {
  left: -3%;
}
.main-content .owl-theme .custom-nav .owl-next {
  right: -3%;
}
.main-content .owl-theme .custom-nav1 {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
}
.main-content .owl-theme .custom-nav1 .owl-prev,
.main-content .owl-theme .custom-nav1 .owl-next {
  position: absolute;
  height: 100px;
  color: inherit;
  background: none;
  border: none;
  z-index: 100;
}
.main-content .owl-theme .custom-nav1 .owl-prev i,
.main-content .owl-theme .custom-nav1 .owl-next i {
  font-size: 2.5rem;
  color: black;
}
.main-content .owl-theme .custom-nav1 .owl-prev {
  left: -3%;
}
.main-content .owl-theme .custom-nav1 .owl-next {
  right: -3%;
}

.main-content .owl-carousel .owl-item img {
  display: inline-block;
  /* width: auto !important; */
}
.quizes_boxlist img {
  width: auto !important;
}
.top-performer {
  margin-top: 50px;
}
.main-content .item {
  margin: 7px;
}
/* Quizes css */
.profile_pick {
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 8px 0px #ccc;
  position: absolute;
  top: -20px;
  border: 3px solid white;
}
.str_img img {
  height: 13px !important;
  margin-right: 0 !important;
}
.profile_pick img {
  border-radius: 50%;
}
.create_by p {
  margin: 0;
  font-size: 12px;
  color: #999;
}
.create_by h6 {
  font-size: 12px;
  color: #555;
  font-weight: 600;
}
.heart {
  display: flex;
  justify-content: space-evenly;
}
.heart span:nth-child(1) {
  background: pink;
  padding: 3px 10px;
  border-radius: 50%;
  margin-right: 3px;
}
.heart span:nth-child(1) img {
  height: 11px;
  max-width: initial;
}

.heart span:nth-child(2) {
  background: #188dd273;
  padding: 8px 6px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  color: #2a3f6f;
  align-items: center;
}
.head_pro h6 {
  font-size: 13px;
  font-weight: 600;
}
.head_pro p {
  font-size: 12px;
  color: #999;
}
.box_quize p {
  font-size: 12px;
  color: #999;
}
.quize_listing .continue {
  margin-top: 15px;
  padding: 9px 19px;
  outline: none !important;
  font-size: 12px;
  border-radius: 52px;
}
.quize_listing {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.quizes_boxlist {
  display: flex;
  justify-content: flex-start;
}
.quizes_boxlist img {
  height: 21px;
  margin-right: 5px;
}
.quizes_boxlist_new {
  position: relative;
  padding-left: 30px;
  min-height: 30px;
}
.quizes_boxlist_new .icon {
  position: absolute;
  top: 0px;
  left: 0px;
  color: #294177;
  font-size: 20px;
}

.quizes_boxlist p {
  font-size: 11px;
}

.box_quize {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 12px -3px #aaa;
  margin-bottom: 60px;
}
.gener_gap {
  margin-top: 50px;
  margin-bottom: 0px;
}
.advertisement-slider.margin-top {
  margin-top: 20px;
}
.cust_link .fa-arrow-right {
  font-weight: 300;
  font-size: 20px;
  margin-left: 10px;
  position: relative;
  top: -2px;
}
.cust_link h5 {
  cursor: pointer;
  font-size: 21px;
  font-weight: 900;
}
.m-fold-001 .creator-content {
  padding-left: 90px;
}
.m-fold-001 .creator-profile-img {
  position: absolute;
}
.creator-profile {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.creator-profile img {
  border-radius: 50%;
  border: 3px solid white;
  height: 80px;
  width: 80px;
  box-shadow: 0px 0px 12px -3px #aaa;
  cursor: pointer;
}
.creator-text img {
  height: 18px;
}
.creator-text .icon {
  color: #294177;
  font-size: 28px;
}
.quiz-sell1 {
  font-size: 16px;
}
.m-fold-001 {
  box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 15px;
  cursor: pointer;
  padding: 15px;
}
.song-quiz h5 {
  color: #294177;
  text-align: center;
}
.song-quiz {
  text-align: center;
}
.song-quiz .genre-icons span {
  background: #294177;
  color: #fff;
  font-size: 22px;
  padding: 10px;
  border-radius: 100%;
}
.song-quiz h3 {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .song-quiz h3 {
    font-size: 20px;
  }
  .content-profile-creator {
    padding-top: 50px;
    margin-top: 50px;
    position: relative;
  }
  .content-profile-creator .m-fold-pic {
    margin-top: -93px;
  }
  .creator-text .creator-text-padding {
    padding: 0.25rem 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .song-quiz {
    margin-bottom: 20px;
  }
  /* .categoryImgOuter{
        width: 85px;
        height: 90px;
    } */
}
.creator-text .creator-text-padding {
  padding: 0.5rem 0;
}
.about-genres {
  box-shadow: 0px 0px 12px -3px #aaa;
  padding: 10px;
  border-radius: 20px;
}

.sub-category-title-name {
  box-shadow: 0px 0px 12px -3px #aaa;
  padding: 10px;
  border-radius: 20px;
  background: #294177;
  color: #fff;
  font-size: 2rem;
  font-weight: 900;
  min-height: 12vh;
}

.sub-category-details {
  box-shadow: 0px 0px 12px -3px #aaa;
  padding: 20px;
  border-radius: 20px;
  color: #294177;
}

.sub-category-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.bollywood-head-002 {
  text-align: center;
  color: #294177;
  margin-bottom: 30px;
  margin-top: 30px;
}
.genreHeadingTxt {
  font-size: 18px !important;
}
.pb-50px {
  padding-bottom: 75px !important;
}
.genreTxt {
  padding-bottom: 75px !important;
}
@media only screen and (max-width: 768px) {
  .listingArea,
  .genreCategoryArea {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .genres-search-008 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
/* @media only screen and (max-device-width : 767px){
    .categoryImgOuter{
        max-width: 80%;
    }
}  */
.bolly-new-song {
  margin-top: 5px;
  text-align: center;
  line-height: 1.1;
}
.denra1 {
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
  cursor: pointer;
}
.genres-songs {
  padding: 20px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.genres-songs .categoryImgOuter {
  width: 100px;
  height: 100px;
}
.genres-search-008 {
  margin-top: 40px;
}
.mar_40 {
  margin-top: 40px;
}
.genres-search-008 {
  border-radius: 25px;
  box-shadow: 0px 0px 12px -3px #aaa;
  padding: 40px 30px;
}
.music-icon {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-shadow: 0px 0px 12px -3px #aaa;
  margin: auto;
  margin-top: -65px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.genres-search .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  font-size: 25px;
  text-align: center;
  /* background-color: transparent !important; */
}
@media (min-width: 1200px) {
  .profile_pick {
    top: -45px;
  }
}

.quiz_details .quizes_boxlist img {
  width: auto !important;
}
.quiz_details .top-performer {
  margin-top: 50px;
}
@media (max-width: 767px) {
  .quiz_details .top-performer {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .customize_link {
    text-align: center;
    justify-content: space-around;
    display: flex;
    margin-top: 20px;
  }
  .cust_folder {
    border-right: 0 !important;
  }
}
@media (max-width: 567px) {
  .firstfull-credit-001 p {
    font-size: 16px !important;
  }
  /* .credit-member .detail-name{
        padding: initial !important;
    } */
}
.quiz_details .main-content .item {
  margin: 7px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .quiz_details .profile_pick {
    top: -55px !important;
  }
}
.quiz_details .profile_pick {
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 8px 0px #ccc;
  position: absolute;
  top: -35px;
  border: 3px solid white;
}
.quiz_details .str_img img {
  height: 13px !important;
  margin-right: 0 !important;
}
.quiz_details .profile_pick img {
  border-radius: 50%;
  object-fit: cover;
  height: 100%;
}
.quiz_details .create_by p {
  margin: 0;
  font-size: 12px;
  color: #999;
}
.quiz_details .create_by h6 {
  font-size: 12px;
  color: #555;
  font-weight: 600;
}
.quiz_details .heart {
  display: flex;
  justify-content: space-evenly;
}
.quiz_details .heart span:nth-child(1) {
  background: pink;
  padding: 3px 10px;
  border-radius: 50%;
  margin-right: 3px;
}
.quiz_details .heart span:nth-child(1) img {
  height: 11px;
  max-width: initial;
}
.quiz_details .heart span:nth-child(2) {
  background: #188dd273;
  padding: 8px 6px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  color: #2a3f6f;
  align-items: center;
}
.quiz_details .head_pro {
  padding-right: 95px;
  padding-left: 70px;
  position: relative;
  min-height: 30px;
}
.quiz_details .head_pro .profile_pick {
  width: 60px;
  height: 60px;
  left: 0px;
}
.quiz_details .head_pro .icons {
  position: absolute;
  right: 0px;
  top: 0px;
}
.quiz_details .head_pro .icons .icon {
  color: #009400;
  font-size: 32px;
  display: inline-block;
}

.quiz_details .head_pro .icons .paid-text {
  background-color: #294177;
  font-size: 10px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 15px;
  display: inline-block;
  color: #ffffff;
  vertical-align: top;
  margin: 2px 5px 0px 0px;
}
.quiz_details .head_pro h6 {
  font-size: 13px;
  font-weight: 600;
  width: 96%;
}
.quiz_details .head_pro p {
  font-size: 12px;
  color: #999;
}
.quiz_details .quize_listing .continue {
  margin: 0px;
  padding: 9px 19px;
  outline: none !important;
  font-size: 14px;
  background: #294177;
  color: #fff !important;
  border: 3px solid #294177;
  border-radius: 52px;
  text-shadow: 5px 3px 5px black;
}
.contentLockBox {
  position: relative;
  /* top: -5px; */
}
.contentGroupLock {
  background: #294177;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 3px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ml-5px {
  margin-left: 5px;
}
.contentGroupLock .icon {
  font-size: 18px !important;
  color: #ffffff !important;
}
/* .quiz_details .quize_listing .continue:hover {
  background: linear-gradient(to bottom, #3578c4, #16457b);
} */
.quiz_details .quize_listing {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding-top: 15px;
}
.quiz_details .quizes_boxlist {
  display: flex;
  justify-content: flex-start;
}
.quiz_details .quizes_boxlist img {
  height: 21px;
  margin-right: 5px;
}
.quiz_details .quizes_boxlist p {
  font-size: 11px;
}
.quiz_details .box_quize {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 12px -3px #aaaaaa;
  margin-bottom: 60px;
  padding: 15px;
}

@media (min-width: 768px) {
  /* .categoryImg{
        width: 100%;
    } */
  .profile_pick {
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 8px 0px #ccc;
    position: absolute;
    border: 3px solid white;
  }
  .pad_rt_5 {
    padding-right: 5px;
  }
  .pad_lft_5 {
    padding-left: 5px;
  }
}
@media (max-width: 992px) {
  .head_pro h6 {
    margin-top: 15px;
  }
}
@media (max-width: 768px) {
  .quiz-extra-info-008 .review-section .user-content {
    text-align: left;
  }
  .box_quize .review-section {
    min-height: inherit !important;
    max-height: inherit !important;
  }
  .denra1 {
    border: 1px solid silver;
  }
  .profile_pick {
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 8px 0px #ccc;
    position: relative;
    top: 0px;
    border: 3px solid white;
    width: 100px;
    margin: auto;
  }
  .create_by {
    text-align: center;
  }
}
.memorize-footer .back-to-top-btn {
  z-index: 999;
}
.banner_image {
  text-align: center;
  background: #dddddd;
}
.banner_image a {
  display: block;
}
.img_banner {
  /* max-height: 200px; */

  max-width: 100%;
  /* min-width: 70%; */
  /* object-fit: cover; */
  /* background-image: url("../images/banner.png"); */

  max-height: 400px;

  /* background-position: center; */

  /* background-repeat: no-repeat; */

  /* background-size: cover; */
}
.genre_list_img_banner {
  max-width: 100%;
  /* object-fit: cover; */
  background-image: url("../images/banner.png");

  height: 300px;

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;
}
.desktopView {
  display: block;
}
.mobileView {
  display: none;
}
@media (max-width: 992px) {
  .desktopView {
    display: none;
  }
  .mobileView {
    display: block;
  }
}
@media (max-width: 768px) {
  .genre_list_img_banner {
    background-size: contain;
  }
  .quiz-detail-page .bollywood-content {
    min-width: initial !important;
  }
}
/*Styling for carousel*/
#owl-carousel .owl-prev {
  width: 15px;
  height: 60px;
  position: absolute;
  top: 14%;
  margin-left: -25px;
  display: block !important;
  border: 0px solid black;
  font-size: 60px;
}
.cust_genre {
  width: 100%;
}
.cust_genre h4 {
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
}
#genres .item {
  /* height: 125px; */
  background: #fff;
  box-shadow: 1px 0px 8px #555;
  margin: 10px 10px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 10px;
  min-width: 40%;
  max-width: 40%;
  cursor: pointer;
}

@media (min-width: 768px) {
  #genres .item {
    min-width: 25%;
    max-width: 25%;
  }
}

@media (min-width: 992px) {
  #genres .item {
    min-width: 20%;
    max-width: 20%;
  }
}

@media (min-width: 1200px) {
  #genres .item {
    min-width: 10%;
    max-width: 10%;
  }
}

#genres .item.cur_list {
  height: 125px;
  background: #294177;
  color: #fff !important;
  box-shadow: 1px 0px 8px #555;
  margin: 10px 10px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 10px;
}
#genres .item img,
.content-001 img {
  width: 60%;
}
.create-content-wrapper {
  margin-bottom: 50px;
}
.folder-create a {
  padding: 10px 20px;
}
#owl-carousel {
  margin: 50px 0;
}
#owl-carousel .item {
  height: 125px;
  background: #fff;
  box-shadow: 1px 0px 8px #555;
  margin: 10px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 10px;
}
#owl-carousel .item.cur_list {
  height: 125px;
  background: #294177;
  color: #fff !important;
  box-shadow: 1px 0px 8px #555;
  margin: 10px 10px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 10px;
}
.performance2 {
  margin-bottom: 20px;
}
.performance3 {
  margin-bottom: 20px;
  justify-content: center;
}
.performance2 h3 {
  color: #294177;
}
@media (max-width: 767px) {
  .performance2 {
    flex-direction: column;
  }
  .performance2 h3 {
    margin-bottom: 15px;
  }
}
#owl-carousel .owl-next {
  width: 15px;
  height: 60px;
  position: absolute;
  top: 14%;
  right: -25px;
  display: block !important;
  border: 0px solid black;
  font-size: 60px;
}
#owl-carousel .item img {
  width: 35px;
  margin: auto;
}
/*cat list*/
#owl-carousel .owl-prev i,
#owl-carousel .owl-next i {
  transform: scale(1, 6);
  color: #ccc;
}

.row.genre_disp_list .item {
  width: 100%;
  text-align: center;
  box-shadow: 1px 1px 5px #999;
  margin: 0px 1px 0px 0px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  min-height: 95px;
}
.genre_disp_list .item.cur_list {
  background: #294177;
  color: #fff !important;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 5px;
}
.row.cat_disp_list .item {
  width: 100%;
  text-align: center;
  box-shadow: 1px 1px 5px #999;
  margin: 0px 1px 0px 0px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  min-height: 95px;
}
.cat_disp_list .item.cur_list {
  background: #294177;
  color: #fff !important;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 5px;
}
.genre_pref_list .item {
  width: 100%;
  text-align: center;
  box-shadow: 1px 1px 5px #999;
  margin: 0px 1px 0px 0px;
  border: 2px solid #fff;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  min-height: 95px;
}
.genre_pref_list .item.cur_list {
  border: 2px solid #294177;
}
.cust_center {
  margin: auto;
  box-shadow: 1px 1px 10px #ccc;
  padding: 20px 20px;
  border-radius: 30px;
}
.no-pad {
  padding: 5px;
}

.genre_pref_list {
  padding: 0 15px;
}
.circle {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-shadow: 1px 1px 10px #999;
  text-align: center;
  padding-top: 28px;
  margin: auto;
  border: 2px solid #fff;
}
.lang_sel p {
  margin-top: 20px;
}
.circle.active_circ {
  border: 2px solid #294177;
}
.circle1 {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-shadow: 1px 1px 10px #999;
  text-align: center;
  padding-top: 28px;
  margin: auto;
  border: 2px solid #fff;
}
.lang_sel p {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 900;
}
.circle1.active_circ {
  border: 2px solid #294177;
}
.cat_disp_list .cust_genre img,
.genre_disp_list .cust_genre img {
  width: 55%;
}
.cat_disp_list .cust_genre h4,
.genre_disp_list .cust_genre h4 {
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
}
.genre_pref_list .cust_genre img {
  width: 55%;
}
.genre_pref_list .cust_genre h4 {
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
}
.cust_center h4 {
  margin: 20px 0;
}
@media (max-width: 575px) {
  .cat_disp_list .col-xs-3,
  .genre_disp_list .col-xs-3 {
    width: 33%;
  }
  .genre_pref_list .col-xs-6 {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .col-md-3.lang_sel {
    width: 50%;
  }
}
@media (max-width: 992px) and (min-width: 768px) {
  .circle {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    box-shadow: 1px 1px 10px #999;
    text-align: center;
    padding-top: 15px;
    margin: auto;
    border: 2px solid #fff;
  }
  .circle1 {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    box-shadow: 1px 1px 10px #999;
    text-align: center;
    padding-top: 15px;
    margin: auto;
    border: 2px solid #fff;
  }
}
.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: inline-block;
  padding: 4px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 22px;
  cursor: text;
  width: 100%;
  border-radius: 0px;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  box-shadow: none;
  margin-bottom: 20px;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  box-shadow: none !important;
  margin-bottom: 20px;
}
.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: #fff !important;
  background: #294177;
  padding: 5px;
  border-radius: 4px;
}
.plus_img {
  position: absolute;
  right: 0;
  top: -18px;
  width: 45px;
}
.cust_cat_btn {
  padding: 13px 37px;
  font-size: 20px;
  font-weight: 600;
  display: block;
  width: 200px;
  margin: 30px auto;
  text-align: center;
}
/*Quiz details*/
/* .sell-pro-003 {
    background: linear-gradient(to left,#16457B,#3578C4);
    color: white;
} */
.sell-pro-003 {
  background: #294177;
  color: #fff;
}
.detail-001-text {
  margin-bottom: 50px;
}
.detail-001-text p {
  line-height: 30px;
  word-spacing: 2px;
}
.bollywood-content .icon {
  font-size: 40px;
  color: #294177;
}
.quiz-extra-info-008 .icon {
  font-size: 22px;
  color: #294177;
}
@media (max-width: 1400px) {
  .detail-001-text p {
    line-height: 24px;
    word-spacing: 0px;
    font-size: 14px;
    color: #000;
  }
  .text-white {
    font-size: 14px;
    word-spacing: 2px;
  }
  .quiz-extra-info-008 p {
    font-weight: bold;
    font-size: 14px;
  }

  .bollywood-content p {
    font-size: 12px;
  }

  .details-cate-content {
    height: 75px;
    width: 75px;
  }
  .attribute {
    font-size: 14px;
  }
  .extreum-cont img {
    height: 50px;
  }
  .extreum-cont h5 {
    font-size: 16px;
    margin-top: 15px;
  }
  .det-num-quiz {
    font-size: 18px;
  }
  .detail-availa-002 {
    font-size: 18px;
  }
  .details-expertaion {
    font-size: 18px;
    font-weight: bold;
    color: #294177;
  }
}
.det-num-quiz {
  color: #294177;
}
.detail-availa-002 {
  color: #294177;
}
.extreum-cont h5 {
  color: #08366a;
}
.details-cate-content {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  box-shadow: 0px 0px 12px -3px #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quiz-extra-info-008 {
  border-radius: 10px;
  box-shadow: 0px 0px 12px -3px #aaa;
  padding: 16px;
}
/*Lokesh styles*/
.quiz-detail-page .resume-memorising {
  margin-bottom: 20px;
}
.quiz-extra-info-008 .review-section {
  margin-top: 5px;
  margin-bottom: 15px;
}
.quiz-extra-info-008 .review-section .review-user span {
  font-size: 18px;
  color: #294177;
  margin-right: 10px;
  margin-top: 2px;
}
.quiz-extra-info-008 .review-section .language-img,
.quiz-extra-info-008 .review-section .review-user,
.quiz-extra-info-008 .review-section .questions-section {
  margin-top: 10px;
}
.quiz-extra-info-008 .review-section .questions-section .content-right {
  justify-content: flex-end;
}
.quiz-extra-info-008 .review-section .user-content {
  margin-top: 7px;
  text-align: right;
}
.quiz-extra-info-008 .review-section .language-img img {
  margin: 0px 7px 10px 0;
  width: 30px;
}
.quiz-extra-info-008 .review-section .questions-section span {
  font-size: 20px;
  color: #294177;
}
.quiz-extra-info-008 .review-section .review-user {
  display: flex;
}
.quiz-extra-info-008 .review-section .review-user a {
  font-weight: bold;
  border-bottom: 2px solid;
  font-size: 17px;
  color: #294177;
  display: inline-block;
  margin-top: -4px;
}
.quiz-extra-info-008 .review-section .questions-section .count {
  margin-right: 15px;
}
.quiz-extra-info-008 .review-section .questions-section b {
  color: #294177;
  margin-top: -2px;
  margin-left: 3px;
  font-size: 15px;
  margin-right: 2px;
}
.quiz-extra-info-008 .review-section .questions-section {
  margin-top: 10px;
}
.quiz-detail-page .content-violation {
  background-color: #fff;
  box-shadow: 0px 0px 2px #b3b3b3;
  border-radius: 100%;
  padding: 10px;
}
.quiz-detail-page .content-violation span {
  font-size: 30px;
  color: #d20000;
}
.quiz-extra-info-008 .heading-text {
  min-height: 55px;
}
.quiz-extra-info-008 .create_by {
  min-height: 60px;
}
.quiz-extra-info-008 .user-content-info {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 28vh;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.quiz-extra-info-008 .user-content span {
  /* box-shadow: 0px 0px 12px -3px #aaa;
    -webkit-box-shadow: 0px 0px 12px -3px #aaa;
    -moz-box-shadow: 0px 0px 12px -3px #aaa; */
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  text-align: center;
  /* padding: 6px; */
  border: 2px #ffffff solid;
  margin-bottom: 15px;
  color: #294177;
  margin-right: 0px;
  font-size: 22px;
}
.quiz-detail-page .bollywood-content {
  width: 100%;
  height: 100%;
  min-width: 114px;
}
.quiz-detail-page .detail-purchase {
  margin-bottom: 20px;
}
.advertisement-slider {
  margin-bottom: 20px;
}
.quiz-detail-page .creator-text a {
  color: white;
}
.quiz-detail-page .creator-text a:hover {
  text-decoration: underline;
}
.quiz-detail-page .content-report {
  /* position: fixed;
    bottom: 20px;
    right: 20px; */
  border-radius: 100%;
  box-shadow: 0px 0px 12px -3px #aaa;
  width: 50px;
  height: 50px;
  background-color: #fff;
  padding: 9px 10px;
}
.quiz-detail-page .content-report span {
  font-size: 30px;
  color: #98170e;
}
@media (max-width: 767px) {
  .resume-memorising {
    flex-direction: column;
  }
  .quiz-extra-info-008 .review-section .questions-section .content-right {
    justify-content: inherit;
  }
  .quiz-extra-info-008 .review-section .user-content {
    text-align: left;
  }
  .quiz-detail-page .content-violation {
    border-radius: 40px !important;
  }
  .quiz-detail-page .content-violation span {
    text-align: center;
    display: block;
  }
  .quiz-detail-page .resp-btn {
    flex-direction: column;
  }
  .details-play-quiz {
    margin-bottom: 10px !important;
  }
  .quiz-extra-info-008 {
    margin-top: 15px;
  }
  #owl-carousel .item {
    margin: 10px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .details-play-quiz {
    padding: 15px !important;
  }
}
@media (min-width: 992px) and (max-width: 1100px) {
  .quiz-extra-info-008 .review-section .language-img img {
    margin: 0px 6px 6px 0;
    width: 25px;
  }
  .quiz-extra-info-008 .user-content span {
    font-size: 19px;
  }
  .quiz-extra-info-008 .review-section i.fa {
    font-size: 16px;
    margin-right: 5px;
  }
  .quiz-extra-info-008 .review-section .questions-section b {
    margin-left: 0px;
    font-size: 14px;
  }
  .quiz-extra-info-008 .review-section .review-user span,
  .quiz-extra-info-008 .review-section .questions-section span {
    font-size: 18px;
    margin-right: 5px;
  }
  .quiz-extra-info-008 .review-section .review-user small {
    font-size: 16px;
  }
  .details-play-quiz {
    padding: 15px !important;
    min-width: 160px !important;
  }
}
/*Lokesh styles ends*/
.de-rat img {
  height: 20px;
}
.profile-creators {
  position: relative;
}
.detail-profile-arro img {
  transform: rotate(90deg);
}
.detail-profile-arro {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: white;
  bottom: -23px;
  position: absolute;
  left: 50%;
  right: 50%;
  box-shadow: 0px 0px 12px -3px #aaa;
  border: 2px solid #083568;
}
.credit-member .detail-name {
  padding: 15px 80px 15px 15px;
  position: relative;
}
.credit-member .detail-name .icon {
  position: absolute;
  color: #009400;
  font-size: 36px;
  right: 0px;
  top: 0px;
  border-radius: 15px;
  background: #ffffff;
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
}
/* .credit-member {
    background: linear-gradient(to left,#16457B,#3578C4);
} */
.credit-member {
  background: #294177;
  /* text-shadow: 5px 3px 5px black; */
}
.member-ship img {
  height: 70px;
  max-width: initial;
}
.firstfull-credit {
  box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 15px;
}
.bollywood-content {
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bollywood-content {
  border-radius: 10px;
  box-shadow: 0px 0px 12px -3px #aaa;
}

.bollywood-content p {
  color: black;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  margin-top: 15px;
}
.cate-tex {
  text-align: center;
  margin-top: 15px;
  font-size: 13px;
  font-weight: bold;
}
.detail-genres h5 {
  margin-top: 15px;
  margin-bottom: 30px;
  font-weight: 600;
}
.attribute {
  box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 30px;
  padding: 10px 30px;
  margin-right: 10px;
  font-weight: 600;
}
.keywords {
  font-size: 14px;
}
.keywords {
  border: 1px solid #083568;
  border-radius: 30px;
  padding: 8px 30px;
  margin-right: 10px;
  font-weight: bold;
}
.detail-leader-btn {
  background: white;
  color: #16457b;
  border: none;
  outline: none;
  padding: 14px 30px;
  margin: 20px auto;
  border-radius: 34px;
  border: 2px solid #16457b;
  min-width: 190px;
  text-align: center;
}
.details-play-quiz {
  /* background: linear-gradient(to bottom,#16457B,#3578C4); */
  background: #294177;
  text-shadow: 5px 3px 5px black;
  color: white;
  border: none;
  outline: none;
  padding: 15px 20px;
  border-radius: 34px;
  min-width: 190px;
  text-align: center;
}
.details_icon {
  font-size: 18px;
  background: #fff;
  position: absolute;
  top: 10px;
  right: 26px;
  z-index: 99;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  padding-top: 2px;
  padding-left: 1px;
  color: #16457b;
}
/*Quiz details*/
/*Quiz listing*/
@media (max-width: 767px) {
  .filter_layout {
    margin: 15px;
  }
  .performance1 h3 {
    margin-bottom: 20px;
  }
}
.filter_layout {
  padding: 10px 15px;
  box-shadow: 1px 0px 7px #aaa;
  border-radius: 15px;
}
.filter_layout h3 {
  font-size: 18px;
  font-weight: bold;
  color: #294177;
  border-bottom: 1px solid #ccc;
  padding-bottom: 7px;
}

.filter_layout .card-header {
  background: #fff;
  border-radius: 0;
  padding: 10px 0px;
  border: none;
  border-bottom: 1px solid #ccc !important;
}
.filter_layout .card {
  border-radius: 0;
  border: none;
}
.filter_layout .card-header h5 {
  font-size: 15px;
  color: #555;
}
.filter_layout .card-header h5 i {
  float: right;
  margin-top: 5px;
  font-weight: 100;
  font-size: 12px;
  color: #b8b8b8;
}
.filter_layout .card .card-body {
  padding: 5px 0px;
}
.filter_layout .card .card-body ul {
  list-style: none;
  padding: 5px 0px;
  color: #888;
}
.cust_search {
  position: relative;
  width: 100%;
  display: block;
}
.scroll-tab label {
  font-size: 15px;
}
.scroll-tab {
  height: 300px;
  padding: 5px 10px;
  overflow: auto;
}
.cust_search i {
  position: absolute;
  top: 3px;
  left: 5px;
  color: #aaa;
}
.cust_search input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  padding-left: 25px;
}
.filter_layout .card {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #ccc !important;
}
.scroll-tab::-webkit-scrollbar {
  width: 10px;
  border-top-left-radius: 3px;
}
.search_tab {
  box-shadow: 2px 2px 5px #ccc;
  min-height: 70px;
  padding: 15px;
}
@media (max-width: 767px) {
  .search_tab {
    margin-top: 15px;
  }
}
.search_content {
  /* float: right; */
  position: relative;
  /* margin-top: 20px; */
  display: block;
}
.search_content input {
  border: 1px solid #ccc;
  padding: 10px 10px 10px 30px;
  width: 100%;
}
.search_content i {
  position: absolute;
  left: 8px;
  top: 14px;
  color: #999;
}
/*Menus*/
.nav-link img {
  width: 25px;
  margin-right: 5px;
}
.nav-link {
  position: relative;
  display: inline-block;
}

.nav-link .tooltiptext {
  visibility: hidden;
  width: 74px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 10px;
  bottom: 7px;
  left: -7px;
}
.searchcontent {
  padding-left: 0 !important;
}
.nav-link:hover .tooltiptext {
  visibility: visible;
}
/*Menus end*/
/* Track */
.scroll-tab::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll-tab::-webkit-scrollbar-thumb {
  background: #888;
}
/*Quiz listing*/
/* .categoryImg{
    padding: 0 3px;
} */
@media screen and (min-width: 768px) and (max-width: 1160px) {
  .bolly-new-song {
    font-size: 14.5px;
  }
}
@media (min-width: 991px) and (max-width: 1200px) {
  .listingArea {
    padding: 0;
  }
}

/*  Flexible Modal Dialog start */
.flexible-modal-dialog {
  width: 90%;
  max-width: 90%;
  margin: 1.75rem auto;
}

.flexible-modal-dialog .modal-content {
  padding: 20px;
}

.flexible-modal-dialog .modal-header,
.flexible-modal-dialog .modal-body {
  padding: 15px 20px;
}

@media (max-width: 991px) {
  .flexible-modal-dialog {
    width: 95%;
    max-width: 95%;
    margin: 1rem auto;
  }

  .flexible-modal-dialog .modal-content {
    padding: 10px;
  }

  .flexible-modal-dialog .modal-header,
  .flexible-modal-dialog .modal-body {
    padding: 10px;
  }
}
/*  Flexible Modal Dialog end */

/* Image type options start here*/
.temp_answer_image {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.answer-item {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .answer-item {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

.answer-image {
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
}
/* Image type options end here*/
