.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueGradient90D {
  background: #16457b;
  background: linear-gradient(90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueGradient90DR {
  background: #16457b;
  background: linear-gradient(-90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.BoxShadowHover {
  box-shadow: 0px 0px 12px -3px #294177;
  -webkit-box-shadow: 0px 0px 12px -3px #294177;
  -moz-box-shadow: 0px 0px 12px -3px #294177;
}
.BorderRadius4 {
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
}
.BorderRadius10 {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.BorderRadius100 {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.Border {
  border: 1px #dddddd solid;
}
.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WhiteBg {
  background: #FFFFFF;
}
.Transition {
  transition: .5s;
}
/* === Begin Emails === */
.emails-list-box .card {
  border: 1px #dddddd solid !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  margin-bottom: 5px;
}
.emails-list-box .card .card-header {
  background: none;
  padding: 0px;
}
.emails-list-box {
  margin-bottom: 50px;
}
.emails-list-box .card .card-header a {
  display: block;
  padding: .75rem 1.25rem;
  font-size: 20px;
  color: #294177;
}
.emails-list-box .card .card-header a:hover {
  background: #fafafa;
  color: #268cff;
}
.emails-list-box .card .card-header a:after {
  position: absolute;
  content: "\e90b";
  font-family: 'icomoon';
  right: 15px;
  top: 2px;
  font-size: 14px;
  line-height: 44px;
  color: #888888;
}
.emails-list-box .card .card-header a.collapsed:after {
  content: "\e908";
}
.emails-list-box .card .info {
  color: #888888;
}
.emails-list-box .card .action-btn {
  margin-top: 15px;
}
.emails-list-box .card .action-btn a {
  min-width: 120px;
  text-align: center;
}
