.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}

.BlueGradient90D {
  background: #16457b;
  background: linear-gradient(90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}

.BlueGradient90DR {
  background: #16457b;
  background: linear-gradient(-90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}

.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}

.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}

.BoxShadowHover {
  box-shadow: 0px 0px 12px -3px #104178;
  -webkit-box-shadow: 0px 0px 12px -3px #104178;
  -moz-box-shadow: 0px 0px 12px -3px #104178;
}

.BorderRadius4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.BorderRadius10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}

.BorderRadius100 {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}

.Border {
  border: 1px #dddddd solid;
}

.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.WhiteBg {
  background: #FFFFFF;
}

.Transition {
  transition: .5s;
}

/* ===== My Credits ===== */

.suggestion-txt p strong {
  color: #294177;
}

.my-credits-wrapper h3 {
  text-align: center;
  font-weight: 900;
  color: #294177;
  margin-bottom: 20px;
}

@media(max-width:767px) {
  .my-credits-wrapper h3 {
    margin-bottom: 20px;
  }
  .my-credits-wrapper .credits-history-info .credits-history-list .time-and-points .points-txt {
    margin-left: 10px;
  }
}

.my-credits-wrapper .credits-available-box {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 20px 20px 50px;
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}

.my-credits-wrapper .credits-available-box .credit-icon {
  display: inline-block;
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  height: 80px;
  line-height: 80px;
  width: 80px;
  text-align: center;
  vertical-align: top;
}

.my-credits-wrapper .credits-available-box .credit-icon .icon-dollar {
  color: #294177;
  font-size: 50px;
  vertical-align: middle;
}

.credits-history-list .icon-dollar {
  color: #294177;
  font-size: 30px;
}

.my-credits-wrapper .credits-available-box .credit-txt {
  font-size: 20px;
  padding-left: 20px;
  padding-top: 5px;
}

.my-credits-wrapper .credits-available-box .credit-txt .points {
  color: #104178;
  font-weight: 900;
  font-size: 26px;
}

.my-credits-wrapper .credits-available-box .more-credits-btn {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -20px;
  text-align: center;
  min-height: 40px;
}

.my-credits-wrapper .credits-available-box .more-credits-btn .btn-background {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  padding: 10px 30px;
}

.my-credits-wrapper .credits-history-info {
  padding-top: 20px;
  padding-bottom: 50px;
}

.blueBgBtn {
  background-color: #294177;
  color: #ffffff;
  border: 2px solid #294177;
  transition: .5s;
  -webkit-border-radius: 30px;
  -moz-border-radius: 25px;
  border-radius: 30px;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  padding: 10px 30px;
  box-shadow: 0px 0px 12px -3px #aaa;
}

.saleCreditTable tr th, .saleCreditTable tr td {
  color: #294177;
  padding: .4rem;
}

.saleCreditTable tr td {
  border-bottom: 1px solid !important;
  border-top: 0 !important;
}

.transactionBalanceNum {
  font-weight: 600 !important;
}

.saleCreditTable tbody, .saleCreditTable td, .saleCreditTable th, .saleCreditTable thead, .saleCreditTable tr {
  border-color: #294177 !important;
}

.saleCreditTable thead {
  border-top: 2px solid #294177 !important;
}

.purchaseBox {
  display: flex;
}

.contentBox {
  padding-right: .5rem !important;
}

.blueBgBtn:hover {
  background-color: #ffffff;
  color: #294177 !important;
}

@media (max-width: 768px) {
  .my-credits-wrapper .credits-history-info {
    padding-bottom: 30px;
  }
  .saleCreditTable thead {
    display: none;
  }
  .saleCreditTable, .saleCreditTable tbody, .saleCreditTable tr, .saleCreditTable td {
    display: block;
    width: 100%;
  }
  .saleCreditTable tr {
    margin-bottom: 15px;
    border: 1px solid #294177;
    border-radius: 6px;
  }
  .saleCreditTable tbody tr td {
    text-align: left !important;
    padding-left: 50%;
    position: relative;
    border-bottom: none !important;
    overflow-wrap: break-word;
    font-size: 14px;
  }
  .saleCreditTable td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }
  .purchaseBox {
    flex-direction: column;
  }
  .contentBox {
    padding-right: 0 !important;
  }
}

.my-credits-wrapper .credits-history-info .credits-history-list {
  padding: 15px 0px 15px 55px;
  position: relative;
  border-bottom: 1px #dddddd solid;
}

.my-credits-wrapper .credits-history-info .credits-history-list .head {
  color: #212529;
  font-weight: 900;
  font-size: 24px;
}

.my-credits-wrapper .credits-history-info .credits-history-list .categories {
  color: #104178;
  font-size: 18px;
  font-weight: bold;
}

.my-credits-wrapper .credits-history-info .credits-history-list .balance-points {
  font-size: 18px;
  font-weight: bold;
}

.my-credits-wrapper .credits-history-info .credits-history-list .balance-points .number {
  color: #104178;
}

.my-credits-wrapper .credits-history-info .credits-history-list .pm-icon {
  position: absolute;
  left: 0px;
  top: 20px;
  width: 40px;
  height: 40px;
}

.my-credits-wrapper .credits-history-info .credits-history-list .time-and-points {
  text-align: right;
}

@media (max-width: 568px) {
  .my-credits-wrapper .credits-history-info .credits-history-list .time-and-points {
    text-align: left;
    margin-top: 10px;
  }
}

.my-credits-wrapper .credits-history-info .credits-history-list .time-and-points .date-time {
  margin-bottom: 10px;
}

.my-credits-wrapper .credits-history-info .credits-history-list .time-and-points .ch-icon {
  display: inline-block;
  margin: 10px 0px 0px 0px;
  vertical-align: top;
}

.my-credits-wrapper .credits-history-info .credits-history-list .time-and-points .points-txt {
  display: inline-block;
  color: #104178;
  font-size: 20px;
}

.my-credits-wrapper .credits-history-info .credits-history-list .time-and-points .points-txt .ch-points {
  font-size: 40px;
  line-height: 40px;
  font-weight: 900;
}

.purchase-credits-info .credits-available-box .credit-txt {
  margin-top: 15px;
}

.purchase-credits-info .center-box {
  margin: 50px 0;
}

@media(max-width:767px) {
  .purchase-credits-info .center-box {
    margin: 0;
  }
}

.purchase-credits-info .signup-points {
  background: #16457b;
  background: linear-gradient(-90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
  padding: 20px 20px 20px 75px;
  position: relative;
  min-height: 40px;
  display: block;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.purchase-credits-info .signup-points .icon {
  position: absolute;
  top: 15px;
  left: 20px;
  width: 40px;
  height: 40px;
  font-size: 35px;
  display: inline-block;
}

.purchase-credits-info .signup-points:hover {
  color: #FFFFFF;
  background: #16457b;
  background: linear-gradient(90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}

/*verify email*/

.verify-email {
  margin: 100px 0;
}

.verify-email p {
  color: #294177;
  font-weight: bold;
}

.verify-icons {
  display: flex;
  justify-content: space-around;
  color: #294177;
  align-items: center;
  margin-top: 50px;
}

.verify-icons .icon-mail1 {
  font-size: 8rem;
}

.verify-icons .icon-arrow {
  font-size: 5rem;
}

.verify-icons .icon-inbox {
  font-size: 10rem;
}

.verify-icons .icon-hand {
  font-size: 7rem;
}

@media(max-width:767px) {
  .verify-icons .icon-mail1, .verify-icons .icon-hand {
    font-size: 4rem;
  }
  .verify-icons .icon-arrow {
    font-size: 1.5rem;
  }
  .verify-icons .icon-inbox {
    font-size: 5rem;
  }
}

@media(min-width:768px) and (max-width:991px) {
  .verify-icons .icon-mail1, .verify-icons .icon-hand {
    font-size: 6rem;
  }
  .verify-icons .icon-arrow {
    font-size: 3rem;
  }
  .verify-icons .icon-inbox {
    font-size: 7rem;
  }
}