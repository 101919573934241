.campaignList .custList {
  background-color: #FFFFFF;
  padding: 15px 25px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px #aaaaaa;
  margin-bottom: 50px;
}
.campaignList .custList .row {
  margin-bottom: 30px;
}
.campaignList .custList img {
  width: 100%;
}
.campaignList .custList h3 {
  text-align: center;
  font-weight: 600;
}
.campaignList .custList p {
  position: relative;
}
.campaignList .custList p .socialIcons {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 448px) {
  .campaignList .custList p .socialIcons {
    position: static;
  }
}
.campaignList .custList p .socialIcons .icon {
  font-size: 28px;
  margin-right: 10px;
}
.campaignList .custList p .socialIcons .icon-facebook {
  color: #3b5a9a;
}
.campaignList .custList p .socialIcons .icon-twitter {
  color: #03a9f5;
}
.campaignList .custList .description {
  margin-top: 20px;
}
.campaignList .custList .description p {
  color: #000000;
  margin-bottom: 0;
}
@media (max-width: 992px) {
  .campaignList .custList .description h5 {
    font-size: 15px;
  }
}
.newsList .custList {
  background-color: #FFFFFF;
  padding: 15px 25px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px #aaaaaa;
  margin-bottom: 10px;
}
.newsList .custList .imgOverlay {
  position: relative;
  margin-bottom: 20px;
}
.newsList .custList .imgOverlay .caption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 25px;
  color: #FFFFFF;
}
@media (max-width: 768px) {
  .newsList .custList .imgOverlay .caption h4 {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .newsList .custList .custWid1 {
    -ms-flex: 0 0 62.333333%;
    flex: 0 0 62.333333%;
    max-width: 62.333333%;
  }
}
@media (min-width: 768px) {
  .newsList .custList .custWid2 {
    -ms-flex: 0 0 37.333333%;
    flex: 0 0 37.333333%;
    max-width: 37.333333%;
  }
}
.newsList .custList .gridList {
  margin-bottom: 30px;
}
.newsList .custList .gridList img {
  width: 90%;
}
@media (max-width: 768px) {
  .newsList .custList .gridList img {
    width: 100%;
  }
}
.newsList .custList .gridList .description h4 {
  font-weight: 600;
  font-size: 22px;
}
@media (max-width: 992px) {
  .newsList .custList .gridList .description h4 {
    font-size: 18px;
  }
}
.newsList .custList .gridList .description h5 {
  font-size: 16px;
}
@media (max-width: 768px) {
  .newsList .custList .gridList .description {
    margin-top: 30px;
  }
}
.newsList .custList #owl-carousel4 span.icon {
  width: 40px;
  height: 40px;
  border: 2px solid #104178;
  background: #FFFFFF;
  display: block;
  padding-top: 5px;
  border-radius: 50%;
  color: #104178;
  margin-right: 10px;
  font-size: 26px;
  transition-duration: 0.3s;
}
@media (max-width: 992px) {
  .newsList .custList #owl-carousel4 span.icon {
    width: 35px;
    height: 35px;
    padding-top: 5px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 20px;
    transition-duration: 0.3s;
  }
}
.newsList .custList #owl-carousel4 span.icon:hover {
  background: #104178;
  color: #FFFFFF;
}
.newsList .custList #owl-carousel4 .item h3 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 20px;
  color: #000000;
  font-weight: 600;
}
.newsList .custList #owl-carousel4 .item .dateBy {
  font-size: 15px;
  color: #aaaaaa;
  margin: 0;
}
.newsList .custList #owl-carousel4 .item .cusText {
  font-size: 16px;
  color: #000000;
}
.newsList .custList #owl-carousel4 .item .rdMr {
  color: #104178;
  font-weight: 600;
  transition-duration: 0.3s;
}
.newsList .custList #owl-carousel4 .item .rdMr:hover {
  color: #000000;
}
.newsList .custList #owl-carousel4 .owl-nav {
  float: right;
}
.newsList .custList #owl-carousel4 .owl-nav .owl-prev:focus {
  outline: none;
}
.newsList .custList #owl-carousel4 .owl-nav .owl-next:focus {
  outline: none;
}
.newsList .custList .gridRow {
  margin-top: 30px;
}
.newsList .custList .gridRow h3 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 20px;
  color: #000000;
  font-weight: 600;
}
@media (max-width: 992px) {
  .newsList .custList .gridRow h3 {
    font-size: 16px;
  }
}
.newsList .custList .gridRow .dateBy {
  font-size: 15px;
  color: #aaaaaa;
  margin: 0;
}
@media (max-width: 992px) {
  .newsList .custList .gridRow .dateBy {
    font-size: 11px;
  }
}
.newsList .custList .gridRow .cusText {
  font-size: 16px;
  color: #000000;
}
@media (max-width: 992px) {
  .newsList .custList .gridRow .cusText {
    font-size: 13px;
  }
}
.newsList .custList .gridRow .rdMr {
  color: #104178;
  font-weight: 600;
  transition-duration: 0.3s;
}
@media (max-width: 992px) {
  .newsList .custList .gridRow .rdMr {
    font-size: 14px;
  }
}
.newsList .custList .gridRow .rdMr:hover {
  color: #000000;
}
