.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueGradient90D {
  background: #16457b;
  background: linear-gradient(90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueGradient90DR {
  background: #16457b;
  background: linear-gradient(-90deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.BoxShadowHover {
  box-shadow: 0px 0px 12px -3px #294177;
  -webkit-box-shadow: 0px 0px 12px -3px #294177;
  -moz-box-shadow: 0px 0px 12px -3px #294177;
}
.BorderRadius4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.BorderRadius10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.BorderRadius100 {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.Border {
  border: 1px #dddddd solid;
}
.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WhiteBg {
  background: #FFFFFF;
}
.Transition {
  transition: .5s;
}
/* ===== Attributes ===== */
.attributes-wrapper .attributes-box {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 20px 30px;
  margin-bottom: 50px;
}
.attributes-wrapper .attributes-box h4 {
  margin-bottom: 15px;
}
.attributes-wrapper .attributes-box .inner-space {
  margin: 10px 0px 30px;
}
.attributes-wrapper .attributes-box .inner-space input {
  border: none;
  border-bottom: 1px #dddddd solid;
  width: 100%;
  padding: 10px;
}
.attributes-wrapper .attributes-box .inner-space input:focus {
  border-bottom: 1px #294177 solid;
  outline: none;
}
.attributes-wrapper .inner-boxs {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  text-align: center;
  padding: 15px 10px;
  border: 2px #FFFFFF solid;
  display: inline-block;
  min-height: 100%;
  color: #294177;
  font-weight: 900;
  min-width: 100px;
}
@media (min-width:576px){
  .inner-space .restrictIcon{
    right: 6px;
    bottom: -5px !important;
  }
}
@media (max-width:576px){
  .inner-space .restrictIcon{
    right: 7px;
    bottom: 9px;

  }
}
@media (max-width: 568px) {
  .attributes-wrapper .inner-boxs {
    display: block;
    min-height: initial;
    margin-bottom: 15px;
  }
}
.attributes-wrapper .inner-boxs .icon {
  display: block;
  font-size: 50px;
  margin-bottom: 10px;
}
.attributes-wrapper .inner-boxs:hover {
  border: 2px #294177 solid;
}
.attributes-wrapper .inner-boxs.active {
  border: 2px #294177 solid;
}
.attributes-wrapper .inner-boxs img {
  max-width: 100%;
}
.attributes-wrapper .b-buttons a {
  margin-bottom: 15px;
}
.attributes-wrapper .b-buttons a.active {
  background: #294177;
  color: #FFFFFF;
}
.contentIcon{
  width: 60px;
  height: 60px;
  background-color: #294177;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 10px;
}
.contentIcon .icon{
  font-size: 37px !important;
  color: #FFFFFF;
  line-height: 60px !important;
}
.contentIconWrapper .inner-boxs{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}