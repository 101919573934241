* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/* .nowrapText{
    white-space: nowrap;
} */
a{
    text-decoration: none !important;
}

.groupAdminHeading {
    color: #294177;
    font-weight: 600;
    width: 82%;
    margin-left: auto;
}

.adminPanel {
    margin: 50px 0;
    font-size: 14px;
}
.adminPanel input{
    color: #294177;
}
.adminPanel input:focus{
    outline: none !important;
}

.adminPanelInner {
    /* box-shadow: 7px 0 5px -2px #aaa; */
    box-shadow: 5px 5px 5px 0px #aaa;
    padding: 30px;
    border: 1px solid #294177;
    border-radius: 25px;
}
.adminPanelInner input{
    font-weight: 600;
}
.groupadminArea {
    display: flex;
    justify-content: space-between;
}

.blueThemeText {
    color: #294177;
}
.adminCreateBox{
    width: 89%;
    margin-right: 10px;
}
.adminPanel ::-webkit-input-placeholder{
    color: #294177 !important;
    font-weight: 600 !important;
}
.adminCreateBox .bb-1{
    width: 100%;
    border: 0;
    border-bottom: 1px solid #294177;
    padding: 5px 0;
}
.cameraIcon{
    position: absolute;
    top: 6px;
    right: -10px;
}
.cameraIcon .icon{
    color: #c0c0c0;
    font-size: 23px;
}
.w-90 {
    width: 90%;
}
.w-100 {
    width: 100%;
}
.groupAdminBox{
    align-items: center;
    margin-bottom: 0.5rem;
}
.groupAdminBox label{
    white-space: nowrap;
    font-weight: 600;
}
.groupAdminBox input{
    border: 1px solid #294177;
    padding: 4px 6px;
}
.groupAdminBox .addCloseIcon{
    width: 24px;
    height: 24px;
    background-color: #294177;
    border: 2px solid #294177;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all ease-in-out;
    position: absolute;
    right: 4px;
    top: 4px;
}
.icon-close{
    color: #294177;
    font-size: 18px;
}
.adminPanel .icon-plus,.adminPanel .icon-m-create,.adminPanel .icon-close1{
    color: #fff;
}
.icon-close1{
    font-size: 10px !important;
}
.icon-plus:hover{
    background-color: transparent;
color: #294177 !important;
}
.groupadminArea .closedLock{
    background: #294177;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 3px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hiddenLabel{
    visibility: hidden;
}
.groupLock{
    display: inline-block;
    border: 1px solid #294177;
    padding: 2px;
}

.logoUpload{
    border: 1px solid #294177;
    width: 50px;
    height: 50px;
    text-align: center;
}
.groupLogoImg{
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100% !important;
    /* padding: 2px; */

}
.logoText{
    position: absolute;
    bottom: 0;
    left: 50%;
    font-weight: 500;
    transform: translateX(-50%);
}

.custom-file-input{
    cursor: pointer;
}
.custom-file-input::before{
    content: 'Logo';
}

.custom-file-input::-webkit-file-upload-button{
    visibility: hidden;
}

.actionIcon{
    width: 24px;
    height: 24px;
    background-color: #294177;
    border: 2px solid #294177;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all ease-in-out;
}
.actionIcon:hover,.addCloseIcon:hover{
    background-color: transparent;
}
.actionIcon:hover .icon,.addCloseIcon:hover .icon{
    color: #294177 !important;
}
.icon{
    font-size: 12px;
}
/* .inviteUserAction{
    position: absolute;
    top: 0;
    left: -14px;
} */
.noAccess{
    border: 2px solid #ff0000 !important;
    background-color:#ff0000 !important;
}
.acceptRequest{
    border: 2px solid #618b39 !important;
    background-color: #618b39 !important;
}
.acceptRequest:hover{
    background-color: transparent !important;
}
.acceptRequest:hover .icon{
    color: #618b39 !important;
}
.noAccess:hover{
    background-color: transparent !important;
}
.noAccess:hover .icon{
    color: #ff0000 !important;
}
.border-box{
    border: 1px solid #294177 !important;
}
.photo{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    width: 40px;
    height: 40px;
    box-shadow: 1px 0px 3px #555;
    border-radius: 50%;
}
.photo img{
    width: 31px;
    object-fit: cover;
}
.richTextEditor{
    border: 1px solid #294177;
    /* height: 390px; */
    margin-top: 15px;
}
.groupAdminRichText .jodit-container:not(.jodit_inline) .jodit-workplace{
    min-height: 181px !important;
}
.memberDetail{
    display: flex;
}
.memberDetail .info{
    color: #294177;
    font-size: 12px;
}
.memberPanel,.newsPanel{
    min-height: 100% !important;
    border: 1px solid #294177;
    margin-bottom: 0.5rem !important;
    position: relative;
}
/* .memberList,.newsList{
    height: 280px;
    overflow-y: auto;
} */

.memberNewsPanel .header,.groupContentPanel .header,.requestJoinPanel .header{
    padding: 8px 6px;
}
.memberBox,.contentBox,.newsBox{
    background-color: #fbfbfb;
    padding: 7px 8px;
    border-top: 1px solid rgb(0 0 0 / 7%);
    margin: 0 6px;
}
.memberBox:first-child,.contentBox:first-child,.newsBox{
    border-top: 0 !important;
}
.memberNewsPaging{
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}
.requestList{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.requestList .memberBox{
    width: 47%;
    border-top: 0 !important;
}

.inputBox{
    padding: 3px 0 3px 8px !important;
}
.cursor-pointer{
    cursor: pointer !important;
}
.paginationArea{
    text-align: center;
    padding: 7px 8px;
}
.newsBox{
    padding-right: 34px;
}
.contentBox{
    border-top: 1px solid #dddede;
    padding-right: 28px !important;
}
.panelHeading{
    font-weight: 600;
}
.memberBox .info,.contentBox .info{
    margin-left: 0.7rem !important;
}
.memberBox .info,.newsBox .info,.contentBox .info{
    font-weight: 600;
}
.contentBox .closeIcon,.newsList .closeIcon{
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 5px;
}
.mailId{
    word-break: break-word;
}
.middleActionIcon{
    margin: 0 0.5rem;
}
@media only screen and (max-width:992px){
    /* .memberNewsPanel{
        flex-direction: column;
    } */
    .groupAdminHeading{
        width: 90%;
    }
    .requestList .memberBox{
        width: 100%;
    }
}
@media only screen and (max-width:768px){
    .groupAdminHeading{
        width: 100%;
    }
    .middleActionIcon{
        margin: 0 0.25rem;
    }
}
@media only screen and (max-width:567px){
    .groupAdminBox{
        flex-direction: column;
        align-items: flex-start !important;
    }
    .hiddenLabel{
        display: none !important;
    }
    .adminPanelInner
    {
        padding: 15px !important;
    }
}
@media only screen and (max-width:450px){
    .logoUploadArea{
        justify-content: end;
    }
    .groupAdminHeading{
        font-size: 22px;
    }
    .searchBox{
        width: 165px !important;
    }
}
@media only screen and (max-width:420px){
    .inviteUserHeading{
        flex-direction: column;
        padding-right: initial !important;
    }
    .inviteUserBox{
        display: flex;
        justify-content: flex-end;
        position: initial !important;
        margin-bottom: 1rem !important;
    }
}

.actionBtns{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
}
.memberBox{
    padding-right: 97px;
}
.memberNewsPanel{
    padding-bottom: 80px;
}
.header{
    /* padding-right: 220px !important; */
    padding-right: 188px !important;
}
.searchBox{
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    width: 175px;
}

.v-hidden{
    visibility: hidden !important;
}

/* create group css */
.createGroup .logoUploadArea{
    margin-top: -17px;
}
.groupStatusArea{
    width: 89%;
    margin-right: 10px;
}
.adminPanel .icon-lock,.groupStatusArea .icon-unlock,.groupLock .icon-unlock{
    background: #294177;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 3px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkContainer {
    font-weight: 600;
    display: block;
    position: relative;
    padding-left: 31px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0 !important;
  }
  
  /* Hide the browser's default checkbox */
  .checkContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 25px;
    width: 25px;
   border:1px solid #294177;
  }
  
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkContainer input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkContainer .checkmark:after {
    left: 8px;
    top: 2px;
    width: 7px;
    height: 15px;
    border: solid #294177;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .createGroupBtn{
      display: flex;
      justify-content: flex-end;
  }

  .createGroupBtn button,.createGroupBtn a{
    background: #294177;
    color: white;
    border: none;
    outline: none;
    padding: 8px 6px;
    border-radius: 34px;
    min-width: 147px;
    text-align: center;
    border: 2px solid #294177;
    transition: 0.3s all ease-in-out;
  }
  .createGroupBtn a{
      padding: 4px 6px !important;
      min-width: 90px !important;
  }
  .createGroupBtn .icon{
      color: #ffffff;
      font-size: 22px;
  }
  .createGroupBtn button:hover,.createGroupBtn a:hover{
      background: transparent;
      color: #294177;
  }
  .createGroupBtn a:hover .icon-next{
      color: #294177 !important;
  }
  .createGroup .adminPanelInner{
    padding: 30px 30px 15px 30px;
  }
  @media only screen and (max-width:576px){
    .groupStatusArea{
        width: 100% !important;
    }
  }
@media only screen and (max-width:450px){
    .createGroup .logoUploadArea{
        margin-top: -10px;
    }
}

.groupPanel{
    margin: 50px 0;
    font-size: 14px;
}
.groupListing{
    box-shadow: 6px 6px 5px 0px #aaa;
    padding: 30px;
    border: 1px solid #294177;
    border-radius: 25px;
}

.createGroupBtn button{
    background: #294177;
    color: white;
    border: none;
    outline: none;
    padding: 8px 6px;
    border-radius: 34px;
    min-width: 147px;
    font-size: 16px !important;
    text-align: center;
    border: 2px solid #294177;
    transition: 0.3s all ease-in-out;
  }
  .createGroupBtn button:hover{
      background: transparent;
      color: #294177;
  }
  .groupAppetizerBox{
      background-color: #294177;
      color: #fff;
      padding: 45px 15px;
      border-radius: 25px;
    -webkit-border-radius: 25px;
    box-shadow: 6px 7px 5px 0px #0000009e;
    padding-right: 120px;
    margin-top: 2rem !important;
  }
  
  .groupStatus{
      position: absolute;
      top: 15px;
      right: 9px;
      top: 50%;
      transform: translateY(-50%);
  }
  .groupStatusIcon{
    width: 45px;
    height: 45px;
    border: 6px solid #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .groupStatusIcon .icon{
      font-size: 18px;
  }
  .groupLogo{
    width: 70px;
    height: 70px;
    background: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:hidden;
  }

  .statusLogo{
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .statusLogo img{
    margin: 0 auto;
     height: 100% !important;
    /* padding: 2px; */
    /* max-width: 65px; */
    border-radius: 20px;
    object-fit: cover;
  }
  .settingIcon{
    width: 40px;
    height: 40px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: -8px;
  }
  .settingIcon .icon{
    color: #bf0000;
    font-size: 30px;
  }

  @media only screen and (max-width:567px){
    .groupListing
    {
        padding: 15px !important;
    }
  }
@media only screen and (max-width:450px){
    .groupAdminHeaderArea{
        flex-direction: column !important;
    }
    .groupListingHeader{
        margin-bottom: 1rem !important;
    }
}
.pl-75px{
    padding-left: 75px;
}
/* common code */
.blueThemeText {
    color: #294177;
}


.inviteUserPanel {
    font-weight: 500;
}
.groupDetailContent{
    margin: 40px 0;

}
.shadowPanelInner {
    box-shadow: 6px 6px 5px 0px #aaa;
    padding: 15px;
    border: 1px solid #294177;
    border-radius: 25px;
}
.groupDetailBox{
    padding: 20px;
}
.blueThemeText {
    color: #294177;
}

.inviteUser {
    background: #294177;
    border: 2px solid #294177;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inviteUser .icon {
    font-size: 26px;
    color: #fff;
}

.inviteUserLogo,.groupDetailLogo {
    width: 65px;
    height: 65px;
    position: absolute;
    left: 0;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
.groupDetailLogo{
    width: 70px;
    height: 70px;
    background: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.groupDetailBox{
    background-color: #294177;
    color: #ffffff !important;
}
.pl-70px{
    padding-left: 70px;
}
.pl-80px{
    padding-left: 80px;
}
.pl-90px{
    padding-left: 90px;
}
.inviteUserLogo img,.groupDetailLogo img {
    margin: 0 auto;
    height: 100% !important;
    /* padding: 2px; */
    border-radius: 20px;
    object-fit: cover;
}
.inviteUserHeading{
    padding-right: 60px;
    font-weight: 600;
}
.inviteUserBox{
    position: absolute;
    top: 0;
    right: 0;
}
.gpDetailIcon{
    width: 60px;
    height: 60px;
    border: 6px solid #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gpDetailIcon .icon{
    font-size: 30px;
    color: #ffffff;
}
.fw-600{
    font-weight: 600;
}
::-webkit-input-placeholder{
    color: #294177 !important;
    font-weight: 500;
}
.w-100{
    width: 100%;
}
.organisationField input{
    border: 0 !important;
    color:#294177;
}
input:focus{
    outline: none;
}
.inviteUserText input{
    border: 1px solid #294177;
    padding: 8px 15px;
    color:#294177;
}
.inviteUserText .addCloseIcon{
    width: 30px;
    height: 30px;
    background-color: #294177;
    border: 2px solid #294177;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all ease-in-out;
    position: absolute;
    right: 15px;
    top: 6px;
}
.inviteUserText .addCloseIcon .icon{
    color: #ffffff;
}
a{
    text-decoration: none !important;
}
.hoverIcon{
    transition: 0.3s all ease-in-out;
}
.hoverIcon:hover{
    background-color: transparent;
}
.hoverIcon:hover .icon{
    color: #294177 !important;
}
#deleteMemberModal .btn-default,#deleteContentModal .btn-default{
    min-width: 80px !important;
}
.modal-header .icon-close1{
    color: #000000 !important;
}
@media only screen and (max-width:567px){
    .groupDetailBox{
        padding: 15px;
    }
}
@media only screen and (max-width:450px){
    .groupDetailHeader{
        flex-direction: column;
    }
}
.newsModalBody{
    padding-bottom: 55px;
}
.addNewsBtn{
    position:absolute;
    bottom: -22px;
    right: -10px;
}
.dynamicText p{
    display: inline !important;
}
.h-200px{
    height: 200px;
}


.newsAppetizerBox{
    color: #294177;
    padding: 15px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    /* box-shadow: 6px 7px 5px 0px #0000009e; */
    box-shadow: #0000009e 0px 2px 8px 0px;
    margin-top: 2rem !important;
}
.memorizeBrandIcon{
    position: absolute;
    right: 0;
    top:0;
}
.newsDetails{
    position: relative;
    padding-right: 90px;
}
.newsInfo{
    font-weight: 500;
}
.newsContent{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 42px;
    color: #294177 !important;
}
/* .groupNewsPage .advertisement-slider{
    margin-bottom: 0 !important;
    margin-top: 37px !important;
} */
.createNewsPanel .groupadminArea{
    flex: 1 !important;
}
.createNewsPanel .adminCreateBox{
    margin-right: 0 !important;
    margin-left: 24px !important;
    width:initial !important;
    flex: 1 !important;
}
.createNewsPanel .logoUploadArea{
    margin-top: 0 !important;
}
.createNewsPanel .logoUpload{
    width: 57px !important;
    height: 57px !important;
}
.createNewsPanel .createGroupBtn{
    justify-content: center !important;
}
.createNewsPanel .createGroupBtn a{
    min-width: 104px !important;
}
.createNews .groupAdminHeading{
    width: 78% !important;
}
@media only screen and (max-width:992px){
    .createNews .groupAdminHeading{
        width: 82% !important;
    }
}
@media only screen and (max-width:768px){
    .createNews .groupAdminHeading{
        width: 90% !important;
    }
}
@media only screen and (max-width:576px){
    .createNews .groupAdminHeading{
        width: 100% !important;
    }
}