.BlueGradient {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BlueHoverGradient {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.BoxShadow {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
}
.BoxShadowHover {
  box-shadow: 0px 0px 12px -3px #294177;
  -webkit-box-shadow: 0px 0px 12px -3px #294177;
  -moz-box-shadow: 0px 0px 12px -3px #294177;
}
.BorderRadius4 {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.BorderRadius10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.BorderRadius25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.BorderRadius100 {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.Border {
  border: 1px #dddddd solid;
}
.TextOverFlowDot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.WhiteBg {
  background: #ffffff;
}
.Transition {
  transition: 0.5s;
}
/* ===== Dashboard ===== */
.dashboard-wrapper {
  padding: 50px 0px;
}
.dashboard-wrapper h2 {
  color: #212529;
  font-weight: 900;
}
.dashboard-wrapper .dashboard-content {
  position: relative;
  padding-left: 330px;
  min-height: 700px;
}
@media (max-width: 768px) {
  .dashboard-wrapper .dashboard-content {
    min-height: initial;
    padding-left: 0px;
  }
  .profileImgCol {
    display: flex;
    justify-content: center;
  }
}
.dashboard-wrapper .dashboard-content .dashboard-menu {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 300px;
  /* background: #16457b; */
  /* background: linear-gradient(0deg, #16457b 0%, #3578c4 100%); */
  background: #294177;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
  min-height: 700px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
@media (max-width: 768px) {
  .dashboard-wrapper .dashboard-content .dashboard-menu {
    min-height: initial;
    position: relative;
    width: 100%;
  }
}
.dashboard-wrapper .dashboard-content .dashboard-menu .dashboard-menu-button {
  color: #ffffff;
  padding: 10px 20px;
  display: block;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-bottom: 2px #294177 solid;
  display: none;
}
@media (max-width: 768px) {
  .dashboard-wrapper .dashboard-content .dashboard-menu .dashboard-menu-button {
    display: block;
  }
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-button:after {
  position: absolute;
  right: 20px;
  top: 20px;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
}
@media (max-width: 768px) {
  .dashboard-wrapper .dashboard-content .dashboard-menu .dashboard-menu-list {
    display: none;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 45px;
    background: #294177;
    /* background: #16457b; */
    /* background: linear-gradient(0deg, #16457b 0%, #3578c4 100%); */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    z-index: 1;
  }

  .dashboard-wrapper
    .dashboard-content
    .dashboard-menu
    .dashboard-menu-list.hide {
    display: none;
  }

  .dashboard-wrapper
    .dashboard-content
    .dashboard-menu
    .dashboard-menu-list.show {
    display: block;
  }
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list.show {
  display: block;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu {
  margin: 0px;
  padding: 0px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  transition: 0.5s;
}
.menuFirstChild a {
  border-radius: 25px 25px 0px 0px;
  -webkit-border-radius: 25px 25px 0px 0px;
}

.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li
  a {
  padding: 15px 20px 15px 65px;
  color: #ffffff;
  position: relative;
  display: block;
  font-weight: 900;
  transition: 0.5s;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li
  a:hover
  .outerBg,
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.submenu-content
  li
  a:hover
  .outerBg {
  left: 35px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li
  a:hover {
  padding: 15px 20px 15px 80px;
  background: #268cff;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li
  a.active {
  background: #268cff;
}
.submenu-content .txt {
  /* padding-left: 39px; */
  vertical-align: -9px;
  font-size: 16px !important;
}
.headerUserIcon {
  font-size: 24px !important;
}
.outerBg {
  background: #fff;
  border-radius: 50%;
  color: #294177;
  position: absolute;
  left: 20px;
  top: 10px;
  width: 32px;
  height: 32px;
  text-align: center;
  transition: 0.5s;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li
  a
  .icon {
  font-size: 22px;
  transition: 0.5s;
  line-height: 32px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li
  a.submenu-link {
  position: relative;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li
  a.submenu-link:after {
  position: absolute;
  right: 20px;
  top: 25px;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li
  .submenu-content {
  background: #2b68ac40;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li
  .submenu-content
  ul
  li
  a {
  padding: 10px 15px 10px 65px;
  font-size: 14px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li
  .submenu-content
  ul
  li
  a:hover {
  padding: 10px 15px 10px 80px;
  color: #fff;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li
  .submenu-content
  ul
  li
  a.active {
  color: #294177;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-menu
  .dashboard-menu-list
  ul.mainmenu
  li
  .submenu-content
  ul
  li
  a
  .icon {
  font-size: 18px;
}
@media (max-width: 768px) {
  .dashboard-wrapper .dashboard-content .dashboard-information {
    margin-top: 30px;
  }
}
.manage-subscription-section .header-txt {
  margin-bottom: 30px;
}
.dashboard-wrapper .dashboard-content .dashboard-information .header-txt {
  color: #294177;
  font-weight: 900;
  font-size: 20px;
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  text-align: center;
  padding: 10px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  margin-bottom: 25px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .topside {
  padding: 30px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  h3 {
  color: #888888;
  font-weight: 900;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .user-name {
  color: #294177;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
}

.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .desc {
  color: #294177;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .info-icon {
  padding-left: 40px;
  position: relative;
  color: #888888;
  font-weight: 900;
  margin-bottom: 15px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .info-icon
  .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #294177;
  display: inline-block;
  font-size: 24px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .info-icon
  .email {
  word-wrap: break-word;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .profile-btns
  a {
  display: block;
  padding: 7px 10px;
  color: #fff;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  text-align: center;
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  margin-bottom: 10px;
  transition: 0.5s;
  font-weight: 900;
  background-color: #294177;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .profile-btns
  a:last-child {
  margin-bottom: 0px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .profile-image {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  padding: 5px;
  width: 135px;
  height: 135px;
  border-radius: 135px;
  -webkit-border-radius: 135px;
  -moz-border-radius: 135px;
  position: relative;
}
@media (max-width: 420px) {
  .dashboard-wrapper
    .dashboard-content
    .dashboard-information
    .dashboard-section
    .profile-box
    .profile-image {
    width: 90px;
    height: 90px;
  }
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .profile-image
  img {
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  border-radius: 150px;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .profile-image
  .edit-profile {
  position: absolute;
  right: 10px;
  bottom: 5px;
  color: #212529;
  font-size: 26px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .profile-image
  .edit-profile:hover {
  color: #294177;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .bottomsite {
  text-align: center;
  padding-bottom: 30px;
  position: relative;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .bottomsite
  .b-boxs {
  color: #ffffff;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .bottomsite
  .b-boxs
  .number {
  font-size: 24px;
  font-weight: 900;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .bottomsite:after {
  content: "";
  background: #294177;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50px;
  bottom: 0px;
  z-index: -1;
  border-radius: 0px 0px 25px 25px;
  -webkit-border-radius: 0px 0px 25px 25px;
  -moz-border-radius: 0px 0px 25px 25px;
}
.bottomsite .icon {
  font-size: 39px;
  color: #294177;
}
.bottomsite .icon.dollar {
  font-size: 55px;
  line-height: 68px;
}
@media (max-width: 767px) {
  .bottomsite .icon.dollar {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 896px) {
  .navbar-nav {
    background: #fafafa;
  }
  .memorize-navbar .navbar-nav .nav-link {
    padding: 15px !important;
  }
  .memorize-navbar .navbar-nav .nav-link,
  .login-and-menu {
    border-bottom: 1px solid #ededed;
    display: block;
  }
  .login-and-menu {
    padding: 8px 0 12px 15px !important;
  }
  .notifyNumber {
    left: 45px;
  }
}
@media (max-width: 420px) {
  .dashboard-wrapper
    .dashboard-content
    .dashboard-information
    .dashboard-section
    .profile-box
    .bottomsite:after {
    top: 30px;
  }
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .profile-box
  .bottomsite
  .b-icon {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  height: 100px;
  width: 100px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  padding: 20px;
  margin: 0px auto;
  background: #ffffff;
  margin-bottom: 15px;
}
@media (max-width: 420px) {
  .dashboard-wrapper
    .dashboard-content
    .dashboard-information
    .dashboard-section
    .profile-box
    .bottomsite
    .b-icon {
    width: 60px;
    height: 60px;
    padding: 10px;
  }
  .bottomsite .icon {
    font-size: 30px;
  }
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .chart-info {
  margin-top: 30px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .chart-info
  .chart-boxs {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  margin-bottom: 10px;
  padding: 30px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info {
  margin-bottom: 50px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-geners {
  display: flex;
  margin-bottom: 20px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  h3 {
  color: #294177;
  margin-bottom: 0px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .view-all-btn {
  margin-left: auto;
  padding-top: 5px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .view-all-btn
  a {
  color: #294177;
  font-weight: 900;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .view-all-btn
  a:hover {
  color: #294177;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  text-align: center;
  transition: 0.5s;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs:hover {
  background: #294177;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs:hover
  .in-txt {
  color: #ffffff;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs.active {
  background: #294177;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs.active
  .in-txt {
  color: #ffffff;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs.square {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  padding: 20px 10px;
  min-height: 100%;
  display: block;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs.circle {
  padding: 10px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  height: 100px;
  width: 100px;
  line-height: 75px;
  margin: 0px auto;
  display: block;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs.circle
  .dashborad-flags {
  background-image: url("../images/flag.jpg");
  background-repeat: no-repeat;
  width: 50px;
  height: 35px;
  display: inline-block;
  margin-top: 22px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs.circle
  .dashborad-flags.india {
  background-position: 0px 0px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs.circle
  .dashborad-flags.usa {
  background-position: -50px 0px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs.circle
  .dashborad-flags.china {
  background-position: -100px 0px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs.circle
  .dashborad-flags.japan {
  background-position: -150px 0px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .preferred-boxs
  img {
  max-height: 50px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .dashboard-section
  .preferred-info
  .in-txt {
  margin-top: 10px;
  color: #212529;
  font-weight: 900;
  word-wrap: break-word;
  text-align: center;
  display: block;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .date {
  font-weight: 900;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .start-txt {
  text-align: right;
}
@media (max-width: 767px) {
  .dashboard-wrapper
    .dashboard-content
    .dashboard-information
    .manage-subscription-section
    .start-txt {
    text-align: left;
  }
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .subscription-item {
  margin-top: 30px;
}
.subscription-box .icon-idea,
.subscription-box .icon-subscription1 {
  color: #294177;
  font-size: 50px;
  margin-bottom: 20px;
  display: inline-block;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .subscription-item
  .subscription-box {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  padding: 30px;
  text-align: center;
  min-height: 100%;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .subscription-item
  .subscription-box:hover {
  box-shadow: 0px 0px 12px -3px #294177;
  -webkit-box-shadow: 0px 0px 12px -3px #294177;
  -moz-box-shadow: 0px 0px 12px -3px #294177;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .subscription-item
  .subscription-box
  h5 {
  font-weight: 900;
  color: #294177;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .subscription-item
  .subscription-box
  p {
  color: #888888;
  min-height: 50px;
}

@media (max-width: 767px) {
  .dashboard-wrapper
    .dashboard-content
    .dashboard-information
    .manage-subscription-section
    .subscription-item
    .subscription-box
    p {
    min-height: initial;
  }
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .subscription-item
  .subscription-box
  input {
  border: none;
  border-bottom: 1px #dddddd solid;
  text-align: center;
  font-weight: 900;
  font-size: 20px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .subscription-item
  .subscription-box
  .price {
  color: #294177;
  margin: 20px 0px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .subscription-item
  .subscription-box
  .price
  span {
  font-weight: 900;
  font-size: 18px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .subscription-item
  .subscription-box
  .price-bold {
  font-family: "Arial ExtraBold";
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .subscription-item
  .subscription-box
  .button {
  border: 2px #294177 solid;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 10px 15%;
  transition: 0.5s;
  color: #294177;
  font-weight: 900;
  margin-top: 20px;
  display: inline-block;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .manage-subscription-section
  .subscription-item
  .subscription-box
  .button:hover {
  background: #294177;
  color: #ffffff;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info {
  box-shadow: 0px 0px 12px -3px #aaa;
  -webkit-box-shadow: 0px 0px 12px -3px #aaa;
  -moz-box-shadow: 0px 0px 12px -3px #aaa;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  padding: 30px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .sr-overall {
  text-align: center;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .sr-overall
  .amount {
  color: #294177;
  font-size: 30px;
  font-weight: bold;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .sr-overall
  .txt {
  color: #212529;
  font-weight: bold;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .st-filter {
  margin-top: 30px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .st-filter
  p {
  color: #888888;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .st-filter
  .input-control {
  position: relative;
  margin-bottom: 10px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .st-filter
  .input-control
  input {
  border: none;
  border-bottom: 1px #dddddd solid;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px 30px 10px 0px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .st-filter
  .input-control
  .icon {
  position: absolute;
  right: 10px;
  top: 10px;
  display: inline-block;
  color: #212529;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table {
  margin-top: 30px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  h3 {
  text-align: center;
  color: #294177;
  margin-bottom: 20px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .table-btn {
  background: #16457b;
  background: linear-gradient(0deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  color: #ffffff;
  padding: 8px 16px;
  font-size: 15px;
  font-weight: 900;
  display: inline-block;
  transition: 0.5s;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .table-btn:hover {
  background: #16457b;
  background: linear-gradient(180deg, #16457b 0%, #3578c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#16457b", endColorstr="#3578c4", GradientType=1);
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .reports {
  overflow-y: auto;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .reports
  table {
  border-collapse: collapse;
  width: 100%;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .reports
  table
  .price {
  color: #294177;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .reports
  table
  thead
  th {
  background: #f9f9f9;
  padding: 15px 10px;
  font-size: 20px;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .reports
  table
  td,
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .reports
  table
  th {
  border-right: 1px #dddddd solid;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .reports
  table
  td:last-child,
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .reports
  table
  th:last-child {
  border-right: none;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .reports
  table
  td {
  padding: 10px;
  border-bottom: 1px #dddddd solid;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .reports
  table
  tfoot
  tr
  td {
  background: #f9f9f9;
  font-size: 20px;
  border-right: none;
}
.dashboard-wrapper
  .dashboard-content
  .dashboard-information
  .sales-report-info
  .report-table
  .reports
  table
  tfoot
  tr
  td
  .price {
  font-weight: bold;
}
.icon-user2 {
  font-size: 17px !important;
  vertical-align: 2px;
  padding-left: 1px;
}
.icon-notification1 {
  font-size: 20px !important;
}
.sidenavNotification {
  color: #fff !important;
  font-size: 16px;
  background: red;
  border-radius: 50%;
  border: none !important;
  padding: 12px !important;
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
}
.dropdown-menu-left .icon:before,
.dropdown-menu-left .icon:before {
  font-size: 22px;
}
.usernameItem {
  background: #294177;
  height: 65px;
  display: flex !important;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.usernameItem .user {
  color: #fff;
  padding-left: 8px;
}
.contentUser {
  background: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  padding-top: 5px;
}
.contentUser .icon-user2::before {
  font-size: 16px;
}
#id_repeat_page .plusIcon,
#id_repeat_page .icon-plus {
  border: none !important;
}
.contentBtn.active {
  background: #294177 !important;
  color: #fff !important;
}

@media screen and (min-width: 992px) {
  .contentBtn::before {
    left: -2px;
    content: "";
    position: absolute;
    border-top: 2px solid #294177;
    top: -25px;
    transition: 0.2s;
    width: 0px;
  }
  .contentBtn.active::before {
    width: 100% !important;
  }
  .contentBtn:hover::before {
    width: 100% !important;
  }
}
